<template lang="pug">
.course-modules-wrapper
    CourseModulesLoader(v-if='fetchingCourseModules')
    template(v-else)
        .header-section
            h2.module {{ $t('courses.courseContent') }}
        .modules-container-wrapper(v-if='courseModules.length')
            .modules-container(
                :key='key',
                v-for='(courseGroup, key) in filteredCourseModules'
            )
                h5.course-group-name(v-if='courseGroup.name') {{ courseGroup.name }}
                .course-module(
                    :disabled='module.disabled',
                    :key='module.id',
                    v-for='(module, index) in courseGroup.modules'
                )
                    .module-wrapper
                        .order-block(v-if='isDesktopDevice')
                            .order.notranslate {{ convertToRoman(index + 1) }}
                        .info-container
                            .name-block
                                Badge.free(
                                    :badge-value='$t("courses.free")',
                                    v-if='module.isFree && !isPurchasedCourse'
                                )
                                router-link.h3.name(:to='{ name: "CourseModule", params: { moduleId: module.id } }') {{ module.name }}
                            .module-lessons-info-block
                                .module-info
                                    HTMLRenderer.h5(
                                        :html='module.description || ""',
                                        link-target='_blank'
                                    )
                                    .duration-lessons(v-if='module.lessons.length')
                                        .duration
                                            SVGRenderer(
                                                :has-hover='false',
                                                :icon='durationIcon',
                                                stroke-color='var(--primary-color)'
                                            )
                                            h5 {{ $t('courses.totalModuleLengthApprox') }} {{ approximateModuleDuration(module) }}h
                                        .lessons
                                            SVGRenderer(
                                                :has-hover='false',
                                                :icon='lesson',
                                                stroke-color='var(--primary-color)'
                                            )
                                            h5 {{ module.lessons.length }} {{ $t('courses.lessons') }}
                                .lessons-info(v-if='isDesktopDevice')
                                    .lesson(
                                        :class='{ locked: isLockedModule(module.isFree), disabled: isLockedModule(module.isFree) && !course.shopifyProductId }',
                                        :key='lesson.id',
                                        @click='routeToLessonOrCheckout(module, lesson.id)',
                                        v-for='lesson in module.lessons'
                                    )
                                        .overall-info
                                            .left-attr
                                                SVGRenderer(
                                                    :has-hover='false',
                                                    :icon='lockIcon',
                                                    height='15',
                                                    stroke-color='var(--primary-color)',
                                                    v-if='isLockedModule(module.isFree)',
                                                    width='15'
                                                )
                                                SVGRenderer(
                                                    :has-hover='false',
                                                    :icon='open',
                                                    stroke-color='var(--primary-text-color)',
                                                    v-else
                                                )
                                            h5 {{ lesson.title }}
                                        .meta-info
                                            h5 {{ lesson.duration }}min
        .no-modules(v-else)
            h5 {{ $t('courses.noModulesInCourse') }}
</template>

<script setup lang="ts">
  import { computed, watch } from 'vue'
  import Badge from '@/components/common/Badge.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useDuration from '@/composables/useDuration'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import useBreakpoint from '@/composables/useBreakpoint'
  import type { CourseModule as CourseModuleState } from '@/services/interfaces/Course'
  import CourseModulesLoader from '@/components/loaders/CourseModulesLoader.vue'
  import useShopifyClient from '@/composables/useShopifyClient'

  const { durationIcon, lesson, lockIcon, open } = useIcons()
  const { formatDurationToHHMM } = useDuration()
  const { isDesktopDevice } = useBreakpoint()
  const { convertToRoman } = useCommonMixin()
  const { createShopifyCheckout } = useShopifyClient()
  const { isPurchasedCourse, currentCourseId, courseModules, course, guidedQuizDuration, openLessonModal } = useCourse()

  const emit = defineEmits(['fetching-course-modules'])

  const fetchingCourseModules = computed(() => CourseModule.coursesModuleMap[currentCourseId.value] === undefined)

  const filteredCourseModules = computed(() => {
    if (!course.value?.courseGroups?.length) {
      return {
        '': {
          name: '',
          modules: courseModules.value,
        },
      }
    }
    return course.value.courseGroups.reduce(
      (acc, group) => {
        acc[group.id] = {
          name: group.name,
          modules: courseModules.value?.filter((mod) => mod.courseGroupId === group.id) || [],
        }
        return acc
      },
      {} as { [key: string]: { name: string; modules: CourseModuleState[] } },
    )
  })

  const isLockedModule = computed(() => (isFree: boolean) => {
    return !course.value?.purchased && !isFree
  })

  watch(fetchingCourseModules, (value) => {
    emit('fetching-course-modules', value)
  })

  if (fetchingCourseModules.value) {
    CourseModule.getCourseModules(currentCourseId.value)
  } else {
    emit('fetching-course-modules', false)
  }

  const routeToLessonOrCheckout = (module: CourseModuleState, lessonId: string) => {
    if (isLockedModule.value(module.isFree)) {
      createShopifyCheckout()
    } else {
      openLessonModal({
        lessonId,
        moduleId: module.id,
      })
    }
  }

  const approximateModuleDuration = (module: CourseModuleState) => {
    const lessonDurations = module.lessons.map((lesson) => lesson.duration || 0)
    const totalLessonDuration = lessonDurations.reduce((total, duration) => total + duration, 0)

    const _guidedQuizDuration = module.guidedQuiz ? guidedQuizDuration.value(module.guidedQuiz.numberOfQuestions) : 0

    const totalDuration = totalLessonDuration + _guidedQuizDuration
    return formatDurationToHHMM(Math.round(totalDuration))
  }
</script>

<style lang="postcss" scoped>
  .course-modules-wrapper {
    .loading {
      @apply ketch-mx-auto;
    }
    .header-section {
      @apply md:ketch-mb-[60px];
      h2 {
        @apply ketch-font-big-daily-short;
      }
    }
    .modules-container {
      @apply ketch-mb-c30;
      .course-group-name {
        @apply ketch-text-primary-color ketch-font-bold;
      }
      .course-module {
        @apply ketch-border-b ketch-border-border-color ketch-py-c30;
        &:last-child {
          @apply ketch-border-b-0;
        }
        &[disabled='disabled'] {
          @apply ketch-opacity-30 ketch-pointer-events-none;
        }
        .module-wrapper {
          @apply ketch-flex ketch-items-start ketch-space-x-c25;
          .order-block {
            @apply ketch-w-c55;
            .order {
              @apply ketch-text-4xl ketch-w-c55 ketch-flex ketch-items-center ketch-justify-center;
            }
          }
          .info-container {
            @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-w-full;
            .name-block {
              @apply ketch-flex ketch-flex-col md:ketch-flex-row md:ketch-items-start;
              .free {
                @apply ketch-mb-c5 md:ketch-mb-0 md:ketch-mr-c10;
              }
              .name {
                @apply ketch-font-bold hover:ketch-underline;
              }
            }
            .module-lessons-info-block {
              @apply ketch-flex;
              .module-info {
                @apply ketch-flex ketch-flex-col ketch-space-y-c20;
                @screen md {
                  flex: 0 0 360px;
                }
                .duration-lessons {
                  @apply ketch-flex ketch-flex-col ketch-space-y-c10;
                  @apply md:ketch-space-y-0 md:ketch-flex-row md:ketch-items-center md:ketch-justify-between;
                  .duration,
                  .lessons {
                    @apply ketch-flex ketch-items-center ketch-space-x-c10;
                  }
                }
              }
              .lessons-info {
                @apply ketch-flex ketch-flex-col ketch-space-y-c5 ketch-flex-auto md:ketch-pl-[70px];
                .lesson {
                  @apply ketch-flex ketch-items-center ketch-justify-between ketch-cursor-pointer;
                  &.locked {
                    @apply ketch-opacity-50;
                  }
                  &.disabled {
                    @apply ketch-pointer-events-none;
                  }
                  &:hover {
                    > * {
                      @apply ketch-text-primary-color ketch-transition;
                    }
                  }
                  .overall-info {
                    @apply ketch-flex ketch-space-x-c15 ketch-mr-c5;
                  }
                }
              }
            }
          }
        }
      }
    }
    .no-modules {
      @apply ketch-text-center;
    }
  }
</style>
