<template lang="pug"></template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CookieHelper from '@/helpers/CookieHelper'
  import useAuthentication from '@/composables/useAuthentication'
  import { useRoute } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'

  const route = useRoute()
  const { inWebExtension } = useCommonMixin()
  const { tokenKey } = useAuthentication()
  const { translateString } = useI18n()

  const isLogin = computed(() => {
    return route.name === 'Login'
  })

  const isLoginCallback = computed(() => {
    return route.name === 'LoginCallback'
  })

  const isLogout = computed(() => {
    return route.name === 'Logout'
  })

  const redirect = computed(() => {
    return route.query.redirect ? `?redirect=${route.query.redirect}` : ''
  })

  const handleAuthOperations = async () => {
    if (isLogin.value) return handleLoginOperation()
    if (isLoginCallback.value) return handleLoginCallbackOperation()
    if (isLogout.value) {
      await UserModule.logout()
      let returnUrl = window.location.origin
      if (inWebExtension.value) returnUrl += '/chrome-extension'
      eventBus.$auth.logout({
        logoutParams: {
          returnTo: returnUrl,
        },
      })
    }
  }

  const handleLoginOperation = async () => {
    const token = CookieHelper.getCookieValue(tokenKey.value)
    if (token) {
      window.location.href = (route.query.redirect as string) || '/'
      return
    }

    await eventBus.$auth.loginWithRedirect({
      async openUrl(url = `${window.location.origin}/login/callback${redirect.value}`) {
        window.location.replace(url)
      },
    })
  }

  const handleLoginCallbackOperation = () => {
    if (route.query.error_description) {
      if (route.query.error === 'unauthorized') {
        eventBus.$toasted.error(translateString('onboarding.unauthorizedText'), { duration: 8000 })
      }
      if (route.query.error === 'access_denied') {
        eventBus.$toasted.error(translateString('onboarding.accessDeniedText'), { duration: 8000 })
      }
      const timeout = route.query.error ? 5000 : 2000
      setTimeout(() => (window.location.href = '/'), timeout)
      return
    }
  }

  handleAuthOperations()
</script>

<style lang="postcss">
  .signup {
    @apply ketch-py-c30 xs4:ketch-py-c60 xl:ketch-py-c100 ketch-overflow-hidden;
    @apply md:ketch-mx-c40 lg:ketch-mx-c60 xl:ketch-mx-0;
    .signup-container {
      @apply ketch-flex ketch-justify-center ketch-items-center ketch-flex-col md:ketch-flex-row;
    }
    .signup-content {
      @apply ketch-flex ketch-flex-col md:ketch-mr-c60 xl:ketch-mr-c150;
      @apply ketch-w-full xs4:ketch-w-c500 md:ketch-w-auto ketch-px-c30 xs4:ketch-px-0;
      @screen xl {
        flex: 0 0 500px;
      }
      @screen xl {
        flex: 0 0 579px;
      }

      .logo {
        @apply ketch-flex ketch-justify-between ketch-items-center;
        span {
          @apply ketch-bg-white ketch-inline-flex ketch-items-center ketch-justify-center;
          @apply ketch-rounded-full ketch-w-c50 ketch-h-c50;
        }
        h3 {
          @apply ketch-text-primary-color ketch-underline ketch-font-bold ketch-cursor-pointer;
        }
      }
      p {
        @apply ketch-text-2xl md:ketch-text-5xl ketch-leading-2xl1 md:ketch-leading-6xl ketch-font-big-daily-short;
        @apply ketch-mt-c30 sm:ketch-mt-c50 ketch-mb-c20;
      }
      > h5 {
        @apply ketch-text-md2 ketch-leading-lg ketch-text-secondary-text-color;
      }

      .course-content {
        @apply ketch-space-y-c15 ketch-my-c50 md:ketch-mt-c100 md:ketch-mb-0;
        h2 {
          @apply ketch-pb-c5;
        }
        .course-item {
          @apply ketch-flex ketch-items-center;
          h5 {
            @apply ketch-font-bold ketch-ml-c20 ketch-text-primary-color;
          }
          img {
            @apply ketch-w-[14px] ketch-h-[14px] sm:ketch-w-[22px] sm:ketch-h-[22px];
          }
        }
      }
    }
    .signup-form-container {
      @apply ketch-w-full xs4:ketch-w-c500 md:ketch-w-auto;
      @screen md {
        flex: 0 0 380px;
      }
      @screen lg {
        flex: 0 0 450px;
      }
      @screen 2xl {
        flex: 0 0 535px;
      }
      .login-container {
        @apply ketch-text-right;
        span {
          @apply ketch-text-primary-color ketch-underline ketch-font-bold ketch-cursor-pointer;
        }
      }
      .signup-form {
        @apply ketch-bg-white ketch-p-c40 ketch-mt-c10;
        form {
          @apply ketch-text-primary-text-color;
          .form-wrapper {
            @apply ketch-mt-c40 ketch-space-y-c20;
            .loading .dot {
              @apply ketch-bg-white;
            }
            .auth-errors {
              @apply ketch-p-c20 ketch-border ketch-border-red-500 ketch-rounded-normal;
              > ul {
                @apply ketch-mt-c10;
              }
              .error {
                @apply ketch-text-red-500;
              }
              ul {
                @apply ketch-list-disc ketch-pl-c30;
              }
            }
            .form-group {
              @apply ketch-flex ketch-flex-col;
              label {
                @apply ketch-mb-c5;
              }
              input {
                @apply ketch-border ketch-px-c15 ketch-py-c10 ketch-rounded-small focus:ketch-border-primary-text-color;
                @apply ketch-outline-none;
              }
              .error {
                @apply ketch-text-red-500;
              }
              .has-error {
                @apply ketch-border-red-500;
              }
            }
            .terms {
              @apply ketch-text-center ketch-text-secondary-text-color ketch-py-c10;
              a {
                @apply ketch-text-blue-500 ketch-underline ketch-inline-block;
              }
              span {
                @apply ketch-mx-c5;
              }
            }
            button {
              @apply ketch-w-full ketch-rounded-none ketch-border-none ketch-h-c50;
            }
            .rule {
              @apply ketch-relative ketch-py-c15;
              span {
                @apply ketch-absolute ketch-px-c20 ketch-bg-white ketch-bottom-c5;
                left: calc(50% - 28px);
              }
            }
            .oauth-connections {
              .google,
              .facebook {
                @apply ketch-flex ketch-items-center ketch-px-c5 ketch-border ketch-rounded-small ketch-cursor-pointer;
                img {
                  @apply ketch-mr-c20 ketch-w-c45 ketch-h-c45;
                }
              }
              .google {
                @apply hover:ketch-border-secondary-text-color;
              }
              .facebook {
                @apply ketch-text-white ketch-mb-c10 hover:ketch-bg-blue-900;
                background-color: #3b5998;
                img {
                  @apply ketch-w-c45 ketch-h-c45 ketch-p-c10;
                }
              }
            }
          }
        }
      }
    }
    .clients-logo-area {
      @apply ketch-mt-c80 ketch-mx-auto;
      @screen xl {
        width: 1170px;
      }
      @screen 2xl {
        width: 1255px;
      }
      p {
        @apply ketch-text-md2 ketch-leading-lg1 ketch-text-white ketch-opacity-40 ketch-mb-c25;
      }
      .logo-container {
        @apply ketch-flex ketch-items-center ketch-justify-between;
      }
    }
  }
</style>
