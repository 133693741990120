<template lang="pug">
.course-exam-date
    .exam-date-wrapper(v-if='realExamDate && !dateRemoved')
        h5.days-left {{ daysLeftTillTheExamText(realExamDate) }}
        .pdf-delete-cta
            h5.tips(
                @click='downloadTipsDocument',
                v-if='examDateDetails.examTipsDownloadUrl'
            ) {{ $t('examDatePicker.tipsToPrepare') }}
            h5.remove-date(@click='removeExamDate') {{ $t('examDatePicker.removeDate') }}
    .register-date-cta(v-else-if='!daysLeftTillTheExam(realExamDate) > 0 || dateRemoved')
        h5(@click='triggerAppointmentModal') {{ $t('examDatePicker.doYouAlreadyHaveAnExamDate') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import UsersApi from '@/services/api/UsersApi'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { ExamDateDetails } from '@/services/interfaces/Course'
  import CookieHelper from '@/helpers/CookieHelper'

  const props = defineProps({
    examDateDetails: {
      type: Object as PropType<ExamDateDetails>,
      required: true,
    },
  })
  const { translateString } = useI18n()
  const {
    formatDate,
    daysDifferenceFromNow,
    downloadItem,
    identifyUserInSegmentWithExamDates,
    showExamAppointmentModal,
  } = useCommonMixin()
  const { examAppointmentRemoved } = useSegment()
  const { course } = useCourse()
  const dateRemoved = ref(false)

  const realExamDate = computed(() => props.examDateDetails?.realExamDate)

  const daysLeftTillTheExam = computed(() => (examDate: string) => {
    return daysDifferenceFromNow(examDate, true)
  })

  const daysLeftTillTheExamText = computed(() => (examDate: string) => {
    const daysLeft = daysLeftTillTheExam.value(examDate)

    if (daysLeft > 0 && daysLeft < 1) {
      return translateString('examDatePicker.lessThanOneDayLeft', {
        date: formatDate(examDate, 'dd.MM.yyyy'),
      })
    } else if (daysLeft < 0) {
      return translateString('examDatePicker.examDateHasPassed', { date: formatDate(examDate, 'dd.MM.yyyy') })
    }

    return translateString('examDatePicker.daysLeftTillTheExam', {
      days: Math.floor(daysLeft),
      date: formatDate(examDate, 'dd.MM.yyyy'),
    })
  })

  const downloadTipsDocument = () => {
    if (props.examDateDetails.examTipsDownloadUrl) {
      downloadItem(props.examDateDetails.examTipsDownloadUrl)
    }
  }

  const triggerRemoveDate = () => {
    UsersApi.cancelExamAppointment(course.value!.id)
      .then(() => {
        CookieHelper.setCrossDomainCookie('skip-setting-exam-appointment', 'true')
        dateRemoved.value = true
        identifyUserInSegmentWithExamDates(course.value!.shortTitle, null)
        examAppointmentRemoved(course.value!.id, course.value!.title)
      })
      .finally(() => {
        eventBus.$emit('close-modal')
      })
  }

  const removeExamDate = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'ConfirmationModal',
      modalTitle: translateString('examDatePicker.examDateRemoval'),
      modalProps: {
        confirmationText: translateString('examDatePicker.examDateRemovalConfirmationText'),
        okCallback: triggerRemoveDate,
        cancelButtonText: translateString('cancel'),
      },
      cssClass: 'confirmation',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const triggerAppointmentModal = () => {
    showExamAppointmentModal(course.value!.id)
  }
</script>

<style lang="postcss" scoped>
  .course-exam-date {
    .exam-date-wrapper,
    .register-date-cta {
      @apply ketch-rounded-none ketch-bg-secondary-color ketch-bg-opacity-5 ketch-px-c20 ketch-py-c15;
      @apply ketch-border ketch-border-secondary-color md:ketch-rounded-normal;
      h5 {
        @apply ketch-text-secondary-color;
      }
    }
    .exam-date-wrapper {
      @apply ketch-space-y-c5;
      .days-left {
        @apply ketch-font-bold;
      }
      .pdf-delete-cta {
        @apply ketch-flex ketch-items-center ketch-justify-between;
        h5 {
          @apply ketch-cursor-pointer ketch-underline;
        }
        .remove-date {
          @apply ketch-opacity-50;
        }
      }
    }
    .register-date-cta {
      @apply ketch-cursor-pointer;
      .h5 {
        @apply ketch-text-secondary-color ketch-underline;
      }
    }
  }
</style>
