<template lang="pug">
.all-news-wrapper
    LoadingSpinner(v-if='loadingContent')
    .all-news(v-else)
        h5.sub-header {{ $t('news.alwaysUptoDate') }}
        .news-header-section
            .news-header-wrapper
                h1.news-header-text
                    span {{ $t('news.industrySector') }}
                    span.news {{ $t('news.news') }}
                h4.news-header-info {{ $t('news.headerInfo') }}
            .source-wrapper(data-cy='news-sources')
                .source-logo(
                    :key='source',
                    v-for='source in newsSourceLogos'
                )
                    img.source-logo(:src='source')
        .news(data-cy='news-items')
            a.news-item(
                :class='{ new: index < newNewsContentLength }',
                :href='news.newsUrl',
                :key='news.id',
                @click.stop='openNewsItem(news.newsUrl, news.id)',
                target='_blank',
                v-for='(news, index) in slicedNewsContent'
            )
                .meta-info
                    img.source-logo(:src='news.source.logoUrl')
                    h6.date {{ news.date }}
                .content
                    h5.title {{ news.title }}
                    h6.description {{ news.description }}
        .load-more-pagination(v-if='offset < itemSize')
            span(@click='offset += limit') {{ $t('loadMore') }} ({{ itemSize - offset }})
</template>

<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useSegment from '@/composables/useSegment'
  import { NewsModule } from '@/store/modules/News'
  import CookieHelper from '@/helpers/CookieHelper'
  import type { NewsItem } from '@/services/interfaces/Content'
  import useCommonMixin from '@/composables/useCommonMixin'

  const { newsItemOpened } = useSegment()
  const { setIsLoadingComponentViewData } = useCommonMixin()
  const limit = 15
  const offset = ref(limit)

  const loadingContent = computed(() => newsContent.value === null)
  const newsContent = computed(() => NewsModule.newsContent)
  const newNewsContentLength = computed(() => NewsModule.newNewsContentLength)

  const newsSourceLogos = computed(() => {
    const logoUrls = newsContent.value?.map((news) => {
      return news.source.logoUrl
    })
    return [...new Set(logoUrls)]
  })

  const slicedNewsContent = computed((): NewsItem[] => {
    if (!newsContent.value) return []
    return newsContent.value.slice(0, offset.value)
  })

  const itemSize = computed(() => newsContent.value?.length || 0)
  const openNewsItem = (newsUrl: string, newsId: string) => {
    newsItemOpened(newsUrl, newsId)
  }

  NewsModule.getNewsContent().then((news) => {
    const previousLatestNewsItemId = CookieHelper.getCookieValue('latest_news_item_id')
    if (previousLatestNewsItemId && news) {
      const newLatestNewsItemIdIndex = news[0].id
      if (previousLatestNewsItemId !== newLatestNewsItemIdIndex) {
        const previousLatestNewsItemIdIndex = news.findIndex((news) => news.id === previousLatestNewsItemId)
        NewsModule.setNewNewsContentLength(previousLatestNewsItemIdIndex)
      }
    }
  })

  watch(loadingContent, (value) => {
    setIsLoadingComponentViewData(value)
  })

  onMounted(() => {
    if (newsContent.value) {
      const latestNewsItemId = newsContent.value[0].id
      CookieHelper.setCookieValue('latest_news_item_id', `${latestNewsItemId}`)
    }
  })

  onUnmounted(() => {
    NewsModule.resetNewNewsContentLength()
  })
</script>

<style lang="postcss" scoped>
  .all-news-wrapper {
    .loading {
      @apply ketch-mx-auto;
    }
    .all-news {
      @apply ketch-flex ketch-flex-col;
      .sub-header {
        @apply ketch-uppercase ketch-font-bold ketch-text-primary-color ketch-tracking-[0.07em] ketch-mb-[13px];
      }
      img.source-logo {
        @apply ketch-filter ketch-invert-[1];
      }
      .news-header-section {
        @apply ketch-flex ketch-flex-col ketch-justify-between ketch-mb-[70px] md:ketch-mb-[140px] md:ketch-flex-row;
        .news-header-wrapper {
          @apply ketch-max-w-[340px];
          .news-header-text {
            @apply ketch-flex ketch-flex-col md:ketch-text-4xl md:ketch-leading-[52px];
            > {
              .news {
                @apply ketch-font-bold;
              }
            }
          }
          .news-header-info {
            @apply ketch-mt-c40 ketch-mb-c20 md:ketch-mb-0;
          }
        }
        .source-wrapper {
          @apply ketch-grid ketch-grid-cols-2 ketch-gap-x-c20 ketch-gap-y-c35;
          @apply sm:ketch-grid-cols-3 md:ketch-gap-x-c90 md:ketch-gap-y-c20;
          img {
            @apply ketch-max-h-c30 ketch-max-w-[130px];
          }
        }
      }
      .news {
        .news-item {
          @apply ketch-flex ketch-space-x-c50 ketch-py-c15 ketch-border-b ketch-border-border-color ketch-cursor-pointer;
          @apply ketch-px-c10 ketch-py-[13px] hover:ketch-bg-border-color hover:ketch-bg-opacity-50;
          &.new {
            @apply ketch-bg-primary-color ketch-bg-opacity-[0.08];
          }
          .meta-info,
          .content {
            @apply ketch-flex ketch-flex-col;
          }
          .meta-info {
            @apply ketch-min-w-[105px] ketch-space-y-c5;
            .source-logo {
              @apply ketch-max-h-c20 ketch-max-w-[105px];
            }
            .date {
              @apply ketch-text-secondary-text-color;
            }
          }
          .content {
            @apply ketch-space-y-c5;
            .title {
              @apply ketch-font-bold;
            }
            .description {
              @apply ketch-text-secondary-text-color ketch-overflow-hidden ketch-text-ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
  .ketch-dark .all-news-wrapper {
    .all-news {
      img.source-logo {
        @apply ketch-filter-none;
      }
    }
  }
</style>
