import { render, staticRenderFns } from "./Notes.vue?vue&type=template&id=6d6786d4&scoped=true&lang=pug"
import script from "./Notes.vue?vue&type=script&setup=true&lang=ts"
export * from "./Notes.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Notes.vue?vue&type=style&index=0&id=6d6786d4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d6786d4",
  null
  
)

export default component.exports