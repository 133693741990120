import { computed, ComputedRef } from 'vue'
import { DateTime } from 'luxon'
import { QuestionAnswer } from '@/services/interfaces/QuizQuestion'
import { UserModule } from '@/store/modules/user'
import { ThemeModule } from '@/store/modules/theme'
//@ts-ignore
import KebabCase from 'kebab-case'
import removeAccents from 'remove-accents'
import useBreakpoint from '@/composables/useBreakpoint'
import useEditor from '@/composables/useEditor'
import UsersApi from '@/services/api/UsersApi'
import useSegment from '@/composables/useSegment'
import eventBus from '@/main'
import useI18n from '@/composables/useI18n'
import { Company, StructuredCompanyProfileQuestion } from '@/services/interfaces/Auth0'
import type { LanguageTranslation, WindowOpenEvent } from '@/services/interfaces/Common'
import CookieHelper from '@/helpers/CookieHelper'

interface useCommonMixinReturn {
  isDarkMode: ComputedRef<boolean>
  locale: ComputedRef<'de' | 'en'>
  iframeMode: ComputedRef<boolean>
  replaceNonAlphanumericChars: (name: string, replaceChar?: string) => string
  htmlSubstring: (htmlText: string, charsToReturn: number) => string
  formatDate: (Date: string, format: string) => string
  currencyFormatDE: (num: number) => string
  showVerifyEmailToasterMessage: () => void
  convertToRoman: (num: number) => string | number
  downloadItem: (downloadUrl: string) => void
  onAnswerChoiceClick: (selectedAnswers: QuestionAnswer[], choiceId: string, questionId: string, type?: string) => void
  setPhoneOrTextAnswer: (
    selectedAnswers: QuestionAnswer[],
    questionId: string,
    text: string,
    answerType: 'phone' | 'text',
  ) => void
  profileQuestionIsUnfilled: ComputedRef<
    (selectedAnswers: QuestionAnswer[], question: StructuredCompanyProfileQuestion) => boolean
  >
  productDiscountValue: number
  toKhebabCase: (item: any) => any
  fileExtension: (url: string) => string
  isImageFile: (url: string) => boolean
  isPdfFile: (url: string) => boolean
  formatPhrase: (title: string, term?: string) => string
  subDomainFromUrl: ComputedRef<string | null>
  convertNumberAnswer: (num: string) => string
  correctNumberAnswer: (submittedAnswer: string, actualAnswer: string) => boolean
  revertConvertNumberInput: (num: string) => number
  inWebExtension: ComputedRef<boolean>
  joinArrayOfStringsWithNewlineCharacter: (data: string[]) => string
  convertStringWithNewLineCharactersIntoArrayOfStrings: (text: string) => string[]
  daysDifferenceFromNow: (date: string, future?: boolean) => number
  getInitials: (name: string) => string
  displayUserRole: (role: string) => string
  redirectToNewDomain: (newDomain: string, pathname?: string) => void
  openAssignCoursesModal: (user: { id: string; name: string; email: string }) => void
  openCheckoutModal: (data: {
    user: { id: string; name: string }
    isPackageCheckout?: boolean
    showPreviousCTA?: boolean
    extraClass?: string
    inModuleOrLessonView?: boolean
    showCustomCheckoutTitle?: boolean
  }) => void
  shuffleArray: (array: Array<any>) => Array<any>
  groupBgColor: ComputedRef<(colorId: string) => string>
  nextOnboardingPage: ComputedRef<(currPageName: string) => string | undefined>
  isSachkundeCompany: ComputedRef<boolean>
  isKetchUpCompany: ComputedRef<boolean>
  showAdminNav: ComputedRef<boolean>
  showTranslateSelector: ComputedRef<boolean>
  triggerToursAndHints: ComputedRef<boolean>
  isDrKleinCompany: ComputedRef<boolean>
  isDevCompany: ComputedRef<boolean>
  is34ACompany: ComputedRef<boolean>
  isKnowledgeCheckCompany: ComputedRef<boolean>
  scrollIntoSection: (sectionId: string, top: number) => void
  showCompanyOnboardingPopupDetails: () => void
  showExamAppointmentModal: (courseId: string | null) => void
  userMembersPerPageLimit: ComputedRef<number>
  formatNumberToGermanLocale: ComputedRef<(num: number) => string>
  getColorVariableValue: ComputedRef<(colorVariable: string) => string>
  stripHtmlTagsFromString: ComputedRef<(text: string) => string>
  setIsLoadingComponentViewData: (value: boolean) => void
  languageTranslations: ComputedRef<LanguageTranslation[]>
  identifyUserInSegmentWithExamDates: (shortTitle: string, examDate: string | null) => void
  windowOpenProxyCheck: (targetUrl: string, openEvent: WindowOpenEvent) => void
  desktopLogo: ComputedRef<string | null>
  logoUrl: ComputedRef<string | null>
  companySubdomainDetails: ComputedRef<Company | null>
  hasAccessToCompanySubdomain: ComputedRef<boolean>
  academySignupBenefits: ComputedRef<string[]>
  highlightedCourseBundlePrefix: ComputedRef<string | null>
}

export default function useCommonMixin(): useCommonMixinReturn {
  const { isDesktopDevice, isMobileDevice } = useBreakpoint()
  const { inEditorMode } = useEditor()
  const { companyOnboardingPopupClosed, windowOpenBlocked } = useSegment()
  const { translateString } = useI18n()

  const isDarkMode = computed((): boolean => {
    return ThemeModule.theme === 'dark'
  })

  const replaceNonAlphanumericChars = (name: string, replaceChar = '-'): string => {
    const nospaces = name.replace(/\s/g, replaceChar)
    return nospaces.replace(/[^A-Za-z0-9_-]/g, '')
  }

  const iframeMode = computed((): boolean => {
    return eventBus?.$route.query.iframe_mode === 'true'
  })

  const locale = computed((): 'de' | 'en' => {
    let lang: 'en' | 'de' = 'en'
    if (['de', 'de-DE'].includes(eventBus.$locale)) {
      lang = 'de'
    }
    return lang
  })

  const formatDate = (Date: string, format: string): string => {
    return DateTime.fromISO(Date).toFormat(format)
  }

  const showVerifyEmailToasterMessage = (): void => {
    eventBus.$toasted.error(translateString('checkout.errors.verifyYourEmail'), { duration: 8000 })
  }

  const htmlSubstring = (htmlText: string, charsToReturn: number): string => {
    let m
    const r = /<([^>\s]*)[^>]*>/g
    const stack = []
    let lasti = 0
    let result = ''

    //for each tag, while we don't have enough characters
    while ((m = r.exec(htmlText)) && charsToReturn) {
      //get the text substring between the last tag and this one
      const temp = htmlText.substring(lasti, m.index).substr(0, charsToReturn)
      //append to the result and count the number of characters added
      result += temp
      charsToReturn -= temp.length
      lasti = r.lastIndex

      if (charsToReturn) {
        result += m[0]
        if (m[1].indexOf('/') === 0) {
          //if this is a closing tag, than pop the stack (does not account for bad html)
          stack.pop()
        } else if (m[1].lastIndexOf('/') !== m[1].length - 1) {
          //if this is not a self closing tag than push it in the stack
          stack.push(m[1])
        }
      }
    }

    //add the remainder of the string, if needed (there are no more tags in here)
    result += htmlText.substr(lasti, charsToReturn)

    //fix the unclosed tags
    while (stack.length) {
      result += '</' + stack.pop() + '>'
    }

    return result
  }

  function currencyFormatDE(num: number) {
    if (!num) return ''
    return (
      num
        .toFixed(2) // always two decimal digits
        .replace('.', ',') // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
    ) // use . as a separator
  }

  function convertToRoman(num: number) {
    if (isNaN(num)) return NaN
    // eslint-disable-next-line prefer-const
    let digits = String(+num).split(''),
      // eslint-disable-next-line prefer-const
      key = [
        '',
        'C',
        'CC',
        'CCC',
        'CD',
        'D',
        'DC',
        'DCC',
        'DCCC',
        'CM',
        '',
        'X',
        'XX',
        'XXX',
        'XL',
        'L',
        'LX',
        'LXX',
        'LXXX',
        'XC',
        '',
        'I',
        'II',
        'III',
        'IV',
        'V',
        'VI',
        'VII',
        'VIII',
        'IX',
      ],
      roman = '',
      i = 3
    while (i--) {
      // @ts-ignore
      roman = (key[+digits.pop() + i * 10] || '') + roman
    }
    return Array(+digits.join('') + 1).join('M') + roman
  }

  const downloadItem = (url: string) => {
    const a = document.createElement('a')
    a.href = url
    a.setAttribute('download', '')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const onAnswerChoiceClick = (
    selectedAnswers: QuestionAnswer[],
    choiceId: string,
    questionId: string,
    type?: string,
  ) => {
    const questionAnsObj = selectedAnswers.find((el) => el.questionId === questionId)
    if (selectedAnswers.length && questionAnsObj) {
      const index = questionAnsObj.selectedAnswerIds.indexOf(choiceId)
      if (index === -1) {
        if (type === 'dropdown' || type === 'single') questionAnsObj.selectedAnswerIds!.shift()
        questionAnsObj.selectedAnswerIds.push(choiceId)
      } else {
        questionAnsObj.selectedAnswerIds.splice(index, 1)
      }
    } else {
      selectedAnswers.push({
        questionId: questionId,
        selectedAnswerIds: [choiceId],
      })
    }
  }

  const setPhoneOrTextAnswer = (
    selectedAnswers: QuestionAnswer[],
    questionId: string,
    text: string,
    answerType: 'phone' | 'text',
  ) => {
    let entry = selectedAnswers.find((a) => a.questionId === questionId)

    if (entry) {
      entry[`${answerType}Answer`] = text
    } else {
      entry = {
        questionId,
        selectedAnswerIds: [],
      }
      entry[`${answerType}Answer`] = text
      selectedAnswers.push(entry)
    }
  }

  const profileQuestionIsUnfilled = computed(() => {
    return (selectedAnswers: QuestionAnswer[], question: StructuredCompanyProfileQuestion): boolean => {
      if (!question.mandatory) return false

      const questionAnswerEntry = selectedAnswers.find((a) => a.questionId === question.id)

      if (!questionAnswerEntry) return true

      switch (question.type) {
        case 'multiple':
        case 'dropdown':
          return !questionAnswerEntry.selectedAnswerIds.length
        case 'text':
          return !questionAnswerEntry.textAnswer
        case 'phone_input':
          return !questionAnswerEntry.phoneAnswer
        default:
          return true
      }
    }
  })

  const isObject = (item: any): boolean => {
    return item && typeof item === 'object' && !Array.isArray(item)
  }

  const toKhebabCase = (item: any): any => {
    for (const key in item) {
      const value = item[key]
      delete item[key]
      item[KebabCase(key) as string] = value
      if (isObject(item[key])) return toKhebabCase(item[key])
    }
    return item
  }

  const fileExtension = (url: string): string => {
    const index = url.lastIndexOf('.')
    return url.substr(index).toLowerCase()
  }

  const isPdfFile = (url: string): boolean => {
    return fileExtension(url) === '.pdf'
  }

  const isImageFile = (url: string): boolean => {
    return ['.jpg', '.jpeg', '.gif', '.png', '.bmp', '.heic'].includes(fileExtension(url))
  }

  const productDiscountValue = 0.2

  const termMatchesWord = (word: string, term: string): boolean => {
    return removeAccents(word).toLowerCase().includes(removeAccents(term).toLowerCase())
  }

  const formatPhrase = (title: string, term?: string): string => {
    const searchTerm = (eventBus.$route.query.q as string) || term
    if (!searchTerm) return title
    return title
      .split(' ')
      .map((word) => {
        const termFilters = searchTerm.split(' ').filter((term) => termMatchesWord(word, term))
        if (termFilters.length) {
          return word
            .split(/\n/)
            .map((w) => {
              if (termFilters.some((t) => termMatchesWord(w, t))) {
                return `<em class="special">${w}</em>`
              }
              return w
            })
            .join('\n')
        }
        return word
      })
      .join(' ')
  }

  const subDomainFromUrl = computed(() => {
    const subDomain = window.location.hostname.split('.')[0]
    if (!window.location.hostname.includes('ketchup.academy')) return null
    return subDomain
  })

  const redirectToNewDomain = async (newDomain: string, pathname?: string) => {
    const url = new URL(window.location.href)
    url.host = `${newDomain}.ketchup.academy`
    if (pathname) url.pathname = pathname
    window.location.replace(url.href)
  }

  const convertNumberAnswer = (num: string): string => {
    // to replace . with , to correspond with EU currency
    return num.replace('.', ',').trim()
  }

  const normalizeNumberString = (numberString: string) => {
    let normalized = numberString.trim()
    normalized = normalized.replace(',', '.')

    if (normalized.includes('.')) {
      // remove trailing zeros
      normalized = normalized.replace(/0+$/, '')
      // if the last character is a decimal point, remove it
      if (normalized.endsWith('.')) {
        normalized = normalized.slice(0, -1)
      }
    }
    return normalized
  }

  const correctNumberAnswer = (submittedAnswer: string, actualAnswer: string) => {
    return normalizeNumberString(submittedAnswer) === normalizeNumberString(actualAnswer)
  }

  const revertConvertNumberInput = (num: string): number => {
    return Number(num.replace(',', '.').trim())
  }

  const inWebExtension = computed(() => {
    const ancestorOrigin = window.location.ancestorOrigins?.item(0)
    return !!ancestorOrigin?.startsWith('chrome-extension')
  })

  const joinArrayOfStringsWithNewlineCharacter = (data: string[]) => {
    return data.join('\r\n')
  }

  const convertStringWithNewLineCharactersIntoArrayOfStrings = (text: string) => {
    return text
      .trim()
      .split('\n')
      .filter((string) => string)
  }

  const daysDifferenceFromNow = (date: string, future?: boolean) => {
    const startDate = DateTime.fromISO(date)
    const endDate = DateTime.now()
    const difference = future
      ? startDate.diff(endDate, 'days').toObject().days
      : endDate.diff(startDate, 'days').toObject().days

    return difference ? (future ? difference : Math.floor(difference)) : 0
  }

  const getInitials = (name: string) => {
    if (!name) return ''
    const arrayOfSplitStrings = name.split(' ')
    const firstInitial = arrayOfSplitStrings[0].charAt(0)
    if (arrayOfSplitStrings.length === 1) return firstInitial
    const secondInitial = arrayOfSplitStrings[arrayOfSplitStrings.length - 1].charAt(0)
    return firstInitial + secondInitial
  }

  const displayUserRole = (role: string) => {
    const replacedStr = role.toLowerCase().replace('_', ' ')
    return replacedStr.charAt(0).toUpperCase() + replacedStr.slice(1)
  }

  const openAssignCoursesModal = (user: { id: string; name: string; email: string }) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'AssignUserCourseModal',
      modalProps: {
        user,
      },
      cssClass: 'assign-user-course-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const openCheckoutModal = (data: {
    user: { id: string; name: string }
    isPackageCheckout?: boolean
    showPreviousCTA?: boolean
    extraClass?: string
    inModuleOrLessonView?: boolean
    showCustomCheckoutTitle?: boolean
  }) => {
    eventBus.$emit('close-modal')
    eventBus.$emit('show-modal', {
      modalContentComponent: 'CheckoutModal',
      modalTitle: translateString('homepage.assignedCourses.buyCourse'),
      modalProps: {
        user: data.user,
        isPackageCheckout: data.isPackageCheckout,
        showPreviousCTA: data.showPreviousCTA,
        inModuleOrLessonView: data.inModuleOrLessonView,
        showCustomCheckoutTitle: data.showCustomCheckoutTitle,
      },
      cssClass: 'checkout-modal' + (data.extraClass ?? ''),
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const shuffleArray = (array: Array<any>) => {
    let currentIndex = array.length,
      randomIndex

    // while there remains elements to shuffle
    while (currentIndex != 0) {
      // pick a remaining element
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // swap it with the current element
      ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
  }

  const groupBgColor = computed(() => {
    return (colorId: string) => {
      return UserModule.groupColors?.find((c) => c.id === colorId)?.backgroundColor || ''
    }
  })

  const nextOnboardingPage = computed(() => (currPageName: string) => {
    const currentOnboardingPage = UserModule.onboardingPages.find((page) => page.pageName === currPageName)
    if (!currentOnboardingPage) return ''
    const nextPage = UserModule.onboardingPages.find((page) => page.pageNumber === currentOnboardingPage.pageNumber + 1)
    return nextPage?.pageName
  })

  const isSachkundeCompany = computed(() => UserModule.currentCompany?.id === '7573f9d0-2995-44c3-aa8f-b032e97c1a2d')

  const isKetchUpCompany = computed(() => UserModule.currentCompany?.id === 'e8137522-4f13-4eed-8198-6ef9ee0845ee')

  const isDrKleinCompany = computed(() => UserModule.currentCompany?.id === 'a5684577-7d11-48db-90cd-59b462774dca')

  const isDevCompany = computed(() => UserModule.currentCompany?.id === 'f9cace57-ecb2-4852-a108-9f37568b7235')

  const is34ACompany = computed(() => UserModule.currentCompany?.id === 'ae192a20-59d1-47d4-8ae6-b6b06b6d95bf')

  const isKnowledgeCheckCompany = computed(
    () => UserModule.currentCompany?.id === 'a336dc8e-5b1f-4e60-99d2-dee4c4989e34',
  )

  const showAdminNav = computed(
    () => UserModule.isCompanyAdmin && isDesktopDevice.value && !eventBus.$route.meta?.hideAdminNav,
  )

  const showTranslateSelector = computed(
    () =>
      !!(UserModule.currentCompany?.showTranslateSelector || UserModule.company?.showTranslateSelector) &&
      !UserModule.isCompanyEditor,
  )

  // a temporary solution to disable tours and hints, can be set in the BE under company later
  const triggerToursAndHints = computed(() => false)

  const scrollIntoSection = (sectionId: string, top: number) => {
    const element = document.getElementById(sectionId) as HTMLDivElement
    if (element) {
      const y = top + window.scrollY
      window.scroll({
        top: y - (showAdminNav.value ? (inEditorMode.value ? 185 : 120) : inEditorMode.value ? 145 : 80),
        behavior: 'smooth',
      })
    }
  }

  const showCompanyOnboardingPopupDetails = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'CompanyOnboardingModal',
      cssClass: 'company-onboarding-modal',
      modalProps: {
        onboardingPopupDetails: UserModule.companyOnboardingPopupDetails,
      },
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
        UserModule.setReTriggerOnboardingPopup(true)
        UserModule.setShowCompanyOnboardingPopup(false)
        companyOnboardingPopupClosed(UserModule.currentCompany!.id)
        // set showCompanyPopup to false
        UsersApi.updateShowOnboardingPopup(UserModule.auth0Id, false)
      },
    })
  }

  const showExamAppointmentModal = (courseId: string | null) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'ExamAppointmentModal',
      cssClass: 'exam-appointment-modal',
      closeModalOnOutsideClick: false,
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
        CookieHelper.setCrossDomainCookie('skip-setting-exam-appointment', 'true')
      },
      modalProps: {
        courseId,
      },
    })
  }

  const userMembersPerPageLimit = computed(() => {
    if (!process.env.VUE_APP_MEMBERS_PER_PAGE) return 20
    return parseInt(process.env.VUE_APP_MEMBERS_PER_PAGE)
  })

  const formatNumberToGermanLocale = computed(() => (number: number) => {
    return number.toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  })

  const getColorVariableValue = computed(
    () => (colorVariable: string) =>
      (window as any).getComputedStyle(document.documentElement).getPropertyValue(`${colorVariable}`),
  )

  const stripHtmlTagsFromString = computed(() => (text: string) => text.replace(/(<([^>]+)>)/gi, ''))

  const setIsLoadingComponentViewData = (value: boolean) => {
    if (value) {
      UserModule.setLoadingComponentViewData(true)
    } else {
      UserModule.setLoadingComponentViewData(false)
    }
  }

  const languageTranslations = computed(() => UserModule.languagesTranslations)

  const identifyUserInSegmentWithExamDates = (shortTitle: string, examDate: string | null) => {
    const { analytics } = window as any
    if (!analytics) return

    const userAttributes: { [key: string]: string | null } = {
      name: UserModule.fullname,
      email: UserModule.email,
      company: UserModule.currentCompany?.name || '',
    }

    const courseExamNameAttribute = shortTitle
      .replace(/\s+/g, '_')
      .replace(/.*?(\d+)/, '$1')
      .replace(/[()]/g, '')

    const dynamicExamDateValue = `${courseExamNameAttribute}_start_date`
    userAttributes[dynamicExamDateValue] = examDate ? `${examDate} 00:00:00` : null
    analytics.identify(UserModule.auth0Id, userAttributes)
  }

  const windowOpenProxyCheck = (targetUrl: string, openEvent: WindowOpenEvent) => {
    const newWindow = window.open(targetUrl, '_blank')
    if (newWindow && !newWindow.closed) {
      newWindow.focus()
    } else {
      windowOpenBlocked(UserModule.email, openEvent)
      window.location.assign(targetUrl)
    }
  }

  const desktopLogo = computed(() => UserModule.desktopLogoUrl)

  const logoUrl = computed(() => {
    const mobileLogo = UserModule.mobileLogoUrl
    if (isMobileDevice.value && mobileLogo) return mobileLogo
    return desktopLogo.value
  })

  const companySubdomainDetails = computed(() => UserModule.companySubdomainDetails)

  const hasAccessToCompanySubdomain = computed(() => {
    return UserModule.companies.some((c) => c.id === companySubdomainDetails.value?.id)
  })

  const academySignupBenefits = computed(
    () =>
      UserModule.company?.signupBenefitsBulletPoints ?? companySubdomainDetails.value?.signupBenefitsBulletPoints ?? [],
  )

  const highlightedCourseBundlePrefix = computed(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const prefix = urlParams.get('bundlePrefix') || CookieHelper.getCookieValue('bundlePrefix')

    const allowedPrefixes = ['34i', '34d', '34f']
    return allowedPrefixes.includes(prefix) ? prefix : null
  })

  return {
    isDarkMode,
    replaceNonAlphanumericChars,
    locale,
    iframeMode,
    htmlSubstring,
    formatDate,
    currencyFormatDE,
    showVerifyEmailToasterMessage,
    convertToRoman,
    downloadItem,
    onAnswerChoiceClick,
    setPhoneOrTextAnswer,
    profileQuestionIsUnfilled,
    productDiscountValue,
    toKhebabCase,
    fileExtension,
    isPdfFile,
    isImageFile,
    formatPhrase,
    subDomainFromUrl,
    convertNumberAnswer,
    correctNumberAnswer,
    revertConvertNumberInput,
    inWebExtension,
    joinArrayOfStringsWithNewlineCharacter,
    convertStringWithNewLineCharactersIntoArrayOfStrings,
    daysDifferenceFromNow,
    getInitials,
    displayUserRole,
    redirectToNewDomain,
    openAssignCoursesModal,
    openCheckoutModal,
    shuffleArray,
    groupBgColor,
    nextOnboardingPage,
    isSachkundeCompany,
    isKetchUpCompany,
    isDevCompany,
    is34ACompany,
    isKnowledgeCheckCompany,
    showAdminNav,
    showTranslateSelector,
    triggerToursAndHints,
    scrollIntoSection,
    showCompanyOnboardingPopupDetails,
    showExamAppointmentModal,
    userMembersPerPageLimit,
    formatNumberToGermanLocale,
    isDrKleinCompany,
    getColorVariableValue,
    stripHtmlTagsFromString,
    setIsLoadingComponentViewData,
    languageTranslations,
    identifyUserInSegmentWithExamDates,
    windowOpenProxyCheck,
    desktopLogo,
    logoUrl,
    academySignupBenefits,
    highlightedCourseBundlePrefix,
    companySubdomainDetails,
    hasAccessToCompanySubdomain,
  }
}
