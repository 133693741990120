import {
  NewGroup,
  Socials,
  UserGamificationActivity,
  UserGamificationActivityResource,
} from '@/services/interfaces/Auth0'
import { GetStartedStepEntity, WindowOpenEvent } from '@/services/interfaces/Common'

interface useSegmentReturn {
  trackPage: (page: string) => Promise<any>
  trackEvent: (
    eventType: string,
    payload: {
      title?: string
      content_id?: string
      event_type?: string
      content_type?: string
      quiz_id?: string
      passed?: boolean
      completion_id?: string
      number_of_questions_in_part?: number
      number_of_questions_answered_in_part?: number
      in_iframe_mode?: boolean
      provider?: string
      search_term?: string
      news_url?: string
      question_id?: string
      learning_goal_id?: string
      news_id?: string
      email_address?: string
      selected_categories?: string[]
      email_verified?: boolean
      source?: string
      course_id?: string
      course_name?: string
      product_type?: string
      invoice_url?: string
      course_state?: string
      module_id?: string
      module_name?: string
      lesson_id?: string
      lesson_name?: string
      download_url?: string
      page_title?: string
      tip_number?: number
      course_bundle_id?: string
      course_bundle_name?: string
      proposal?: string
      company_id?: string
      user_emails?: string[]
      group_ids?: string[]
      groups?: NewGroup[]
      exam_id?: string
      time_remaining_on_part?: number
      question_number?: number
      total_questions?: number
      part_number?: number
      question_number_on_part?: number
      total_questions_on_part?: number
      answered_question_correctly?: boolean
      action?: UserGamificationActivity
      points?: number
      resource?: UserGamificationActivityResource | null
      value?: number
      step_number?: number
      step_entity?: GetStartedStepEntity
      completed?: boolean
      level_name?: string
      exam_centre_id?: string
      exam_date_id?: string
      exam_date?: string
      user_id?: string
      leuckentext_id?: string
      invite_code?: string
      language_name?: string
      language_code?: string
      window_open_event?: WindowOpenEvent
    },
  ) => Promise<any>
  pageViewed: (pageTitle: string) => Promise<any>
  frontendUserSignedUp: (email: string) => Promise<any>
  frontendCourseTrialStarted: (email: string, courseId: string, courseName: string) => Promise<any>
  recommendedCourseClicked: (courseId: string, courseName: string) => Promise<any>
  recommendedCourseGetNotified: (courseId: string, courseName: string) => Promise<any>
  coursePurchased: (courseId: string, courseName: string, value: number) => Promise<any>
  packagePurchased: (courseBundleId: string, courseBundleName: string, value: number) => Promise<any>
  invoiceDownloaded: (productType: string, invoiceUrl: string) => Promise<any>
  courseUpsellModalShown: (courseId: string, courseName: string, source: string) => Promise<any>
  coursePurchaseIntended: (courseId: string, courseName: string, source: string) => Promise<any>
  coursePackagePurchaseIntended: (courseBundleId: string, courseBundleName: string, source: string) => Promise<any>
  courseItemClicked: (courseId: string, courseName: string, courseState: string, source: string) => Promise<any>
  lessonImageDownloaded: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
    downloadUrl: string,
  ) => Promise<any>
  courseMaterialDownloaded: (courseId: string, courseName: string, downloadUrl: string) => Promise<any>
  tryCourseForFreeButtonClicked: (courseId: string, courseName: string) => Promise<any>
  startModuleButtonClicked: (courseId: string, courseName: string, moduleId: string, moduleName: string) => Promise<any>
  videoStarted: (
    contentId: string,
    contentType: string,
    courseId?: string,
    courseName?: string,
    moduleId?: string,
    moduleName?: string,
    lessonId?: string,
    lessonName?: string,
  ) => Promise<any>
  companyOnboardingPopupVideoStarted: (companyId: string) => Promise<any>
  getStartedCourseOpened: (courseId: string) => Promise<any>
  getStartedStepClicked: (stepNumber: number, stepEntity: GetStartedStepEntity, completed: boolean) => Promise<any>
  getStartedVideoModalShown: (stepNumber: number, stepEntity: GetStartedStepEntity) => Promise<any>
  videoPaused: (
    contentId: string,
    contentType: string,
    courseId?: string,
    courseName?: string,
    moduleId?: string,
    moduleName?: string,
    lessonId?: string,
    lessonName?: string,
  ) => Promise<any>
  videoCompleted: (
    contentId: string,
    contentType: string,
    courseId?: string,
    courseName?: string,
    moduleId?: string,
    moduleName?: string,
    lessonId?: string,
    lessonName?: string,
  ) => Promise<any>
  examTimedOut: (
    quizId: string,
    totalQuestionsInPart: number,
    totalQuestionsAnsweredInPart: number,
    courseId?: string,
    courseName?: string,
  ) => Promise<any>
  lessonQuizCompleted: (
    quizId: string,
    passed: boolean,
    completionId: string,
    course_id: string,
    courseName: string,
    module_id: string,
    moduleName: string,
  ) => Promise<any>
  guidedQuizCompleted: (
    guidedQuizId: string,
    completionId: string,
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
  ) => Promise<any>
  examCompleted: (courseId: string, courseName: string, examId: string, completionId: string | null) => Promise<any>
  bookmarkAdded: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => Promise<any>
  bookmarkRemoved: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => Promise<any>
  userInfoUpdated: () => Promise<any>
  resetToursAndHints: () => Promise<any>
  accountDeleted: () => Promise<any>
  emailVerificationRequested: (email: string) => Promise<any>
  passwordResetRequested: (email: string) => Promise<any>
  categoryInterestsUpdated: (selectedCategoriesIds: string[]) => Promise<any>
  newsItemOpened: (newsUrl: string, newsId: string) => Promise<any>
  contentTermSearched: (searchTerm: string) => Promise<any>
  nextTourTipRequested: (pageTitle: string, tipNumber: number) => Promise<any>
  lessonFeedbackProvided: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
    comment: string,
    rating: number | null,
  ) => Promise<any>
  questionFeedbackProvided: (
    courseId: string,
    courseName: string,
    questionId: string,
    questionTitle: string,
    comment: string,
  ) => Promise<any>
  guidedQuizQuestionAnswered: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    questionId: string,
    questionNumber: number,
    totalQuestions: number,
    answeredQuestionCorrectly: boolean,
  ) => Promise<any>
  examQuestionAnswered: (
    courseId: string,
    courseName: string,
    examId: string,
    questionId: string,
    partNumber: number,
    questionNumberOnPart: number,
    totalQuestionsOnPart: number,
    answeredQuestionCorrectly: boolean,
  ) => Promise<any>
  courseProposalSubmitted: (proposal: string) => Promise<any>
  companyUsersGroupsAssigned: (companyId: string, userEmails: string[], groupIds: string[]) => Promise<any>
  userGroupCreated: (companyId: string, groups: NewGroup[]) => Promise<any>
  examStarted: (examId: string, courseId: string, courseName: string) => Promise<any>
  examPaused: (courseId: string, courseName: string, examId: string, timeRemainingOnPart: number) => Promise<any>
  examAborted: (quizId: string, courseId: string, courseName: string) => Promise<any>
  examContinued: (courseId: string, courseName: string, examId: string, timeRemainingOnPart: number) => Promise<any>
  examPartCompleted: (courseId: string, courseName: string, examId: string, partNumber: number) => Promise<any>
  examQuestionsReviewed: (courseId: string, courseName: string, examId: string, completionId: string) => Promise<any>
  gamificationPointsUpdated: (
    action: UserGamificationActivity,
    points: number,
    resource: UserGamificationActivityResource | null,
  ) => Promise<any>
  lessonNoteSaved: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => Promise<any>
  companyOnboardingPopupClosed: (companyId: string) => Promise<any>
  socialMediaClicked: (companyId: string, companyName: string, service: Socials) => Promise<any>
  newGamificationLevelPopupShown: (companyId: string, levelName: string, points: number) => Promise<any>
  examAppointmentAdded: (courseId: string, courseName: string, examDate: string) => Promise<any>
  examAppointmentRemoved: (courseId: string, courseName: string) => Promise<any>
  getYourOwnAcademyDocumentDownloaded: () => Promise<any>
  getYourOwnAcademyBannerClosed: () => Promise<any>
  leuckentextQuizCompleted: (
    leuckentextId: string,
    completionId: string,
    passed: boolean,
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => Promise<any>
  lessonViewed: (
    companyId: string,
    lessonId: string,
    lessonName: string,
    moduleId: string,
    courseId: string,
    languageCode: string,
  ) => Promise<any>
  courseTrialExpiredPopupShown: (courseId: string, courseName: string) => Promise<any>
  referralCandyInviteCodeModalShown: (email: string) => Promise<any>
  referralCandyInviteCodeCopied: (email: string, inviteCode: string) => Promise<any>
  languageTranslationToggled: (languageName: string, languageCode: string) => Promise<any>
  invalidEmailShopifyCheckout: (email: string) => Promise<any>
  stripeCheckoutUsed: (email: string) => Promise<any>
  skgCoursePurchaseIntended: (userEmail: string, courseId: string) => Promise<any>
  routeToLastAccessedLessonClicked: (userEmail: string) => Promise<any>
  windowOpenBlocked: (userEmail: string, windowOpenEvent: WindowOpenEvent) => Promise<any>
}

export default function useSegment(): useSegmentReturn {
  const trackPage = (page: string): Promise<any> => {
    const analytics = (window as any).analytics
    return analytics?.page(page)
  }

  const trackEvent = (
    eventType: string,
    payload: {
      title?: string
      content_id?: string
      event_type?: string
      content_type?: string
      quiz_id?: string | null
      passed?: boolean | null
      completion_id?: string | null
      number_of_questions_in_part?: number
      number_of_questions_answered_in_part?: number
      in_iframe_mode?: boolean
      provider?: string
      search_term?: string
      news_url?: string
      question_id?: string
      question_title?: string
      learning_goal_id?: string
      news_id?: string
      email_address?: string
      selected_categories?: string[]
      email_verified?: boolean
      source?: string
      course_id?: string
      course_name?: string
      product_type?: string
      invoice_url?: string
      course_state?: string
      module_id?: string
      module_name?: string
      lesson_id?: string
      lesson_name?: string
      download_url?: string
      page_title?: string
      tip_number?: number
      course_bundle_id?: string
      course_bundle_name?: string
      comment?: string
      rating?: number | null
      quiz_type?: string
      proposal?: string
      company_id?: string
      user_emails?: string[]
      group_ids?: string[]
      groups?: NewGroup[]
      exam_id?: string
      time_remaining_on_part?: number
      question_number?: number
      total_questions?: number
      part_number?: number
      question_number_on_part?: number
      total_questions_on_part?: number
      answered_question_correctly?: boolean
      action?: UserGamificationActivity
      points?: number
      resource?: UserGamificationActivityResource | null
      service?: Socials
      company_name?: string
      value?: number
      step_number?: number
      step_entity?: GetStartedStepEntity
      completed?: boolean
      level_id?: string
      level_name?: string
      exam_centre_id?: string
      exam_date_ids?: string[]
      exam_date_id?: string
      exam_date?: string
      user_id?: string
      leuckentext_id?: string
      invite_code?: string
      language_name?: string
      language_code?: string
      window_open_event?: WindowOpenEvent
    },
  ): Promise<any> => {
    const analytics = (window as any).analytics
    return analytics?.track(eventType, payload)
  }

  const pageViewed = (pageTitle: string): Promise<any> => {
    return trackEvent('page_viewed', {
      title: pageTitle,
    })
  }

  const frontendUserSignedUp = (email: string) => {
    return trackEvent('frontend_user_signed_up', {
      email_address: email,
    })
  }

  const frontendCourseTrialStarted = (email: string, courseId: string, courseName: string) => {
    return trackEvent('frontend_course_trial_started', {
      email_address: email,
      course_id: courseId,
      course_name: courseName,
    })
  }

  const recommendedCourseClicked = (courseId: string, courseName: string): Promise<any> => {
    return trackEvent('recommended_course_clicked', {
      course_id: courseId,
      course_name: courseName,
    })
  }

  const recommendedCourseGetNotified = (courseId: string, courseName: string): Promise<any> => {
    return trackEvent('recommended_course_get_notified', {
      course_id: courseId,
      course_name: courseName,
    })
  }

  const coursePurchased = (courseId: string, courseName: string, value: number): Promise<any> => {
    return trackEvent('course_purchased', {
      course_id: courseId,
      course_name: courseName,
      value,
    })
  }

  const packagePurchased = (courseBundleId: string, courseBundleName: string, value: number): Promise<any> => {
    return trackEvent('package_purchased', {
      course_bundle_id: courseBundleId,
      course_bundle_name: courseBundleName,
      value,
    })
  }

  const invoiceDownloaded = (productType: string, invoiceUrl: string): Promise<any> => {
    return trackEvent('invoice_downloaded', {
      product_type: productType,
      invoice_url: invoiceUrl,
    })
  }

  const courseUpsellModalShown = (courseId: string, courseName: string, source: string): Promise<any> => {
    return trackEvent('course_upsell_modal_shown', {
      course_id: courseId,
      course_name: courseName,
      source,
    })
  }

  const coursePurchaseIntended = (courseId: string, courseName: string, source: string): Promise<any> => {
    return trackEvent('course_purchase_intended', {
      course_id: courseId,
      course_name: courseName,
      source,
    })
  }

  const coursePackagePurchaseIntended = (
    courseBundleId: string,
    courseBundleName: string,
    source: string,
  ): Promise<any> => {
    return trackEvent('package_purchase_intended', {
      course_bundle_id: courseBundleId,
      course_bundle_name: courseBundleName,
      source,
    })
  }

  const courseItemClicked = (
    courseId: string,
    courseName: string,
    courseState: string,
    source: string,
  ): Promise<any> => {
    return trackEvent('course_item_clicked', {
      course_id: courseId,
      course_name: courseName,
      course_state: courseState,
      source,
    })
  }

  const lessonImageDownloaded = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
    downloadUrl: string,
  ): Promise<any> => {
    return trackEvent('lesson_image_downloaded', {
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
      download_url: downloadUrl,
    })
  }

  const lessonNoteSaved = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ): Promise<any> => {
    return trackEvent('lesson_note_saved', {
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
    })
  }

  const courseMaterialDownloaded = (courseId: string, courseName: string, downloadUrl: string): Promise<any> => {
    return trackEvent('course_materials_downloaded', {
      course_id: courseId,
      course_name: courseName,
      download_url: downloadUrl,
    })
  }

  const tryCourseForFreeButtonClicked = (courseId: string, courseName: string): Promise<any> => {
    return trackEvent('try_course_for_free_button_clicked', {
      course_id: courseId,
      course_name: courseName,
    })
  }

  const startModuleButtonClicked = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
  ): Promise<any> => {
    return trackEvent('start_module_button_clicked', {
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
    })
  }

  const companyOnboardingPopupVideoStarted = (companyId: string) => {
    return trackEvent('company_onboarding_popup_video_started', {
      company_id: companyId,
    })
  }

  const getStartedCourseOpened = (courseId: string) => {
    return trackEvent('get_started_course_opened', {
      course_id: courseId,
    })
  }

  const getStartedStepClicked = (stepNumber: number, stepEntity: GetStartedStepEntity, completed: boolean) => {
    return trackEvent('get_started_step_clicked', {
      step_number: stepNumber,
      step_entity: stepEntity,
      completed,
    })
  }

  const getStartedVideoModalShown = (stepNumber: number, stepEntity: GetStartedStepEntity) => {
    return trackEvent('get_started_video_modal_shown', {
      step_number: stepNumber,
      step_entity: stepEntity,
    })
  }

  const videoStarted = (
    contentId: string,
    contentType: string,
    courseId?: string,
    courseName?: string,
    moduleId?: string,
    moduleName?: string,
    lessonId?: string,
    lessonName?: string,
  ): Promise<any> => {
    return trackEvent('video_started', {
      content_id: contentId,
      content_type: contentType,
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
    })
  }

  const videoPaused = (
    contentId: string,
    contentType: string,
    courseId?: string,
    courseName?: string,
    moduleId?: string,
    moduleName?: string,
    lessonId?: string,
    lessonName?: string,
  ): Promise<any> => {
    return trackEvent('video_paused', {
      content_id: contentId,
      content_type: contentType,
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
    })
  }

  const videoCompleted = (
    contentId: string,
    contentType: string,
    courseId?: string,
    courseName?: string,
    moduleId?: string,
    moduleName?: string,
    lessonId?: string,
    lessonName?: string,
  ): Promise<any> => {
    return trackEvent('video_completed', {
      content_id: contentId,
      content_type: contentType,
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
    })
  }

  const examAborted = (examId: string, courseId: string, courseName?: string): Promise<any> => {
    return trackEvent('exam_aborted', {
      exam_id: examId,
      course_id: courseId,
      course_name: courseName,
    })
  }

  const examTimedOut = (
    quizId: string,
    totalQuestionsInPart: number,
    totalQuestionsAnsweredInPart: number,
    courseId?: string,
    courseName?: string,
  ): Promise<any> => {
    return trackEvent('exam_timed_out', {
      quiz_id: quizId,
      number_of_questions_in_part: totalQuestionsInPart,
      number_of_questions_answered_in_part: totalQuestionsAnsweredInPart,
      course_id: courseId,
      course_name: courseName,
    })
  }

  const examStarted = (examId: string, courseId: string, courseName?: string): Promise<any> => {
    return trackEvent('exam_started', {
      exam_id: examId,
      course_id: courseId,
      course_name: courseName,
    })
  }

  const examPaused = (
    courseId: string,
    courseName: string,
    examId: string,
    timeRemainingOnPart: number,
  ): Promise<any> => {
    return trackEvent('exam_paused', {
      course_id: courseId,
      course_name: courseName,
      exam_id: examId,
      time_remaining_on_part: timeRemainingOnPart,
    })
  }

  const examContinued = (
    courseId: string,
    courseName: string,
    examId: string,
    timeRemainingOnPart: number,
  ): Promise<any> => {
    return trackEvent('exam_continued', {
      course_id: courseId,
      course_name: courseName,
      exam_id: examId,
      time_remaining_on_part: timeRemainingOnPart,
    })
  }

  const lessonQuizCompleted = (
    quizId: string,
    passed: boolean,
    completionId: string,
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
  ): Promise<any> => {
    return trackEvent('lesson_quiz_completed', {
      quiz_id: quizId,
      passed,
      completion_id: completionId,
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
    })
  }

  const leuckentextQuizCompleted = (
    leuckentextId: string,
    completionId: string,
    passed: boolean,
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ): Promise<any> => {
    return trackEvent('leuckentext_quiz_completed', {
      leuckentext_id: leuckentextId,
      completion_id: completionId,
      passed,
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
    })
  }

  const guidedQuizCompleted = (
    quizId: string,
    completionId: string,
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
  ): Promise<any> => {
    return trackEvent('guided_quiz_completed', {
      quiz_id: quizId,
      completion_id: completionId,
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
    })
  }

  const examCompleted = (
    courseId: string,
    courseName: string,
    examId: string,
    completionId: string | null,
  ): Promise<any> => {
    return trackEvent('exam_completed', {
      course_id: courseId,
      course_name: courseName,
      exam_id: examId,
      completion_id: completionId,
    })
  }

  const bookmarkAdded = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ): Promise<any> => {
    return trackEvent('bookmark_added', {
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
    })
  }

  const bookmarkRemoved = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ): Promise<any> => {
    return trackEvent('bookmark_removed', {
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
    })
  }

  const userInfoUpdated = () => {
    return trackEvent('user_info_updated', {})
  }

  const resetToursAndHints = () => {
    return trackEvent('tours_and_hints_reset', {})
  }

  const accountDeleted = () => {
    return trackEvent('account_deleted', {})
  }

  const emailVerificationRequested = (email: string) => {
    return trackEvent('email_verification_requested', {
      email_address: email,
    })
  }

  const passwordResetRequested = (email: string) => {
    return trackEvent('password_reset_requested', {
      email_address: email,
    })
  }

  const categoryInterestsUpdated = (selectedCategoriesIds: string[]) => {
    return trackEvent('category_interests_updated', {
      selected_categories: selectedCategoriesIds,
    })
  }

  const newsItemOpened = (newsUrl: string, newsId: string) => {
    return trackEvent('news_item_opened', {
      news_url: newsUrl,
      news_id: newsId,
    })
  }

  const contentTermSearched = (searchTerm: string) => {
    return trackEvent('content_term_searched', {
      search_term: searchTerm,
    })
  }

  const nextTourTipRequested = (pageTitle: string, tipNumber: number): Promise<any> => {
    return trackEvent('next_tour_tip_requested', {
      page_title: pageTitle,
      tip_number: tipNumber,
    })
  }

  const lessonFeedbackProvided = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
    comment: string,
    rating: number | null,
  ): Promise<any> => {
    return trackEvent('lesson_feedback_provided', {
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      lesson_id: lessonId,
      lesson_name: lessonName,
      comment,
      rating,
    })
  }

  const questionFeedbackProvided = (
    courseId: string,
    courseName: string,
    questionId: string,
    questionTitle: string,
    comment: string,
  ): Promise<any> => {
    return trackEvent('question_feedback_provided', {
      course_id: courseId,
      course_name: courseName,
      question_id: questionId,
      question_title: questionTitle,
      comment,
    })
  }

  const guidedQuizQuestionAnswered = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    questionId: string,
    questionNumber: number,
    totalQuestions: number,
    answeredQuestionCorrectly: boolean,
  ): Promise<any> => {
    return trackEvent('guided_quiz_question_answered', {
      course_id: courseId,
      course_name: courseName,
      module_id: moduleId,
      module_name: moduleName,
      question_id: questionId,
      question_number: questionNumber,
      total_questions: totalQuestions,
      answered_question_correctly: answeredQuestionCorrectly,
    })
  }

  const examQuestionAnswered = (
    courseId: string,
    courseName: string,
    examId: string,
    questionId: string,
    partNumber: number,
    questionNumberOnPart: number,
    totalQuestionsOnPart: number,
    answeredQuestionCorrectly: boolean,
  ): Promise<any> => {
    return trackEvent('exam_question_answered', {
      course_id: courseId,
      course_name: courseName,
      exam_id: examId,
      question_id: questionId,
      part_number: partNumber,
      question_number_on_part: questionNumberOnPart,
      total_questions_on_part: totalQuestionsOnPart,
      answered_question_correctly: answeredQuestionCorrectly,
    })
  }

  const examPartCompleted = (
    courseId: string,
    courseName: string,
    examId: string,
    partNumber: number,
  ): Promise<any> => {
    return trackEvent('exam_part_completed', {
      course_id: courseId,
      course_name: courseName,
      exam_id: examId,
      part_number: partNumber,
    })
  }

  const examQuestionsReviewed = (
    courseId: string,
    courseName: string,
    examId: string,
    completionId: string,
  ): Promise<any> => {
    return trackEvent('exam_questions_reviewed', {
      course_id: courseId,
      course_name: courseName,
      exam_id: examId,
      completion_id: completionId,
    })
  }

  const courseProposalSubmitted = (proposal: string): Promise<any> => {
    return trackEvent('course_proposal_submitted', {
      proposal,
    })
  }

  const companyUsersGroupsAssigned = (companyId: string, userEmails: string[], groupIds: string[]): Promise<any> => {
    return trackEvent('company_users_groups_assigned', {
      company_id: companyId,
      user_emails: userEmails,
      group_ids: groupIds,
    })
  }

  const userGroupCreated = (companyId: string, groups: NewGroup[]): Promise<any> => {
    return trackEvent('user_group_created', {
      company_id: companyId,
      groups,
    })
  }

  const gamificationPointsUpdated = (
    action: UserGamificationActivity,
    points: number,
    resource: UserGamificationActivityResource | null,
  ): Promise<any> => {
    return trackEvent('gamification_points_updated', {
      action,
      points,
      resource,
    })
  }

  const companyOnboardingPopupClosed = (companyId: string): Promise<any> => {
    return trackEvent('company_onboarding_popup_closed', {
      company_id: companyId,
    })
  }

  const socialMediaClicked = (companyId: string, companyName: string, service: Socials): Promise<any> => {
    return trackEvent('social_media_clicked', {
      company_id: companyId,
      company_name: companyName,
      service,
    })
  }

  const newGamificationLevelPopupShown = (companyId: string, levelName: string, points: number): Promise<any> => {
    return trackEvent('new_gamification_level_popup_shown', {
      company_id: companyId,
      level_name: levelName,
      points,
    })
  }

  const examAppointmentAdded = (courseId: string, courseName: string, examDate: string) => {
    return trackEvent('exam_appointment_added', {
      course_id: courseId,
      course_name: courseName,
      exam_date: examDate,
    })
  }

  const examAppointmentRemoved = (courseId: string, courseName: string) => {
    return trackEvent('exam_appointment_removed', {
      course_id: courseId,
      course_name: courseName,
    })
  }

  const getYourOwnAcademyDocumentDownloaded = () => {
    return trackEvent('get_your_own_academy_document_downloaded', {})
  }

  const getYourOwnAcademyBannerClosed = () => {
    return trackEvent('get_your_own_academy_banner_closed', {})
  }

  const lessonViewed = (
    companyId: string,
    lessonId: string,
    lessonName: string,
    moduleId: string,
    courseId: string,
    languageCode: string,
  ) => {
    return trackEvent('lesson_viewed', {
      company_id: companyId,
      lesson_id: lessonId,
      lesson_name: lessonName,
      module_id: moduleId,
      course_id: courseId,
      language_code: languageCode,
    })
  }

  const courseTrialExpiredPopupShown = (courseId: string, courseName: string) => {
    return trackEvent('course_trial_expired_popup_shown', {
      course_id: courseId,
      course_name: courseName,
    })
  }

  const referralCandyInviteCodeModalShown = (email: string) => {
    return trackEvent('referral_candy_invite_code_modal_shown', {
      email_address: email,
    })
  }

  const referralCandyInviteCodeCopied = (email: string, inviteCode: string) => {
    return trackEvent('referral_candy_invite_code_copied', {
      email_address: email,
      invite_code: inviteCode,
    })
  }

  const languageTranslationToggled = (languageName: string, languageCode: string) => {
    return trackEvent('language_toggled', {
      language_name: languageName,
      language_code: languageCode,
    })
  }

  const invalidEmailShopifyCheckout = (email: string) => {
    return trackEvent('shopify_checkout_email_invalidated', {
      email_address: email,
    })
  }

  const stripeCheckoutUsed = (email: string) => {
    return trackEvent('stripe_checkout_used', {
      email_address: email,
    })
  }

  const skgCoursePurchaseIntended = (userEmail: string, courseId: string) => {
    return trackEvent('skg_course_purchase_intended', {
      email_address: userEmail,
      course_id: courseId,
    })
  }

  const routeToLastAccessedLessonClicked = (userEmail: string) => {
    return trackEvent('route_to_last_accessed_lesson_clicked', {
      email_address: userEmail,
    })
  }

  const windowOpenBlocked = (userEmail: string, windowOpenEvent: WindowOpenEvent) => {
    return trackEvent('window_open_blocked', {
      email_address: userEmail,
      window_open_event: windowOpenEvent,
    })
  }

  return {
    trackPage,
    trackEvent,
    pageViewed,
    frontendUserSignedUp,
    frontendCourseTrialStarted,
    recommendedCourseClicked,
    recommendedCourseGetNotified,
    coursePurchased,
    packagePurchased,
    invoiceDownloaded,
    courseUpsellModalShown,
    coursePurchaseIntended,
    courseItemClicked,
    lessonImageDownloaded,
    courseMaterialDownloaded,
    tryCourseForFreeButtonClicked,
    startModuleButtonClicked,
    examAborted,
    examTimedOut,
    examStarted,
    examPaused,
    examContinued,
    lessonQuizCompleted,
    guidedQuizCompleted,
    examCompleted,
    videoStarted,
    companyOnboardingPopupVideoStarted,
    getStartedCourseOpened,
    getStartedStepClicked,
    getStartedVideoModalShown,
    videoPaused,
    videoCompleted,
    bookmarkAdded,
    bookmarkRemoved,
    userInfoUpdated,
    resetToursAndHints,
    accountDeleted,
    emailVerificationRequested,
    passwordResetRequested,
    categoryInterestsUpdated,
    newsItemOpened,
    contentTermSearched,
    nextTourTipRequested,
    coursePackagePurchaseIntended,
    lessonFeedbackProvided,
    questionFeedbackProvided,
    guidedQuizQuestionAnswered,
    examQuestionAnswered,
    examPartCompleted,
    examQuestionsReviewed,
    courseProposalSubmitted,
    companyUsersGroupsAssigned,
    userGroupCreated,
    gamificationPointsUpdated,
    lessonNoteSaved,
    companyOnboardingPopupClosed,
    socialMediaClicked,
    newGamificationLevelPopupShown,
    examAppointmentAdded,
    examAppointmentRemoved,
    getYourOwnAcademyDocumentDownloaded,
    getYourOwnAcademyBannerClosed,
    leuckentextQuizCompleted,
    lessonViewed,
    courseTrialExpiredPopupShown,
    referralCandyInviteCodeModalShown,
    referralCandyInviteCodeCopied,
    languageTranslationToggled,
    invalidEmailShopifyCheckout,
    stripeCheckoutUsed,
    skgCoursePurchaseIntended,
    routeToLastAccessedLessonClicked,
    windowOpenBlocked,
  }
}
