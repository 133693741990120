<script setup lang="ts">
  import { computed } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import { CourseModule } from '@/store/modules/course'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import { useRouter } from 'vue-router/composables'

  const router = useRouter()
  const { arrowRightIndicator } = useIcons()
  const { routeToLastAccessedLessonClicked } = useSegment()

  const lastUserCourseLog = computed(() => UserModule.lastUserCourseLog)

  const lastAccessedCourse = computed(() =>
    CourseModule.courseSectionsAssignedCourses?.find((c) => c.id === lastUserCourseLog.value?.courseId),
  )

  const routeToLastAccessedLesson = async () => {
    await routeToLastAccessedLessonClicked(UserModule.email)
    router
      .push({
        name: 'Lesson',
        params: {
          courseId: lastUserCourseLog.value!.courseId,
          moduleId: lastUserCourseLog.value!.moduleId,
          lessonId: lastUserCourseLog.value!.lessonId,
        },
      })
      .catch(() => {
        return
      })
  }

  if (UserModule.hasValidUser) {
    UserModule.setLastUserCourseLog()
  }
</script>

<template lang="pug">
.parent-component-wrapper(v-if='lastAccessedCourse')
    .component-spacing
        .last-user-activity-wrapper(@click='routeToLastAccessedLesson')
            h3 {{ $t('lastAccessedLesson') }}
            .last-user-course
                h5 {{ lastAccessedCourse.title }}
                SVGRenderer(
                    :fill-color='"var(--header-foreground-color)"',
                    :has-hover='false',
                    :icon='arrowRightIndicator',
                    width='20'
                )
</template>

<style scoped lang="postcss">
  .last-user-activity-wrapper {
    @apply ketch-px-c25 ketch-py-c10 ketch-bg-header-background-color ketch-cursor-pointer;
    @apply ketch-flex ketch-flex-col ketch-z-[2] ketch-mt-c30 md:ketch-mt-c50 ketch-rounded-normal;
    h3 {
      @apply ketch-font-bold;
    }
    h3,
    h5 {
      @apply ketch-text-header-foreground-color;
    }
    .last-user-course {
      @apply ketch-flex ketch-space-x-c20;
    }
  }
</style>
