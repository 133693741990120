<template lang="pug">
.user-area-wrapper
    .user-icon-area-wrapper(v-if='!createSpaceForSearchInput')
        template(v-if='showUserLevelPoints && !isKnowledgeCheckCompany')
            .user-level-points(data-cy='user-level-points')
                UserLevelPoints
                .blurred-out-non-editable-section.on-header(v-if='inEditorMode')
        .user-icon-area(
            data-cy='user-icon-area',
            v-if='hasValidUser'
        )
            .user-icon.with-initials(
                @click='toggleUserDropdown',
                v-if='initials'
            )
                h6 {{ initials }}
            .user-icon(
                @click='toggleUserDropdown',
                v-else
            )
                SVGRenderer(
                    :fill-color='"var(--primary-color)"',
                    :has-hover='false',
                    :icon='defaultUserIcon',
                    :stroke-color='"var(--primary-foreground-color)"'
                )
        router-link.sign-in.h4(
            to='/login',
            v-else
        ) {{ $t('onboarding.login') }}
    transition(name='menu-slide')
        .user-dropdown(
            v-if='openUserDropdown',
            v-on-click-outside='toggleUserDropdown'
        )
            template(v-if='$auth.isAuthenticated')
                router-link.h4(
                    data-cy='route-account-page',
                    to='/account'
                ) {{ $t('navigation.nav.myAccount') }}
                router-link.h4(
                    data-cy='route-company-selection',
                    to='/company-selection',
                    v-if='hasMultipleCompanies'
                ) {{ $t('navigation.nav.switchAcademy') }}
            .toggle-theme(
                @click.stop='toggleTheme',
                v-if='allowDarkMode'
            )
                h4.toggle-text {{ $t('navigation.nav.toggleTheme') }}
                ToggleSwitch(
                    :in-user-dropdown='true',
                    :value='darkModeState',
                    @input='toggleTheme'
                )
            router-link.h4(
                to='/logout',
                v-else
            ) {{ $t('navigation.nav.logout') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import ToggleSwitch from '@/components/common/ToggleSwitch.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import UserLevelPoints from '@/components/UserLevelPoints.vue'
  import useIcons from '@/composables/useIcons'
  import { UserModule } from '@/store/modules/user'
  import { ThemeModule } from '@/store/modules/theme'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useEditor from '@/composables/useEditor'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { vOnClickOutside } from '@vueuse/components'

  defineProps({
    createSpaceForSearchInput: {
      type: Boolean,
      default: false,
    },
  })

  const { defaultUserIcon } = useIcons()
  const { isLargeDesktop, isDesktopDevice } = useBreakpoint()
  const { inEditorMode } = useEditor()
  const { isSachkundeCompany, isKnowledgeCheckCompany } = useCommonMixin()
  const openUserDropdown = ref(false)
  const timerId = ref(0)

  const theme = computed((): string => {
    return ThemeModule.theme
  })

  const hasValidUser = computed((): boolean => {
    return UserModule.hasValidUser
  })
  const hasMultipleCompanies = computed(() => {
    return UserModule.companies.length > 1
  })

  const initials = computed((): string => {
    return UserModule.initials || ''
  })

  const allowDarkMode = computed(() => {
    return UserModule.allowDarkMode
  })

  const darkModeState = computed((): boolean => {
    return theme.value === 'dark'
  })

  const showUserLevelPoints = computed(() => {
    // TODO we do this for sachkungegurus because we don't have many nav items
    return (isSachkundeCompany.value ? isDesktopDevice.value : isLargeDesktop.value) && hasValidUser.value
  })

  const toggleTheme = () => {
    ThemeModule.toggleTheme()
  }

  const toggleUserDropdown = () => {
    if (timerId.value) window.clearTimeout(timerId.value)
    timerId.value = window.setTimeout(() => {
      openUserDropdown.value = !openUserDropdown.value
    }, 50)
  }
</script>

<style lang="postcss">
  .user-area-wrapper {
    @apply ketch-flex ketch-items-center ketch-h-full;
    .user-icon-area-wrapper {
      @apply ketch-flex ketch-items-center ketch-space-x-c30 md:ketch-ml-c20;
      .user-level-points {
        @apply ketch-relative;
      }
      .user-icon {
        @apply ketch-w-c25 ketch-h-c25 ketch-cursor-pointer;
        @apply md:ketch-w-c40 md:ketch-h-c40;
        &.with-initials {
          @apply ketch-flex ketch-items-center ketch-justify-center ketch-rounded-full;
          @apply ketch-text-primary-foreground-color ketch-bg-primary-color hover:ketch-bg-primary-hover-color;
        }
      }
      .sign-in {
        @apply ketch-ml-c20 ketch-cursor-pointer ketch-text-header-foreground-color;
      }
    }
    .user-dropdown {
      @apply ketch-bg-primary-color ketch-text-primary-foreground-color ketch-top-c60 ketch-absolute ketch-z-20;
      @apply ketch-flex ketch-flex-col ketch-w-[240px] ketch-rounded-none ketch-shadow-d-user-dropdown;
      @apply ketch-right-c16 sm:ketch-right-c20 md:ketch-right-c30 lg:ketch-right-[70px];
      @screen md {
        top: 80px;
      }
      > * {
        @apply ketch-py-[17px] ketch-px-c25 ketch-border-b ketch-border-gray-700 ketch-border-opacity-10;
        @apply ketch-text-primary-foreground-color hover:ketch-bg-primary-hover-color;
      }
      .toggle-theme {
        @apply ketch-flex ketch-items-center ketch-cursor-pointer;
        .toggle-text {
          @apply ketch-pr-c6;
        }
      }
    }
    img {
      filter: none;
    }
  }
</style>
