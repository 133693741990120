import axios from './interceptor'
import {
  Company,
  CompanyProfileQuestion,
  GamificationActivity,
  UserCourseLog,
  MemberGroup,
  NewGroup,
  OnboardingFlowProperties,
  CompanyOnboardingPopupDetails,
  ReferralCandyPurchase,
  User,
  UserCourseActivity,
  UserExamAppointments,
  UserGamificationActivity,
  UserGamificationActivityResource,
  UserGamificationStats,
  UserLevel,
  UserMember,
  UserMembersResponse,
  UserSetupChecklist,
  UserUtmParameters,
  PendingOrder,
} from '@/services/interfaces/Auth0'
import { QuestionAnswer } from '@/services/interfaces/QuizQuestion'
import { CompanyActivityLog, GroupColor, UserRole } from '../interfaces/Auth0'
import { UserModule } from '@/store/modules/user'
import { OnboardingDetails } from '@/services/interfaces/Common'
import { GlossaryItem } from '@/services/interfaces/Course'

export default class UsersApi {
  static async updateOnboardingUser(
    user: { auth0Id: string; firstName: string; lastName: string },
    profileQuestionAnswers: QuestionAnswer[],
    onboarded?: boolean,
    companyId?: string,
  ): Promise<User> {
    const data: any = { firstName: user.firstName, lastName: user.lastName, profileQuestionAnswers, onboarded }
    if (companyId) {
      data.companyIds = [companyId]
    }
    return axios
      .put(`/users/${user.auth0Id}`, data)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async updateShowOnboardingPopup(auth0Id: string, showOnboardingPopup: boolean): Promise<number> {
    return axios
      .put(`users/${auth0Id}/onboarding_popup`, {
        showOnboardingPopup,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async updateUserUtmParameters(
    auth0Id: string,
    utmParameters: { [key in UserUtmParameters]?: string },
  ): Promise<number> {
    return axios
      .put(`users/${auth0Id}/update_utm_params`, {
        utmParameters,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getUser(auth0Id: string): Promise<User> {
    return axios
      .get(`/users/${auth0Id}`)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async getCompanyProfileQuestions(): Promise<CompanyProfileQuestion[]> {
    return axios
      .get('/company_profile_questions')
      .then((resp) => (resp.data as any).questions)
      .catch((err) => Promise.reject(err))
  }

  static async getUserProfileQuestions(): Promise<CompanyProfileQuestion[]> {
    return axios
      .get('/user_profile')
      .then((resp) => (resp.data as any).questions)
      .catch((err) => Promise.reject(err))
  }

  static async updateUserProfileChoices(questionAnswers: QuestionAnswer[]): Promise<CompanyProfileQuestion[]> {
    return axios
      .post('/user_profile', { questionAnswers: questionAnswers })
      .then((resp) => (resp.data as any).questions)
      .catch((err) => Promise.reject(err))
  }

  static async updatePersonalData(user: { auth0Id: string; firstName: string; lastName: string }): Promise<User> {
    return axios
      .post('/users', user)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async archiveUser(): Promise<any> {
    return axios
      .delete('/archive_user')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getUserSetupChecklist(): Promise<UserSetupChecklist> {
    return axios
      .get('/setup_checklist')
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async inviteUser(payload: {
    companyId: string
    emails: string[]
    reInviteUsers?: boolean
  }): Promise<{ users: UserMember[] }> {
    const body: any = { returnUrl: `https://${UserModule.currentCompany!.subDomain}.ketchup.academy/login` }
    body.emails = payload.emails
    if (payload.reInviteUsers) {
      body.reInviteUsers = true
    }
    return axios
      .post(`/companies/${payload.companyId}/users`, body)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getGroupColors(): Promise<GroupColor[]> {
    return axios
      .get('/group_colors')
      .then((resp) => (resp.data as any).groupColors || [])
      .catch((err) => Promise.reject(err))
  }

  static async createMemberGroup(companyId: string, group: NewGroup): Promise<number> {
    return axios
      .post(`/companies/${companyId}/groups`, group)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async updateMemberGroup(
    companyId: string,
    group: {
      id: string
      groupName: string
      description?: string
      colorId?: string
    },
  ): Promise<number> {
    return axios
      .put(`/companies/${companyId}/groups/${group.id}`, group)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getMembersGroups(companyId: string): Promise<MemberGroup[]> {
    return axios
      .get(`/companies/${companyId}/groups`)
      .then((resp) => (resp.data as any).groups || [])
      .catch((err) => Promise.reject(err))
  }

  static async getMembers(payload: {
    companyId: string
    perPage?: number
    page?: number
    roles?: string[]
    groups?: string[]
    term?: string
    loadAll?: boolean
    sort?: string
  }): Promise<UserMembersResponse> {
    const config: any = { params: {} }
    if (payload.perPage) {
      config.params.per_page = payload.perPage
    }
    if (payload.page) {
      config.params.page = payload.page
    }
    if (payload.roles) {
      config.params.roles = payload.roles.join(',')
    }
    if (payload.groups) {
      config.params.groups = payload.groups.join(',')
    }
    if (payload.term) {
      config.params.term = payload.term
    }
    if (payload.loadAll) {
      config.params.load_all = payload.loadAll
    }
    if (payload.sort) {
      config.params.sort = payload.sort
    } else {
      // set default sort criteria to activity
      config.params.sort = 'activity'
    }
    return axios
      .get(`/companies/${payload.companyId}/users`, config)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async deleteCompanyUsers(companyId: string, data: { emails: string[] }): Promise<number> {
    return axios
      .delete(`/companies/${companyId}/users`, { data })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getCompanyActivityLog(companyId: string): Promise<CompanyActivityLog[]> {
    return axios
      .get(`/companies/${companyId}/activity_log`)
      .then((resp) => (resp.data as any).activityLogs)
      .catch((err) => Promise.reject(err))
  }

  static async assignUsersToGroup(
    companyId: string,
    emails: string[],
    groupIds: string[],
  ): Promise<{ users: UserMember[] }> {
    return axios
      .post(`/companies/${companyId}/users/assign_to_group`, {
        emails,
        groupIds,
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async unAssignUsersToGroup(
    companyId: string,
    data: { emails: string[]; groupIds: string[] },
  ): Promise<{ users: UserMember[] }> {
    return axios
      .delete(`/companies/${companyId}/users/unassign_from_groups`, {
        data,
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async assignCoursesToUser(companyId: string, emails: string[], courseIds: string[]): Promise<number> {
    return axios
      .post(`/companies/${companyId}/users/assign_course`, {
        emails,
        courseIds,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async exportCompanyUsersAsCSV(companyId: string): Promise<number> {
    return axios
      .get(`/companies/${companyId}/export_users_as_csv`)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getCompanies(subDomain?: string): Promise<Company[]> {
    const config: any = { params: {} }
    if (subDomain) {
      config.params.sub_domain = subDomain
    }
    return axios
      .get('/companies', config)
      .then((resp) => (resp.data as any).companies)
      .catch((err) => Promise.reject(err))
  }

  static async getOnboardingFlowProperties(companyId: string): Promise<OnboardingFlowProperties> {
    return axios
      .get(`/companies/${companyId}/onboarding`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getUserGamificationStats(companyId: string): Promise<UserGamificationStats> {
    return axios
      .get('/users/gamification', { params: { companyId } })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async postUserGamificationPoints(
    activity: UserGamificationActivity,
    resource: UserGamificationActivityResource | null,
    currentLevelId: string,
    companyId: string,
  ): Promise<number> {
    return axios
      .post('/users/gamification', {
        activity,
        resource,
        currentLevelId,
        companyId,
      })
      .then((resp) => (resp.data as any).pointsEarned)
      .catch((err) => Promise.reject(err))
  }

  static async getGamificationLevelsOfACompany(companyId: string): Promise<UserLevel[]> {
    return axios
      .get(`/companies/${companyId}/gamification_levels`)
      .then((resp) => (resp.data as any).levels)
      .catch((err) => Promise.reject(err))
  }

  static async updateGamificationLevelsOfACompany(companyId: string, levels: UserLevel[]): Promise<number> {
    return axios
      .put(`/companies/${companyId}/gamification_levels`, {
        levels,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getGamificationActivitiesOfACompany(companyId: string): Promise<GamificationActivity[]> {
    return axios
      .get(`/companies/${companyId}/gamification_activities`)
      .then((resp) => (resp.data as any).activities)
      .catch((err) => Promise.reject(err))
  }

  static async updateGamificationActivitiesOfACompany(
    companyId: string,
    activities: GamificationActivity[],
  ): Promise<number> {
    return axios
      .put(`/companies/${companyId}/gamification_activities`, {
        activities,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getAllGamificationActivities(): Promise<GamificationActivity[]> {
    return axios
      .get('/gamification_activities')
      .then((resp) => (resp.data as any).activities)
      .catch((err) => Promise.reject(err))
  }

  static async getCompanyUserCourseActivity(companyId: string, auth0Id: string): Promise<UserCourseActivity[]> {
    return axios
      .get(`/companies/${companyId}/users/${auth0Id}/course_activity`)
      .then((resp) => (resp.data as any).courseActivities)
      .catch((err) => Promise.reject(err))
  }

  static async getUserRoles(): Promise<{ id: string; name: string }[]> {
    return axios
      .get(`/roles`)
      .then((resp) => resp.data.roles)
      .catch((err) => Promise.reject(err))
  }

  static async updateUserRole(companyId: string, auth0Id: string, roles: UserRole[]): Promise<UserMember> {
    return axios
      .put(`/companies/${companyId}/users/${auth0Id}/role`, {
        roles,
      })
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getCompanyOnboardingPopupDetails(companyId: string): Promise<CompanyOnboardingPopupDetails> {
    return axios
      .get(`/companies/${companyId}/onboarding_popup`)
      .then((resp) => resp.data.onboardingPopupDetails)
      .catch((err) => Promise.reject(err))
  }

  static async getExamAppointments(): Promise<UserExamAppointments[]> {
    return axios
      .get(`/exam_appointments`)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async setExamAppointments(exam_appointments: { courseId: string; date: string }[]): Promise<number> {
    return axios
      .post(`/exam_appointments`, {
        exam_appointments,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async cancelExamAppointment(courseId: string): Promise<number> {
    return axios
      .delete(`/cancel_exam_appointment`, {
        data: {
          courseId,
        },
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getOnboardingDetails(): Promise<OnboardingDetails> {
    return axios
      .get(`/onboarding_details`)
      .then((resp) => resp.data as any)
      .catch((err) => Promise.reject(err))
  }

  static async getGlossaryItems(companyId: string): Promise<GlossaryItem[]> {
    return axios
      .get(`/companies/${companyId}/glossaries`)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async generateReferralCode(email: string): Promise<{ inviteCode: string }> {
    return axios
      .get('/generate_referral_code', {
        params: {
          email,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async createReferralCodeInStripe(referralCode: string): Promise<number> {
    return axios
      .post('/create_stripe_coupon', {
        referralCode,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async sendReferralPurchaseToReferralCandy(payload: ReferralCandyPurchase): Promise<number> {
    return axios
      .post('/referral_purchase', {
        firstName: UserModule.firstName,
        email: UserModule.email,
        discountCode: payload.discountCode,
        orderTimestamp: `${Date.now()}`,
        browserIp: '',
        userAgent: '',
        invoiceAmount: payload.invoiceAmount,
        currencyCode: 'EUR',
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async sendEmailVerification(auth0Id: string): Promise<number> {
    return axios
      .post(`/users/${auth0Id}/email_verification`)
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }

  static async getLastUserCourseLog(auth0Id: string): Promise<{ lastUserCourseLog: UserCourseLog | null }> {
    return axios
      .get(`/users/${auth0Id}/last_user_course_log`)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async getPendingOrder(pendingOrderId: string): Promise<PendingOrder> {
    return axios
      .get(`/fetch_pending_order/${pendingOrderId}`)
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err))
  }

  static async completePendingOrder(
    pendingOrderId: string,
    primaryEmail: string,
    confirmationToken: string,
  ): Promise<number> {
    return axios
      .post(`/complete_pending_order/${pendingOrderId}`, {
        primaryEmail,
        confirmationToken,
      })
      .then((resp) => resp.status)
      .catch((err) => Promise.reject(err))
  }
}
