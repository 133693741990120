<template lang="pug">
.lesson-quiz-questions
    .comment {{ $t('editor.questions.comment') }}
    transition-group.questions-container(
        :name='transitionName',
        tag='div'
    )
        CollapsibleWidget.question(
            :can-drag='canDrag && !question.editorId',
            :draggable='canDrag && !question.editorId',
            :header-title='questionHeaderTitle(question)',
            :initial-open-state='!question.editorId',
            :key='question.editorId || question.id || questionIndex',
            @delete='removeQuestion(question, questionIndex)',
            @dragend.native='onQuestionDragEnd',
            @dragover.native.prevent,
            @dragstart.native='onQuestionDragStart(questionIndex)',
            @drop.native.prevent,
            v-for='(question, questionIndex) in lessonQuestions'
        )
            TextRenderer.title(
                :editor-state='`lesson-question-title-${question.editorId || question.id}`',
                :mutate-and-queue-func='mutateAndQueueFunc(question.editorId || question.id)',
                :placeholder='$t("editor.questions.yourQuestion")',
                :source='originalQuestion(question.id) ? originalQuestion(question.id).title : ""',
                editor-property='title'
            )
            VideoOrImageRenderer(
                :image-url='question.imageUrl',
                @image-data='onImageUpload(questionIndex, $event)',
                @remove-image-url='removeQuestionImage(questionIndex)',
                file-type='question_image',
                image-only
            )
            .question-type
                .top-header
                    h5 Type
                    span ?
                Dropdown.editor-dropdown(
                    :items='dropdownItems',
                    :value='question.questionType',
                    @input='onQuestionTypeChange(question, $event)',
                    default-dropdown-behavior,
                    stroke-color-for-expand-icon='var(--editor-primary-color)'
                )
            DurationInput.editor(
                :edit-title='"Question points"',
                :editor-state='`lesson-question-points-${question.editorId || question.id}`',
                :mutate-and-queue-func='mutateAndQueueFunc(question.editorId || question.id)',
                :show-calibration='false',
                :value='question.points || 1',
                editor-property='points'
            )
            transition-group.answers(
                :name='transitionName',
                tag='div',
                v-if='question.answers.length'
            )
                .answer(
                    :key='answer.editorAnswerId || answer.id || answerIndex',
                    v-for='(answer, answerIndex) in questionAnswers(question.answers)'
                )
                    .top-header(v-if='true')
                        h6(
                            @click='discardQuestionAnswer(questionIndex, answer.id || answer.editorAnswerId)',
                            v-if='hasChanges("answer", questionIndex, answer.id || answer.editorAnswerId)'
                        ) {{ $t('editor.discardChanges') }}
                        h6(@click='removeAnswer(questionIndex, answer)') {{ $t('editor.removeAnswer') }}
                    .container
                        .is-correct(@click='onCorrectAnswer(questionIndex, answer.id || answer.editorAnswerId)')
                            h5 {{ $t('editor.questions.isCorrect') }}
                            SVGRenderer(
                                :has-hover='false',
                                :icon='answer.correct ? checkedCheckboxSquare : checkboxSquare',
                                stroke-color='var(--editor-primary-color)',
                                width='20'
                            )
                        textarea(
                            :placeholder='$t("editor.questions.yourAnswer")',
                            v-model='answer.details'
                        )

            KetchUpButton.tertiary(
                :class='{ "has-answers": question.answers.length }',
                @click.native='addNewAnswer(questionIndex)',
                v-if='question.questionType'
            )
                h5 {{ $t('editor.questions.addNewAnswer') }}
            .comment(v-else) {{ $t('editor.questions.selectQuestionTypeToAddAnswer') }}
            TextRenderer.comment-container(
                :edit-title='$t("comment")',
                :editor-state='`lesson-comment-${question.editorId || question.id}`',
                :mutate-and-queue-func='mutateAndQueueFunc(question.editorId || question.id)',
                :placeholder='$t("editor.questions.yourComment")',
                :source='originalQuestion(question.id) ? originalQuestion(question.id).comment || "" : ""',
                editor-property='comment'
            )
    KetchUpButton.editor(@click.native='addNewQuestion')
        h5 {{ $t('editor.questions.addNewQuestion') }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import { v4 as uuidv4 } from 'uuid'
  import Dropdown from '@/components/common/Dropdown.vue'
  import useEditor from '@/composables/useEditor'
  import useCourse from '@/composables/useCourse'
  import { EditorModule } from '@/store/modules/editor'
  import { CourseModule } from '@/store/modules/course'
  import CourseApi from '@/services/api/CourseApi'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'
  import CollapsibleWidget from '@/components/common/CollapsibleWidget.vue'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type {
    CourseModule as CourseModuleState,
    LessonQuestion,
    EditorStatePayload,
    LessonMap,
    LessonQuestionAnswer,
    EditorQuestion,
    EditorAnswer,
  } from '@/services/interfaces/Course'
  import type { Nullable } from '@/services/interfaces/Content'
  import DurationInput from '@/components/common/DurationInput.vue'

  const props = defineProps({
    originalQuestions: {
      type: Array as PropType<EditorQuestion[]>,
      required: true,
    },
    lessonQuestions: {
      type: Array as PropType<EditorQuestion[]>,
      required: true,
    },
    addLessonQuestion: {
      type: Function as PropType<(question: EditorQuestion) => void>,
      required: true,
    },
    removeLessonQuestion: {
      type: Function as PropType<(index: number) => EditorQuestion[]>,
      required: true,
    },
    canDrag: Boolean,
  })

  const { translateString } = useI18n()
  const questionStates = reactive({} as { [key: string]: boolean })
  const delayOpen = reactive({} as { [key: string]: boolean })
  const { checkedCheckboxSquare, checkboxSquare } = useIcons()
  const { hasLocalChanges } = useEditor()
  const { currentCourseId } = useCourse()
  const transitionName = ref('')

  const questionAnswers = computed(() => {
    return (answers: LessonQuestionAnswer[]) => {
      return answers.filter((a) => a._destroy !== true)
    }
  })

  const originalQuestion = computed(
    () => (questionId: string) => props.originalQuestions.find((q) => q.id === questionId),
  )

  const questionHeaderTitle = computed(() => {
    return (question: EditorQuestion | LessonQuestion) => {
      return (
        question.title ||
        originalQuestion.value(question.id || '')?.title ||
        translateString('editor.questions.newQuestion')
      )
    }
  })

  const addNewQuestion = () => {
    transitionName.value = 'fadeInUp'
    const editorId = 'editor-question-' + uuidv4()
    props.addLessonQuestion({
      editorId,
      title: '',
      questionType: '',
      answers: [],
      comment: '',
      imageUrl: null,
      imageData: '',
      points: 1,
    })
    eventBus.$set(delayOpen, editorId, false)
    eventBus.$set(questionStates, editorId, false)
  }
  const addNewAnswer = (index: number) => {
    transitionName.value = 'fadeInUp'
    const question = props.lessonQuestions[index]
    const editorAnswerId = 'editor-answer-' + uuidv4()
    question.answers.push({
      editorAnswerId,
      correct: false,
      details: '',
    })
  }

  const dropdownItems = [
    { selectable: true, type: 'multiple', title: 'Multiple Choice' },
    { selectable: true, type: 'single', title: 'Single Choice' },
    { selectable: true, type: 'number', title: 'Number' },
  ]

  const removeQuestion = (question: EditorQuestion, index: number) => {
    transitionName.value = 'next-slide'
    if (question.id) {
      eventBus.$set(question, '_destroy', true)
    } else {
      props.removeLessonQuestion(index)
    }
  }
  const getAnswer = (questionIndex: number, answerId: string) => {
    const question = props.lessonQuestions[questionIndex]
    return question.answers.find((a) => [answerId].includes(a.id || a.editorAnswerId!)) as EditorAnswer
  }

  const onQuestionTypeChange = (question: LessonQuestion, newType: 'number' | 'single' | 'multiple') => {
    question.questionType = newType
    if (question.answers.length > 1) {
      if (newType === 'single') {
        let foundFirstSelectedAnswer = false
        question.answers = question.answers.map((a) => {
          if (foundFirstSelectedAnswer) a.correct = false
          if (a.correct) foundFirstSelectedAnswer = true
          return a
        })
      } else if (newType === 'number') {
        const index = question.answers.findIndex((a) => a.correct)
        question.answers = question.answers.splice(index === -1 ? 0 : index, 1)
      }
    }
  }

  const onCorrectAnswer = (questionIndex: number, answerId: string) => {
    const question = props.lessonQuestions[questionIndex]
    const answer = question.answers.find((a) => [answerId].includes(a.id || a.editorAnswerId!)) as EditorAnswer
    const correctAnswers = question.answers.filter((a) => a.correct)

    if (
      ['number', 'multiple'].includes(question.questionType) ||
      (question.questionType === 'single' &&
        (correctAnswers.length === 0 || (correctAnswers.length === 1 && correctAnswers[0] === answer)))
    ) {
      answer.correct = !answer.correct
    }
  }
  const removeAnswer = (questionIndex: number, answer: EditorAnswer) => {
    transitionName.value = 'next-slide'
    const question = props.lessonQuestions[questionIndex]
    const answerIndex = question.answers.findIndex((a) => {
      return [answer.id || answer.editorAnswerId].includes(a.id || a.editorAnswerId)
    })
    if (answerIndex > -1 && !answer.id) question.answers.splice(answerIndex, 1)
    if (answer.id) eventBus.$set(answer, '_destroy', true)
  }

  const removeQuestionImage = (index: number) => {
    const question = props.lessonQuestions[index]
    question.imageUrl = null
  }
  const onImageUpload = (index: number, imageData: string) => {
    const question = props.lessonQuestions[index]
    if (question.imageData === undefined) {
      eventBus.$set(question, 'imageData', imageData)
    } else {
      question.imageData = imageData
    }
  }

  const hasChanges = computed(
    () => (type: 'comment' | 'title' | 'answer', questionIndex: number, answerId?: string) => {
      if (type === 'comment') {
        return hasLocalChanges.value(
          props.lessonQuestions[questionIndex].comment,
          props.originalQuestions[questionIndex]?.comment,
        )
      }
      if (type === 'title') {
        return hasLocalChanges.value(
          props.lessonQuestions[questionIndex].title,
          props.originalQuestions[questionIndex]?.title,
        )
      }
      const editorAnswer = getAnswer(questionIndex, answerId!)
      if (!(editorAnswer.details || editorAnswer.correct)) return false
      const lessonAnswer = props.originalQuestions[questionIndex]?.answers.find((a) => a.id === answerId)
      return hasLocalChanges.value(editorAnswer, lessonAnswer)
    },
  )
  const discardQuestionAnswer = (questionIndex: number, answerId: string) => {
    const answer = getAnswer(questionIndex, answerId)
    answer.details = ''
    answer.correct = false
  }

  const dragQuestionIndex = ref(-1)
  const dropModuleTarget = ref(null as Nullable<CourseModuleState>)
  const dropLessonId = ref('')
  const onQuestionDragStart = (questionIndex: number) => {
    dragQuestionIndex.value = questionIndex
    eventBus.$emit('question-drag-start')
  }
  const onQuestionDragEnd = () => {
    dragQuestionIndex.value = -1
    dropLessonId.value = ''
    dropModuleTarget.value = null
    eventBus.$emit('question-drag-end')
  }
  const onLessonDrop = () => {
    if (dragQuestionIndex.value === -1 || !dropLessonId.value || !dropModuleTarget.value) return

    // ignore drop on same lesson
    if (dropLessonId.value === EditorModule.currentLesson?.id) return
    const removedQuestion = props.removeLessonQuestion(dragQuestionIndex.value)
    if (removedQuestion.length) {
      const question = removedQuestion[0]
      const questionIndex = CourseModule.currentLessonQuestions!.findIndex((q) => q.id === question.id)
      if (questionIndex > -1) CourseModule.currentLessonQuestions!.splice(questionIndex, 1)

      CourseApi.updateQuestion(
        Object.assign({}, question, { lessonId: dropLessonId.value, answers: undefined, editorId: undefined }),
      ).then(async () => {
        eventBus.$emit('turn-on-loader')
        await CourseModule.getCourseModules(currentCourseId.value)
        EditorModule.setCourseModules({
          courseId: currentCourseId.value,
          modules: structuredClone(CourseModule.courseModules(currentCourseId.value)),
        })
        EditorModule.setCurrentLessonQuestions(CourseModule.currentLessonQuestions)
        eventBus.$emit('turn-off-loader')
        eventBus.$toasted.success(translateString('editor.messages.questionMovedToLessonSuccessfully'))
      })
    }
  }

  const bindQuestionDragDropEvents = () => {
    eventBus.$on('lesson-drop', onLessonDrop)
    eventBus.$on('question-drag', (module: CourseModuleState, lessonId: string) => {
      dropModuleTarget.value = module
      dropLessonId.value = lessonId
    })
  }

  const questionRedoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const question = props.lessonQuestions?.find((q) =>
      [(data.value as LessonMap).questionId].includes(q.editorId! || q.id!),
    )
    if (question) (question as any)[data.property!] = data.apiPayload as string
  }
  const questionUndoCallback = (data: EditorStatePayload) => {
    if (!data) return
    const question = props.lessonQuestions?.find((q) =>
      [(data.value as LessonMap).questionId].includes(q.editorId! || q.id!),
    )
    if (question) (question as any)[data.property!] = (data.value as LessonMap).prevPropValue
  }

  const mutateAndQueueFunc = (questionId: string) => {
    return async (data: EditorStatePayload) => {
      const changesPayload = EditorModule.editorChanges[data.path]?.find((c) => c.data.key === data.key)
      const question: any = props.lessonQuestions?.find((q) => [questionId].includes(q.editorId! || q.id!))

      const payload = Object.assign({}, data, {
        apiPayload: data.value,
        value: {
          questionId,
          prevPropValue: (changesPayload?.data.value as LessonMap)?.prevPropValue ?? question[data.property!],
        },
      })
      EditorModule.addEditorChange(payload)
      if (question) question![data.property!] = payload.apiPayload as string
      EditorModule.subscribe({
        type: 'save',
        key: data.key,
        callback: questionRedoCallback,
      })
      EditorModule.subscribe({
        type: 'discard',
        key: 'discard-' + data.key,
        callback: questionUndoCallback,
      })
    }
  }

  onMounted(() => {
    bindQuestionDragDropEvents()
  })

  onBeforeUnmount(() => {
    EditorModule.unsubscribe({ type: 'discard', callback: questionUndoCallback })
    EditorModule.unsubscribe({ type: 'save', callback: questionRedoCallback })
    props.originalQuestions.forEach((q) => {
      eventBus.$set(questionStates, q.id as string, true)
      eventBus.$set(delayOpen, q.id as string, true)
    })
  })
</script>

<style lang="postcss">
  .lesson-quiz-questions {
    @apply ketch-space-y-c30;
    .comment {
      @apply ketch-p-c20 ketch-border ketch-border-editor-primary-color ketch-w-full ketch-mt-c30;
      @apply ketch-rounded-normal ketch-bg-editor-primary-color ketch-bg-opacity-[0.05];
    }
    .questions-container {
      .question .content-elements {
        @apply ketch-space-y-c20;
        .question-type {
          .top-header {
            @apply ketch-flex ketch-space-x-c5 ketch-items-center ketch-mb-c4;
            h5 {
              @apply ketch-font-bold ketch-text-editor-primary-color;
            }
            span {
              @apply ketch-w-c12 ketch-h-c12 ketch-rounded-full ketch-bg-[#E8E8E8] ketch-border-[#A2A2A2];
              @apply ketch-border-[0.5px] ketch-flex ketch-items-center ketch-justify-center ketch-font-bold;
              @apply ketch-text-[8px] ketch-leading-[8px];
            }
          }
        }
        .answers {
          @apply ketch-space-y-c10;
          .answer {
            @apply ketch-pb-c20 ketch-border-b ketch-border-dashed ketch-border-[#ccc];
            .top-header {
              @apply ketch-flex ketch-justify-end ketch-space-x-c20;
              h6 {
                @apply ketch-text-editor-primary-color hover:ketch-underline ketch-cursor-pointer;
              }
            }
            .container {
              @apply ketch-flex ketch-items-start ketch-space-x-c40;
              .is-correct {
                @apply ketch-flex ketch-items-center ketch-text-editor-primary-color ketch-space-x-c15;
                @apply ketch-cursor-pointer ketch-whitespace-nowrap;
                svg {
                  @apply ketch-w-c20 ketch-h-c20;
                }
              }
              textarea {
                @apply ketch-rounded-none ketch-w-full ketch-h-c80 ketch-border ketch-border-border-color;
                @apply ketch-text-primary-text-color ketch-outline-none ketch-p-c10 ketch-resize-none;
                &::placeholder {
                  @apply ketch-text-primary-text-color ketch-text-opacity-60;
                }
              }
            }
          }
        }
        > button.tertiary {
          @apply ketch-w-auto;
          &.has-answers {
            @apply ketch-ml-[140px];
          }
        }
      }
    }
  }
</style>
