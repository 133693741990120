<template lang="pug">
.flags-panel
    h2 Flags Controller
    .flags-wrapper
        .flag(
            :key='flag',
            v-for='flag of Object.keys(flags)'
        )
            h4.flag-text {{ flag }}
            ToggleSwitch(
                :value='flagsValues[flag]',
                @input='enableDisableFlag(flag, $event)'
            )
</template>

<script setup lang="ts">
  import useFlags from '@/composables/useFlags'
  import ToggleSwitch from '@/components/common/ToggleSwitch.vue'
  import eventBus from '@/main'
  import { onMounted } from 'vue'
  import { UserModule } from '@/store/modules/user'

  const { flags } = useFlags()
  const flagsValues: { [key: string]: boolean } = flags

  for (const flagName of Object.keys(flags)) {
    const storageFlagName = 'flag' + flagName
    const storageFlagValue = window.localStorage.getItem(storageFlagName)
    if (storageFlagValue) {
      flagsValues[flagName] = storageFlagValue === 'true'
    }
  }

  const enableDisableFlag = (flag: string, value: boolean): void => {
    if (flagsValues[flag] !== value) {
      flagsValues[flag] = value
      const storageFlagName = 'flag' + flag
      if (value) {
        window.localStorage.setItem(storageFlagName, 'true')
      } else {
        window.localStorage.setItem(storageFlagName, 'false')
      }
      refreshApp()
    }
  }

  const refreshApp = (): void => {
    setTimeout(() => {
      eventBus.$emit('refresh')
    }, 1000)
  }

  onMounted(() => {
    UserModule.setLoadingComponentViewData(false)
  })
</script>

<style lang="postcss">
  .flags-panel {
    .flags-wrapper {
      @apply ketch-mt-c40 ketch-space-y-c30 ketch-rounded-large ketch-border;
      @apply ketch-border-border-color ketch-w-c400 ketch-px-c30 ketch-py-c20;
      .flag {
        @apply ketch-flex ketch-justify-between;
        .flag-text {
          @apply ketch-text-secondary-text-color;
        }
        .switch {
          @apply ketch-h-[26px] ketch-w-c50;
          .round {
            @apply ketch-rounded-[50%/26px];
            &:before {
              @apply ketch-w-[22px] ketch-h-[22px];
            }
          }
          input:checked + .slider:before {
            transform: translateX(24px);
          }
        }
      }
    }
  }
</style>
