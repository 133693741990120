<template lang="pug">
.switch-wrap
    label.switch
        input(
            type='checkbox',
            v-model='active'
        )
        span.slider.round(:class='{ inUserDropdown: inUserDropdown }')
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    value: {
      type: Boolean,
      required: true,
    },
    inUserDropdown: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['input'])

  const active = computed({
    get(): boolean {
      return props.value
    },
    set(value: boolean) {
      emit('input', value)
    },
  })
</script>

<style lang="postcss">
  .switch-wrap {
    @apply ketch-flex ketch-items-center;
    .switch {
      @apply ketch-relative ketch-inline-block ketch-w-c30 ketch-h-c16 ketch-align-middle ketch-overflow-hidden;
      input {
        @apply ketch-opacity-0 ketch-w-0 ketch-h-0;
      }
      .slider {
        @apply ketch-absolute ketch-bg-gray-470 ketch-cursor-pointer ketch-top-0 ketch-left-0 ketch-right-0;
        @apply ketch-bottom-0 ketch-transition-all ketch-duration-500 ketch-rounded-[50%/16px];

        &.round:before {
          @apply ketch-rounded-full;
        }
      }
      .slider:before {
        @apply ketch-absolute ketch-h-c12 ketch-w-c12 ketch-top-c2 ketch-left-c2 ketch-bg-white;
        @apply ketch-transition-all ketch-duration-500;
        content: '';
      }
      input:checked + .slider {
        @apply ketch-bg-primary-color;
      }
      input:focus + .slider {
        @apply ketch-shadow-slider;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(14px);
        -ms-transform: translateX(14px);
        transform: translateX(14px);
      }
    }
    &.editor {
      input:checked + .slider {
        @apply ketch-bg-editor-primary-color;
      }
    }
  }
  .ketch-dark .switch-wrap .switch {
    .slider:before {
      @apply ketch-bg-gray-700;
    }
    input:checked + .slider {
      &.inUserDropdown {
        @apply ketch-bg-white;
      }
      &:not(.inUserDropdown) {
        @apply ketch-bg-primary-color;
      }
    }
  }
</style>
