<template lang="pug">
.google-review-widget-wrapper(
    :class='{ "in-header": header }',
    ref='reviewWidgetEl'
)
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'

  const props = defineProps({
    header: {
      type: Boolean,
      default: false,
    },
    courseId: String,
  })

  const { isSachkundeCompany } = useCommonMixin()
  const reviewWidgetEl = ref(null as HTMLDivElement | null)

  const coursesToShowWidgets = [
    '63d7d5bb-e8e9-429d-b360-cefdecd80448',
    '489dd660-9a78-4f94-8076-1e8f20a451e2',
    '8de44221-a055-4cdb-8ce5-d7ac148cb49e',
    'ef7607dd-bb67-42f7-b0f8-a0f1a437fdd9',
    '84be496a-b904-4184-b456-98736fea9259',
    '284424aa-1a0e-4c60-a5e0-d8df6d9d1868',
  ]

  const reviewWidgetClass = computed(() =>
    props.header
      ? 'elfsight-app-e5bca48e-bb0f-413b-ba20-8d712c429c6f'
      : 'elfsight-app-e8264e16-1c95-4a54-8ff7-4326da9f1b0b',
  )

  onMounted(() => {
    if (
      reviewWidgetEl.value &&
      isSachkundeCompany.value &&
      (props.header || coursesToShowWidgets.includes(props.courseId!)) &&
      (process.env.APP_ENV === 'production' || process.env.APP_ENV === 'development')
    ) {
      const reviewWidget = document.createElement('div')
      reviewWidget.classList.add(`${reviewWidgetClass.value}`)
      reviewWidgetEl.value.appendChild(reviewWidget)
    }
  })
</script>

<style lang="postcss">
  .google-review-widget-wrapper {
    &.in-header {
      @apply ketch-bg-white ketch-rounded-normal ketch-w-[155px] md:ketch-w-[175px];
    }
    .eapps-widget-toolbar {
      display: none;
    }
  }
</style>
