<script setup lang="ts">
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import type { PropType } from 'vue'
  import type { CourseItem } from '@/services/interfaces/Course'
  import useCourse from '@/composables/useCourse'
  import { UserModule } from '@/store/modules/user'
  import useSegment from '@/composables/useSegment'
  import { useRoute, useRouter } from 'vue-router/composables'
  import { computed } from 'vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useShopifyClient from '@/composables/useShopifyClient'
  import useCommonMixin from '@/composables/useCommonMixin'
  import eventBus from '@/main'

  const {
    currentCourseId,
    firstCourseModule,
    canBuyCourses,
    routeToFirstCourseModule,
    showStartCourseForFreeModal,
    findCourseWithFreeModule,
  } = useCourse()
  const { createShopifyCheckout } = useShopifyClient()
  const { tryCourseForFreeButtonClicked, coursePurchaseIntended } = useSegment()
  const { arrowRight } = useIcons()
  const { isDesktopDevice, isMobileDevice } = useBreakpoint()
  const { currencyFormatDE } = useCommonMixin()
  const route = useRoute()
  const router = useRouter()

  const props = defineProps({
    course: {
      type: Object as PropType<CourseItem>,
      required: true,
    },
    productAmount: {
      type: Number,
      required: true,
    },
  })

  const canAccessForFree = computed(() => !!(props.course.free || props.course.trial))

  const canUseShopifyCheckout = computed(() => !!props.course.shopifyProductId)

  const trackCoursePurchaseIntended = () => {
    coursePurchaseIntended(currentCourseId.value, props.course.title, 'course_details_page')
  }

  const tryCourseForFree = () => {
    tryCourseForFreeButtonClicked(currentCourseId.value, props.course.title)
    if ((props.course.trial && !props.course.purchased) || props.course.free) {
      const courseWithFreeModule = findCourseWithFreeModule()
      if (courseWithFreeModule.moduleId) {
        eventBus.$router
          .push({
            name: 'CourseModule',
            params: { courseId: courseWithFreeModule.courseId, moduleId: courseWithFreeModule.moduleId },
          })
          .catch(() => {
            return
          })
      } else {
        routeToFirstCourseModule(currentCourseId.value)
      }
    } else {
      // the fact that you are seeing the page, and you are not logged in implies company is public
      if (!UserModule.isLoggedInUser) {
        return router.push(`/login?redirect=${route.fullPath}`).catch(() => {
          return
        })
      }
      showStartCourseForFreeModal(currentCourseId.value)
    }
  }

  const shopifyCheckout = () => {
    trackCoursePurchaseIntended()
    createShopifyCheckout()
  }
</script>

<template lang="pug">
.course-info-cta
    h5.ketch-text-center(v-if='!firstCourseModule(course.id)') {{ $t('courses.noModulesInCourse') }}
    template(v-else)
        .link.try-course(
            :class='{ "ketch-pointer-events-none ketch-opacity-50": !firstCourseModule(course.id) }',
            @click='tryCourseForFree',
            data-cy='try-course-button',
            v-if='!course.purchased'
        )
            h5.ketch-font-bold {{ canAccessForFree ? $t('courses.continueForFree') : $t('courses.tryItForFree') }}
            SVGRenderer(
                :has-hover='false',
                :icon='arrowRight',
                stroke-color='var(--primary-text-color)'
            )
        KetchUpButton.primary.ketch-flex.ketch-space-x-c10.ketch-w-full(
            @click.native='shopifyCheckout',
            data-cy='buy-course-button',
            v-if='canUseShopifyCheckout && canBuyCourses'
        )
            h5.ketch-font-bold(v-if='isMobileDevice && productAmount')
                span {{ $t('courses.mobilePurchasePrice', { amount: currencyFormatDE(productAmount) }) }}
            h5.ketch-font-bold(v-else) {{ $t('courses.buyCourse') }}
            SVGRenderer(
                :has-hover='false',
                :icon='arrowRight',
                stroke-color='var(--primary-foreground-color)',
                v-if='isDesktopDevice'
            )
</template>

<style scoped lang="postcss">
  .course-info-cta {
    @apply ketch-flex ketch-flex-row-reverse ketch-justify-between ketch-items-center md:ketch-space-y-c10 md:ketch-block;
    .link {
      @apply ketch-flex ketch-items-center ketch-justify-center ketch-h-c40 ketch-w-full;
      @apply ketch-p-c10 ketch-space-x-c8 xs2:ketch-space-x-[14px] ketch-rounded-normal;
    }
    .try-course {
      @apply ketch-border ketch-border-border-color hover:ketch-border-primary-text-color ketch-cursor-pointer;
      @apply ketch-ml-c10 xs4:ketch-ml-c30 sm:ketch-ml-c50 md:ketch-ml-0;
    }
  }
</style>
