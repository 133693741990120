import { render, staticRenderFns } from "./TextRenderer.vue?vue&type=template&id=146ad8cc&lang=pug"
import script from "./TextRenderer.vue?vue&type=script&setup=true&lang=ts"
export * from "./TextRenderer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TextRenderer.vue?vue&type=style&index=0&id=146ad8cc&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports