<template lang="pug">
.audio-player(@click.stop)
    vue-plyr(
        :options='options',
        ref='audioPlayer'
    )
        audio(
            controls,
            crossorigin,
            playsinline
        )
            source(ref='audioSourceEl')
    .loader-info(v-if='loading')
        LoadingSpinner
        h6 {{ $t('editor.readingAudioStream') }}
</template>

<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref } from 'vue'
  //@ts-ignore
  import VuePlyr from 'vue-plyr'
  import Hls from 'hls.js'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import type { Nullable } from '@/services/interfaces/QuizQuestion'

  const props = defineProps({
    audioSource: {
      type: String,
      required: true,
    },
  })

  const audioPlayer = ref(null as Nullable<any>)
  const audioSourceEl = ref(null as Nullable<HTMLSourceElement>)
  const options = {
    autoplay: false,
    muted: false,
    captions: {
      active: true,
      language: 'de',
      update: true,
    },
    controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings'],
    speed: {
      selected: 1,
      options: [1, 1.25, 1.5],
    },
    tooltips: { controls: true, seek: true },
  }
  const hls = new Hls()
  const loading = ref(true)

  onMounted(() => {
    const intervalId = window.setInterval(async () => {
      const data = await fetch(props.audioSource!)
      if (data.status === 200) {
        window.clearInterval(intervalId)
        bootstrapAudioPlayer()
        loading.value = false
      }
    }, 1000)
  })

  const bootstrapAudioPlayer = () => {
    if (Hls.isSupported() && hls) {
      hls.loadSource(props.audioSource)
      hls.attachMedia(audioPlayer.value?.player.media)
      ;(window as any).hls = hls
    } else {
      const sourceEl = audioSourceEl.value as HTMLSourceElement
      sourceEl.src = props.audioSource
    }
  }

  onBeforeUnmount(() => {
    if (Hls.isSupported() && hls) {
      hls.detachMedia()
      hls.destroy()
    }
  })
</script>

<style lang="postcss">
  .audio-player {
    .loader-info {
      @apply ketch-flex ketch-space-x-c15 ketch-mt-c10;
      h6 {
        @apply ketch-text-xxs ketch-leading-xxs ketch-text-red-500;
      }
      .loading {
        @apply ketch-m-0;
      }
    }
    .plyr--audio {
      .plyr__controls {
        @apply ketch-bg-transparent ketch-p-0;
      }
      .plyr__menu__container {
        --plyr-audio-control-background-hover: var(--primary-color);
        --plyr-audio-control-color-hover: var(--primary-foreground-color);
      }
    }
  }
</style>
