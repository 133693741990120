<template lang="pug">
.video-player(@click.stop)
    vue-plyr(
        :options='options',
        ref='plyr'
    )
        video(
            :poster='coverImage',
            controls,
            crossorigin,
            playsinline
        )
            source(ref='videoSourceEl')
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
  //@ts-ignore
  import VuePlyr from 'vue-plyr'
  import Hls from 'hls.js'
  import CookieHelper from '@/helpers/CookieHelper'
  import useSegment from '@/composables/useSegment'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useCourse from '@/composables/useCourse'
  import { UserModule } from '@/store/modules/user'
  import { useRoute } from 'vue-router/composables'
  import eventBus from '@/main'
  import type { PropType } from 'vue'
  import type { TimelineMarker } from '@/services/interfaces/Common'
  import type { Nullable } from '@/services/interfaces/Content'

  const props = defineProps({
    videoSource: {
      type: String,
      required: true,
    },
    posterImage: {
      type: String,
      default: '',
    },
    posterImageWidth: {
      type: String,
      default: '',
    },
    timelineMarkers: {
      type: Array as PropType<TimelineMarker[]>,
      default: null,
    },
    contentType: {
      type: String,
      default: '',
    },
    courseId: {
      type: String,
      default: '',
    },
    moduleId: {
      type: String,
      default: '',
    },
    lessonId: {
      type: String,
      default: '',
    },
    learningUnitId: {
      type: String,
      default: '',
    },
    canAttachPauseEvent: {
      type: Boolean,
      default: false,
    },
  })

  const route = useRoute()

  const { videoStarted, videoPaused, videoCompleted, companyOnboardingPopupVideoStarted } = useSegment()
  const { current } = useBreakpoint()
  const { course, currentModule, currentLesson } = useCourse()

  const options = {
    autoplay: false,
    blankVideo: 'https://cdn.plyr.io/static/blank.mp4',
    muted: false,
    captions: {
      active: true,
      language: 'de',
      update: true,
    },
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen', 'settings'],
    tooltips: { controls: true, seek: true },
    speed: {
      selected: 1,
      options: [1, 1.25, 1.5],
    },
  }

  const videoDuration = ref(0)
  const timerId = ref(0)
  // eslint-disable-next-line no-undef
  const timelineMarkerDivs = ref(null as NodeListOf<Element> | null)
  const plyr = ref(null as Nullable<HTMLDivElement>)
  const videoSourceEl = ref(null as Nullable<HTMLSourceElement>)
  const videoPlayerInstanceIsReady = ref(false)
  const hls = new Hls()

  const player = computed(() => (plyr.value as any)?.player)
  const coverImage = computed((): string => {
    if (!props.posterImage) return ''
    const url = new URL(props.posterImage)
    url.searchParams.set('width', props.posterImageWidth || imageWidth.value.toString())
    return url.toString()
  })

  const imageWidth = computed((): number => {
    const widths: { [key: string]: number } = {
      xs: 320,
      xs1: 360,
      xs2: 375,
      xs3: 411,
      xs4: 540,
      sm: 768,
      md: 992,
      lg: 1024,
      xl: 1280,
    }
    return widths[current.value] || 1440
  })

  onMounted(() => {
    boostrapVideoPlayer()
    bindPlayerEvents()
    window.addEventListener('resize', onResize)
  })

  const setCurrentTime = (time: number): void => {
    player.value.currentTime = time
    if (player.value.paused) player.value.play()
  }

  const setVideoTimestamp = (): void => {
    if (props.learningUnitId) {
      const value = CookieHelper.getCookieValue(props.learningUnitId)
      if (value) {
        setTimeout(() => setCurrentTime(value.currentTime), 2000)
      }
    }
  }

  const boostrapVideoPlayer = (): void => {
    if (Hls.isSupported() && hls) {
      if (props.posterImage) player.value.poster = coverImage.value
      hls.loadSource(props.videoSource)
      hls.attachMedia(player.value.media)
      ;(window as any).hls = hls
    } else {
      const sourceEl = videoSourceEl.value as HTMLSourceElement
      sourceEl.src = props.videoSource
    }
  }

  const onPlayerReady = () => {
    videoPlayerInstanceIsReady.value = !videoPlayerInstanceIsReady.value
  }

  const bindPlayerEvents = (): void => {
    player.value.once('ready', onPlayerReady)
    player.value.once('progress', onProgress)
    player.value.on('timeupdate', onTimeUpdate)
    player.value.on('playing', trackStartedPlayingVideo)
    player.value.on('pause', trackVideoPaused)
    player.value.on('ended', trackVideoCompleted)
    if (props.canAttachPauseEvent) {
      eventBus.$on('pause-video', () => {
        player.value.pause()
      })
    }
  }

  const unbindPlayerEvents = () => {
    player.value.off('ready', onPlayerReady)
    player.value.off('progress', onProgress)
    player.value.off('timeupdate', onTimeUpdate)
    player.value.off('playing', trackStartedPlayingVideo)
    player.value.off('pause', trackVideoPaused)
    player.value.off('ended', trackVideoCompleted)
  }

  const onLessonPage = computed((): boolean => !!(props.lessonId && props.moduleId && props.courseId))
  const onModulePage = computed((): boolean => !!(props.moduleId && props.courseId))
  const onCourseDetailPage = computed((): boolean => !!props.courseId)
  const onTrainingsPage = computed((): boolean => route.name === 'Trainings')

  const trackStartedPlayingVideo = (): void => {
    if (onLessonPage.value) {
      videoStarted(
        props.lessonId,
        props.contentType,
        props.courseId,
        course.value!.title,
        props.moduleId,
        currentModule.value.name,
        props.lessonId,
        currentLesson.value!.title,
      )
    } else if (onModulePage.value) {
      videoStarted(
        props.moduleId,
        props.contentType,
        props.courseId,
        course.value!.title,
        props.moduleId,
        currentModule.value.name,
      )
    } else if (onCourseDetailPage.value) {
      videoStarted(props.courseId, props.contentType, props.courseId, course.value!.title)
    } else if (onTrainingsPage.value) {
      if (props.contentType === 'company_onboarding_popup') {
        companyOnboardingPopupVideoStarted(UserModule.currentCompany!.id)
      }
      videoStarted('', props.contentType)
    }
  }

  const trackVideoPaused = (): void => {
    if (onLessonPage.value) {
      videoPaused(
        props.lessonId,
        props.contentType,
        props.courseId,
        course.value!.title,
        props.moduleId,
        currentModule.value.name,
        props.lessonId,
        currentLesson.value!.title,
      )
    } else if (onModulePage.value) {
      videoPaused(
        props.moduleId,
        props.contentType,
        props.courseId,
        course.value!.title,
        props.moduleId,
        currentModule.value.name,
      )
    } else if (onCourseDetailPage.value) {
      videoPaused(props.courseId, props.contentType, props.courseId, course.value!.title)
    } else if (onTrainingsPage.value) {
      videoPaused('', props.contentType)
    }
  }

  const trackVideoCompleted = (): void => {
    if (onLessonPage.value) {
      videoCompleted(
        props.lessonId,
        props.contentType,
        props.courseId,
        course.value!.title,
        props.moduleId,
        currentModule.value.name,
        props.lessonId,
        currentLesson.value!.title,
      )
    } else if (onModulePage.value) {
      videoCompleted(
        props.moduleId,
        props.contentType,
        props.courseId,
        course.value!.title,
        props.moduleId,
        currentModule.value.name,
      )
    } else if (onCourseDetailPage.value) {
      videoCompleted(props.courseId, props.contentType, props.courseId, course.value!.title)
    } else if (onTrainingsPage.value) {
      videoCompleted('', props.contentType)
    }
  }

  const onProgress = (e: CustomEvent): void => {
    videoDuration.value = e.detail.plyr.duration
    if (!props.timelineMarkers) return
    const progressDiv = document.querySelector('.plyr .plyr__progress') as HTMLDivElement
    if (!progressDiv) return

    props.timelineMarkers.forEach((tm) => {
      const marker = document.createElement('div')
      marker.className = 'marker'
      marker.setAttribute('data-time', tm.time.toString())
      marker.addEventListener('click', onMarkerClick)
      progressDiv?.appendChild(marker)
    })
    timelineMarkerDivs.value = document.querySelectorAll('.plyr .plyr__progress > .marker')
    positionTimelineMarkers()
    setVideoTimestamp()
  }

  const onMarkerClick = (e: Event): void => {
    const marker = e.target as HTMLDivElement
    setCurrentTime(Number(marker.dataset.time) || 0)
  }

  const updateCookieWithCurrentTime = (currentTime: number): void => {
    if (props.learningUnitId) {
      const value = CookieHelper.getCookieValue(props.learningUnitId)
      if (value?.currentTime < currentTime) {
        CookieHelper.setCookieValue(props.learningUnitId, JSON.stringify({ currentTime }))
      }
    }
  }

  const onTimeUpdate = (e: CustomEvent): void => {
    if (!videoPlayerInstanceIsReady.value) return
    const { currentTime } = e.detail.plyr
    updateCookieWithCurrentTime(currentTime)
    timelineMarkerDivs.value?.forEach((marker) => {
      const markerStyle = (marker as HTMLDivElement).style
      const thumbStart = Number(marker.getAttribute('data-thumb-start')) || 0
      const thumbEnd = Number(marker.getAttribute('data-thumb-end')) || 0
      const isInputRangeThumbOnMarker = currentTime > thumbStart && currentTime < thumbEnd
      if (isInputRangeThumbOnMarker) {
        markerStyle.setProperty('z-index', '2')
      } else if (markerStyle.zIndex !== '4') {
        markerStyle.setProperty('z-index', '4')
      }
    })
  }

  const positionTimelineMarkers = (): void => {
    const progressDiv = document.querySelector('.plyr .plyr__progress') as HTMLDivElement
    if (!progressDiv) return
    const progressWidth = progressDiv.offsetWidth

    timelineMarkerDivs.value?.forEach((marker) => {
      const thumbWidth = (10 / progressWidth) * videoDuration.value
      const markerTime = Number(marker.getAttribute('data-time')) || 0
      const left = (markerTime / videoDuration.value) * progressWidth
      const style = (marker as HTMLDivElement).style
      style.setProperty('left', `${left}px`)
      marker.setAttribute('data-thumb-start', (markerTime - thumbWidth).toString())
      marker.setAttribute('data-thumb-end', (markerTime + thumbWidth).toString())
    })
  }

  const onResize = (): void => {
    if (timerId.value) {
      window.clearTimeout(timerId.value)
    }
    timerId.value = window.setTimeout(positionTimelineMarkers, 200)
  }

  onBeforeUnmount(() => {
    if (Hls.isSupported() && hls) {
      hls.detachMedia()
      hls.destroy()
    }
    window.removeEventListener('resize', onResize)
    unbindPlayerEvents()
  })
</script>

<style lang="postcss">
  .video-player {
    height: calc(100vh - 80px);
    @apply ketch-max-h-[180px] xs1:ketch-max-h-[203px] xs2:ketch-max-h-[211px];
    @apply xs3:ketch-max-h-[239px] xs4:ketch-max-h-[304px];
    @apply sm:ketch-max-h-[410px] md:ketch-max-h-[542px] xl:ketch-max-h-[591px];
    @screen 2xl {
      height: calc(100vh - 130px);
    }
    .plyr--video {
      .plyr__control--overlaid,
      .plyr__control--overlaid:hover {
        @apply md:ketch-w-c90 md:ketch-h-c90 ketch-w-c60 ketch-h-c60 ketch-bg-primary-color;
        svg {
          @apply ketch-text-primary-foreground-color md:ketch-h-c25 md:ketch-w-c25 ketch--left-c1 md:ketch-left-c10;
        }
      }
      &:not(.plyr--hide-controls),
      &.plyr--paused {
        .plyr__controls .plyr__time {
          @apply ketch-font-bold;
        }
      }
      .plyr__video-wrapper {
        z-index: inherit;
      }
      .plyr__controls .plyr__progress__container {
        @apply ketch-p-0 ketch-row-start-2 ketch-col-start-1 ketch-col-end-7;
        input[type='range'] {
          z-index: 3;
        }
        .marker {
          @apply ketch-w-c5 ketch-h-c8 ketch-absolute ketch-top-[7px] ketch-cursor-pointer ketch-bg-gray-700 ketch-opacity-75;
          z-index: 4;
        }
      }
      .plyr__controls .plyr__volume {
        input[type='range'] {
          @apply ketch-text-primary-color;
        }
      }
      .plyr__menu__container {
        --plyr-video-control-background-hover: var(--primary-color);
        --plyr-video-control-color-hover: var(--primary-foreground-color);
      }
    }
  }
</style>
