<template lang="pug">
.exam-results-wrapper
    LoadingSpinner(v-if='loadingResults')
    .results-container(
        :class='{ passed: examResult.passed, failed: !examResult.passed }',
        data-cy='exams-results-page',
        v-else
    )
        h3.passed-title {{ passedStatusText }}
        HTMLRenderer.exam-description(
            :html='courseExam.description || ""',
            :increase-padding='true',
            link-target='_blank',
            v-if='courseExam'
        )
        .results(v-if='examResult && examResult.parts')
            h3.title {{ $t('courses.examSimulation.results') }}
            .result(
                :class='{ hasSubjects: hasSubjects }',
                :key='result.partId',
                v-for='(result, index) in examResult.parts'
            )
                .part-wrapper
                    .part
                        h5 {{ $t('courses.examSimulation.part') }} {{ index + 1 }}: {{ result.name }}
                        span(v-if='!hasSubjects') :
                    h5.score(v-if='!hasSubjects') {{ result.subjects[0].score }}%
                .subject-container(v-if='hasSubjects')
                    .subject(
                        :key='subject.subjectId',
                        v-for='subject in result.subjects'
                    )
                        .subject-title-score
                            h5.subject-title {{ subject.subjectName }}:
                            h5.score {{ subject.score }}%
                        h5.subject-subtitle(v-if='subject.subtitle') {{ subject.subtitle }}
        router-link.h5.to-answers-review(
            :to='{ name: "CourseExamReviewAnswers", params: { completionId: examResult.id } }',
            data-cy='review-answers'
        ) {{ $t('courses.examSimulation.toAnswersReview') }}
        .cta
            KetchUpButton.end(@click.native='endExam')
                h5 {{ $t('courses.examSimulation.end') }}
            KetchUpButton.primary(@click.native='startNewSimulation')
                h5 {{ $t('courses.examSimulation.startNewSimulation') }}
</template>

<script setup lang="ts">
  import { computed, onMounted } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import CookieHelper from '@/helpers/CookieHelper'
  import useSegment from '@/composables/useSegment'
  import useGamification from '@/composables/useGamification'
  import { useRouter } from 'vue-router/composables'
  import useI18n from '@/composables/useI18n'
  import useCommonMixin from '@/composables/useCommonMixin'
  import * as Sentry from '@sentry/vue'

  const router = useRouter()
  const { course, currentCourseId, examCompletionIdParam } = useCourse()
  const { trackPage, pageViewed } = useSegment()
  const { trackGamificationActivity } = useGamification()
  const { translateString } = useI18n()
  const { isKnowledgeCheckCompany } = useCommonMixin()

  const loadingResults = computed(() => examResult.value === null || !examResult.value?.parts)

  const examResult = computed(() => CourseModule.examResult)

  const courseExam = computed(() => {
    return course.value?.exam
  })

  const passedStatusText = computed(() => {
    if (!examResult.value) return ''
    return examResult.value!.passed
      ? translateString('courses.examSimulation.passedExamText')
      : translateString('courses.examSimulation.notPassedExamText')
  })

  const hasSubjects = computed(() => {
    return CourseModule.examHasSubjects
  })

  const endExam = () => {
    router.push({ name: isKnowledgeCheckCompany.value ? 'KnowledgeCheckInfo' : 'ExamStartPage' }).catch(() => {
      return
    })
  }

  const startNewSimulation = () => {
    CourseModule.resetExamCompletionId()
    router.push({ name: 'CourseExamContent' }).catch(() => {
      return
    })
  }

  const fetchCourseAndExamResults = async () => {
    try {
      if (!course.value) {
        await CourseModule.getCourse({ courseId: currentCourseId.value })
      }
      fetchExamResults()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const fetchExamResults = () => {
    CourseModule.getExamResult(examCompletionIdParam.value)
  }

  fetchCourseAndExamResults()

  const passedExamGamificationActivityTracked = CookieHelper.getCookieValue('passed_exam_tracked')

  onMounted(() => {
    trackPage('Course Exam Results')
    pageViewed('Course Exam Results')
    if (examResult.value?.passed && !passedExamGamificationActivityTracked) {
      trackGamificationActivity('EXAM_PASSED', { courseId: currentCourseId.value, moduleId: null, lessonId: null })
      CookieHelper.setCookieValue('passed_exam_tracked', 'true')
    }
  })
</script>

<style lang="postcss" scoped>
  .exam-results-wrapper {
    @apply ketch-flex ketch-justify-center;
    .loading {
      @apply ketch-mx-auto;
    }
    .results-container {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-max-w-[450px];
      .passed-title {
        @apply ketch-text-center ketch-font-bold ketch-mb-[17px] ketch-max-w-[370px];
      }
      .exam-description {
        @apply ketch-text-center ketch-mb-c30 ketch-max-w-[430px];
      }
      .results {
        @apply ketch-border ketch-border-secondary-hover-color ketch-rounded-normal ketch-py-c15 ketch-px-c10;
        @apply ketch-bg-secondary-color ketch-bg-opacity-[0.05] ketch-w-full;
        .title {
          @apply ketch-mb-c15 ketch-font-bold;
        }
        .score {
          @apply ketch-ml-c5 ketch-font-bold;
        }
        .result {
          @apply ketch-flex ketch-flex-col;
          &.hasSubjects {
            .part-wrapper {
              .part {
                h5 {
                  @apply ketch-font-bold;
                }
              }
            }
          }
          .part-wrapper {
            @apply ketch-flex ketch-items-center;
            .part {
              @apply ketch-flex ketch-items-center;
            }
          }
          .subject-container {
            @apply ketch-mt-c10;
            .subject {
              @apply ketch-flex ketch-flex-col ketch-py-c10 ketch-border-b ketch-border-border-color;
              .subject-title-score {
                @apply ketch-flex ketch-items-center;
              }
              &:last-of-type {
                @apply ketch-border-b-0;
              }
            }
          }
        }
      }
      .to-answers-review {
        @apply ketch-pt-c8 ketch-pb-c25 ketch-underline ketch-text-secondary-color;
      }
      .cta {
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-space-x-c10;
        .end {
          @apply ketch-border-border-color ketch-w-[100px];
        }
        .primary {
          @apply ketch-w-auto ketch-px-c15;
        }
      }
    }
  }
</style>
