<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  // @ts-ignore
  import DatePicker from 'vue2-datepicker'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import UsersApi from '@/services/api/UsersApi'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'
  import { DateTime } from 'luxon'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { UserModule } from '@/store/modules/user'
  import CookieHelper from '@/helpers/CookieHelper'

  const props = defineProps({
    courseId: {
      type: String,
      default: null,
    },
  })

  const { translateString } = useI18n()
  const { examAppointmentAdded } = useSegment()
  const { identifyUserInSegmentWithExamDates } = useCommonMixin()

  const fetchingExamAppointments = ref(true)
  const settingAppointment = ref(false)
  const examAppointments = ref<{ courseId: string; date: string }[]>([])

  const loadingData = computed(() => !examCourses.value.length || fetchingExamAppointments.value)

  const appointmentsSelected = computed(
    () => examAppointments.value.length > 0 && examAppointments.value.every((appointment) => !!appointment.date),
  )

  const courseBundles = computed(() => CourseModule.courseBundles)

  const bundleJobTitle = computed(() =>
    props.courseId
      ? courseBundles.value?.find((bundle) => bundle.courses.some((course) => course.id === props.courseId))?.jobTitle
      : null,
  )

  const examCourses = computed(() => {
    return (
      courseBundles.value
        ?.flatMap((bundle) =>
          bundle.courses
            .filter((course) => (course.trial || course.purchased) && course.exam)
            .map((course) => ({
              courseId: course.id,
              cardTeaserImageUrl: course.cardTeaserImageUrl || course.teaserImageUrl,
            })),
        )
        .filter((course) => (props.courseId ? course.courseId === props.courseId : true)) || []
    )
  })

  const courseSectionsAssignedCourses = computed(() => CourseModule.courseSectionsAssignedCourses)

  const disabledBeforeToday = computed(() => (date: Date) => {
    const today = DateTime.local().startOf('day')
    const inputDate = DateTime.fromJSDate(date).startOf('day')

    return inputDate < today
  })

  const examAppointmentEntry = computed(
    () => (courseId: string) => examAppointments.value.find((appointment) => appointment.courseId === courseId),
  )

  const examAppointment = computed(() => (courseId: string) => examAppointmentEntry.value(courseId)?.date ?? null)

  const transformedDates = computed(() => examAppointments.value.filter((appointment) => appointment.date !== null))

  const selectAppointment = (courseId: any, date: any) => {
    const appointmentEntry = examAppointmentEntry.value(courseId)
    if (appointmentEntry) {
      appointmentEntry.date = date
    } else {
      examAppointments.value.push({
        courseId,
        date,
      })
    }
  }

  const clearAppointment = (courseId: any) => {
    const appointmentIndex = examAppointments.value.findIndex((appointment) => appointment.courseId === courseId)
    if (appointmentIndex > -1) {
      examAppointments.value.splice(appointmentIndex, 1)
    }
  }

  const setAppointment = () => {
    if (!appointmentsSelected.value) {
      eventBus.$emit('close-modal')
      return
    }
    settingAppointment.value = true
    UsersApi.setExamAppointments(transformedDates.value)
      .then(() => {
        CookieHelper.removeCrossDomainCookie('skip-setting-exam-appointment')
        UserModule.getUser()
        eventBus.$toasted.success(translateString('examDatePicker.appointmentSetSuccessfully'))
        transformedDates.value.forEach((value) => {
          const course = courseSectionsAssignedCourses.value?.find((c) => c.id === value.courseId)
          if (course) {
            examAppointmentAdded(course.id, course.title, value.date)
            identifyUserInSegmentWithExamDates(course.shortTitle, value.date)
          }
        })
      })
      .finally(() => {
        settingAppointment.value = false
        eventBus.$emit('close-modal')
      })
  }

  const skipSettingExamAppointment = () => {
    UserModule.setSkippedSettingExamAppointment(true)
    CookieHelper.setCrossDomainCookie('skip-setting-exam-appointment', 'true')
    eventBus.$emit('close-modal')
  }

  const fetchExamAppointments = () => {
    UsersApi.getExamAppointments()
      .then((appointments) => {
        appointments.forEach((appointment) => {
          examAppointments.value.push({
            courseId: appointment.courseId,
            date: appointment.date,
          })
        })
      })
      .finally(() => (fetchingExamAppointments.value = false))
  }

  const fetchPackageBundlesAndExamAppointments = () => {
    CourseModule.getPackageBundles().finally(() => {
      if (examCourses.value.length !== 0) {
        fetchExamAppointments()
      } else {
        eventBus.$emit('close-modal')
      }
    })
  }

  onMounted(() => {
    fetchPackageBundlesAndExamAppointments()
  })
</script>

<template lang="pug">
.exam-appointment-modal
    .exam-appointment-container
        .exam-appointment-wrapper
            h2.exam-appointment-title
                span {{ $t('examDatePicker.title') }}
                span.job-title(v-if='bundleJobTitle') {{ bundleJobTitle }}
            h4.exam-appointment-description {{ $t('examDatePicker.helperText') }}
            .appointment-courses-loader(v-if='loadingData')
                LoadingSpinner
            .exam-appointments-submit-cta(v-else)
                .exam-appointments
                    .exam-appointment(
                        :key='course.courseId',
                        v-for='course in examCourses'
                    )
                        .appointment-course-image
                            VideoOrImageRenderer(
                                :file-type='""',
                                :image-url='course.cardTeaserImageUrl',
                                image-only
                            )
                        .appointment-picker
                            h5 {{ $t('examDatePicker.dateOfTheExam') }}
                            DatePicker(
                                :disabled-date='disabledBeforeToday',
                                :placeholder='$t("examDatePicker.date")',
                                :value='examAppointment(course.courseId)',
                                @clear='clearAppointment(course.courseId)',
                                @input='selectAppointment(course.courseId, $event)',
                                format='YYYY-MM-DD',
                                value-type='YYYY-MM-DD'
                            )
                .new-signup-flow-submit-cta
                    KetchUpButton(
                        :disabled='settingAppointment || !appointmentsSelected',
                        @click.native='setAppointment'
                    )
                        LoadingSpinner(v-if='settingAppointment')
                        h5(v-else) {{ $t('save') }}
        .skip-cta(
            @click='skipSettingExamAppointment',
            v-if='!courseId',
            data-cy='skip-appointment'
        )
            h5 {{ $t('skip') }}
</template>

<style lang="postcss">
  .exam-appointment-modal {
    @apply ketch-h-full;
    .exam-appointment-container {
      @apply ketch-h-full ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-p-c20 ketch-text-[#00254A];
      .exam-appointment-wrapper {
        @apply ketch-flex ketch-flex-col ketch-justify-center ketch-items-center ketch-space-y-c20;
        @apply ketch-bg-module-selector-background-color ketch-rounded-large;
        .appointment-courses-loader {
          @apply ketch-py-c20;
        }
        .exam-appointment-title {
          @apply ketch-flex ketch-flex-col ketch-items-center ketch-font-bold;
          span {
            @apply ketch-text-center;
          }
          span.job-title {
            @apply ketch-text-primary-color;
          }
        }
        .exam-appointment-description {
          @apply ketch-text-center sm:ketch-max-w-[480px];
        }
        .exam-appointments-submit-cta {
          @apply sm:ketch-max-w-[400px];
          .exam-appointments {
            @apply ketch-flex ketch-flex-col;

            .exam-appointment {
              @apply ketch-flex ketch-flex-col ketch-space-x-0 ketch-space-y-c10 ketch-py-c15 ketch-border-b;
              @apply ketch-border-b-border-color sm:ketch-flex-row sm:ketch-space-x-c20 sm:ketch-space-y-0;

              &:last-child {
                @apply ketch-border-b-0;
              }

              .appointment-course-image {
                @apply sm:ketch-max-w-[180px];
              }

              .appointment-picker {
                @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-w-full;

                h5 {
                  @apply ketch-font-bold;
                }

                .mx-datepicker {
                  @apply ketch-w-full;

                  input {
                    @apply ketch-rounded-lg hover:ketch-border-primary-color;
                  }
                }
              }
            }
          }
          .new-signup-flow-submit-cta {
            @apply ketch-mt-c20;
            button {
              @apply ketch-w-full;
            }
          }
        }
      }
      .skip-cta {
        @apply ketch-flex ketch-justify-center ketch-mt-c15;
        h5 {
          @apply ketch-underline ketch-cursor-pointer;
        }
      }
    }
  }
</style>
