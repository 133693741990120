<template lang="pug">
TrainingsMenuDropdownLoader(
    @hide-dropdown='$emit("hide-dropdown")',
    v-if='loadingCourseSections'
)
.trainings-menu-dropdown(
    @mouseleave='$emit("hide-dropdown")',
    ref='menuDropdown',
    v-else
)
    .sections-menu-wrapper(
        :class='{ "has-trial-or-purchased-courses": hasTrialOrPurchasedCourses }',
        ref='trainingSections'
    )
        h5.trainings-menu-title {{ $t('navigation.submenu.categories') }}
        .training-sections
            .training-section(
                :key='section.id',
                @click='scrollToSection(section.id)',
                v-for='section in courseSectionsAbstractDetails'
            )
                .section-details
                    .random-icon
                        SVGRenderer(
                            :has-hover='false',
                            :icon='section.icon',
                            :stroke-color='"var(--secondary-color)"',
                            height='20',
                            width='20'
                        )
                    .name-description
                        HTMLRenderer.h5(:html='section.name || ""')
                        HTMLRenderer.h5.description(:html='section.description || ""')
    .trainings-menu(v-if='hasTrialOrPurchasedCourses')
        .purchased-trainings-wrapper(v-if='purchasedTrainings.length')
            h5.trainings-menu-title {{ $t('navigation.submenu.purchasedTrainings') }}
            .purchased-trainings
                router-link.purchased-training(
                    :key='training.id',
                    :to='{ name: "CourseDetails", params: { courseId: training.id } }',
                    v-for='training in purchasedTrainings'
                )
                    img.training-image(:src='training.cardTeaserImageUrl || cardEmptyImage')
                    h5.training-title {{ training.title }}
        .in-trial-trainings-wrapper(v-if='inTrialTrainings.length')
            h5.trainings-menu-title {{ $t('navigation.submenu.trainingsInTest') }}
            .in-trial-trainings
                router-link.in-trial-training(
                    :key='training.id',
                    :to='{ name: "CourseDetails", params: { courseId: training.id } }',
                    v-for='training in inTrialTrainings'
                )
                    img.training-image(:src='training.cardTeaserImageUrl')
                    h5.training-title {{ training.title }}
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import { EditorModule } from '@/store/modules/editor'
  import { CourseModule } from '@/store/modules/course'
  import useEditor from '@/composables/useEditor'
  import useCommonMixin from '@/composables/useCommonMixin'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import TrainingsMenuDropdownLoader from '@/components/loaders/TrainingsMenuDropdownLoader.vue'
  import { UserModule } from '@/store/modules/user'
  import { useRoute, useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import type { CourseItem } from '@/services/interfaces/Course'
  import useCourse from '@/composables/useCourse'

  const emit = defineEmits(['hide-dropdown'])
  const router = useRouter()
  const route = useRoute()
  const { hasPurchasedAtLeastACourse } = useCourse()
  const { inEditorMode } = useEditor()
  const { scrollIntoSection, isSachkundeCompany } = useCommonMixin()
  const { isDesktopDevice } = useBreakpoint()
  const { bookIcon, zapIcon, playCircleIcon, cardEmptyImage } = useIcons()
  const menuDropdown = ref(null as HTMLDivElement | null)
  const trainingSections = ref(null as HTMLDivElement | null)

  const courseSections = computed(() => {
    if (inEditorMode.value) return EditorModule.courseSections
    return CourseModule.courseSections
  })

  const loadingCourseSections = computed(() => courseSections.value === null)

  const courseSectionsAbstractDetails = computed(() => {
    if (isSachkundeCompany.value && !hasPurchasedAtLeastACourse.value) {
      const section = courseSections.value?.[0]
      return section
        ? [
          {
            id: section.id,
            name: section.name,
            description: section.description,
            icon: sectionIcon.value(0),
          },
        ]
        : []
    }
    return courseSections.value
      ?.filter((section) => section.id)
      .map((section, index) => ({
        id: section.id,
        name: section.name,
        description: section.description,
        icon: sectionIcon.value(index),
      }))
  })

  const sectionIcon = computed(() => (index: number) => {
    const icons = [bookIcon.value, zapIcon.value, playCircleIcon.value]
    if (icons[index]) return icons[index]
    return icons[0]
  })

  const purchasedTrainings = computed(() => {
    return courseSections.value?.reduce((acc, section) => {
      section.assignedCourses.forEach((course) => {
        if (course.purchased && course.visibleToGroup && !course.locked) {
          acc.push(course)
        }
      })
      return acc
    }, [] as CourseItem[])
  })

  const inTrialTrainings = computed(() => {
    return courseSections.value?.reduce((acc, section) => {
      section.assignedCourses.forEach((course) => {
        if (course.trial && course.visibleToGroup && !course.locked) {
          acc.push(course)
        }
      })
      return acc
    }, [] as CourseItem[])
  })

  const hasTrialOrPurchasedCourses = computed(() => purchasedTrainings.value?.length || inTrialTrainings.value?.length)

  const scrollToSection = (sectionId: string) => {
    if (isDesktopDevice.value) {
      emit('hide-dropdown')
    } else {
      eventBus.$emit('close-modal')
    }
    if (route.name !== 'Trainings') {
      router.push({ name: 'Trainings', query: { sectionId: sectionId } })
    } else {
      const element = document.getElementById(sectionId) as HTMLDivElement
      if (element) {
        const top = element.getBoundingClientRect().top
        scrollIntoSection(sectionId, top)
      }
    }
  }

  const setSubmenuHeight = (timeToWaitInMilliseconds: number) => {
    if (isDesktopDevice.value) {
      setTimeout(() => {
        const sectionsDiv = trainingSections.value
        if (sectionsDiv && menuDropdown.value) {
          const sectionsHeight = sectionsDiv.offsetHeight
          menuDropdown.value.style.height = `${sectionsHeight}px`
        }
      }, timeToWaitInMilliseconds)
    }
  }

  watch(loadingCourseSections, (value) => {
    if (!value) {
      setTimeout(() => {
        setSubmenuHeight(10)
      }, 10)
    }
  })

  watch(
    () => route.path,
    () => {
      if (isDesktopDevice.value) {
        emit('hide-dropdown')
      }
    },
  )

  if (route.name !== 'Trainings' && !courseSections.value) {
    if (UserModule.currentCompany?.id) {
      CourseModule.getCourseSections(UserModule.currentCompany.id)
    }
  }

  onMounted(() => {
    if (menuDropdown.value && !loadingCourseSections.value) {
      setSubmenuHeight(20)
    }
  })
</script>

<style lang="postcss">
  .trainings-menu-dropdown {
    @apply ketch-flex ketch-flex-col ketch-bg-white ketch-border ketch-border-[#EAECF0] ketch-rounded-[8px];
    @apply md:ketch-absolute md:ketch-top-c80 md:ketch-left-[18%];
    @apply md:ketch-flex-row md:ketch-justify-between md:ketch-w-[768px];
    box-shadow:
      0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    .trainings-menu-title {
      @apply ketch-text-secondary-color ketch-mb-c25;
    }
    .sections-menu-wrapper,
    .trainings-menu {
      @apply ketch-px-c16 ketch-py-c25 md:ketch-p-c30;
    }
    .sections-menu-wrapper {
      @apply ketch-rounded-t-[8px] md:ketch-rounded-tl-[8px] md:ketch-rounded-bl-[8px] ketch-h-min;
      &.has-trial-or-purchased-courses {
        @apply md:ketch-max-w-[320px];
      }
      .training-sections {
        @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-cursor-pointer;
        .training-section {
          @apply ketch-flex ketch-flex-col hover:ketch-bg-primary-text-color hover:ketch-bg-opacity-5;
          @apply hover:ketch-transition-[background-color] md:ketch--mx-c30 md:ketch-px-c30 md:ketch-py-c5;
          .section-details {
            @apply ketch-flex ketch-space-x-c20;
            .name-description {
              @apply ketch-flex ketch-flex-col;
              .description {
                @apply ketch-opacity-50;
              }
            }
          }
        }
      }
    }
    .trainings-menu {
      @apply ketch-bg-[#F9FAFB] ketch-max-w-[448px] ketch-rounded-b-[8px] ketch-flex ketch-flex-col ketch-space-y-c25;
      @apply md:ketch-overflow-hidden md:ketch-overflow-y-auto md:ketch-rounded-tr-[8px] md:ketch-rounded-br-[8px];
      .purchased-trainings-wrapper,
      .in-trial-trainings-wrapper {
        .purchased-trainings,
        .in-trial-trainings {
          @apply ketch-flex ketch-flex-col ketch-space-y-c30;
          .purchased-training,
          .in-trial-training {
            @apply ketch-flex ketch-flex-col ketch-space-y-c15;
            @apply md:ketch-flex-row md:ketch-space-y-0 md:ketch-space-x-c25;
            @apply md:ketch--mx-c30 md:ketch-px-c30 md:ketch-py-c5;
            @apply hover:ketch-transition-[background-color] hover:ketch-bg-black hover:ketch-bg-opacity-5;
            .training-image {
              @apply ketch-w-full ketch-h-auto ketch-rounded-[5px] md:ketch-w-[145px] md:ketch-h-[87px];
            }
          }
        }
      }
    }
  }
</style>
