//@ts-ignore
import Client from 'shopify-buy'
import { UserModule } from '@/store/modules/user'
import eventBus from '@/main'
import useI18n from '@/composables/useI18n'
import useSegment from '@/composables/useSegment'
import { useRoute, useRouter } from 'vue-router/composables'
import { computed, ComputedRef } from 'vue'
import { CourseModule } from '@/store/modules/course'
import useCommonMixin from '@/composables/useCommonMixin'
import CommonApi from '@/services/api/CommonApi'

interface useShopifyClientReturn {
  createShopifyCheckout: (email?: string, shopifyVariantId?: string, enforceShopifyCheckout?: boolean) => Promise<void>
  useStripeCheckout: ComputedRef<boolean>
}

export default function useShopifyClient(): useShopifyClientReturn {
  const { translateString } = useI18n()
  const { invalidEmailShopifyCheckout, stripeCheckoutUsed, skgCoursePurchaseIntended } = useSegment()
  const { isSachkundeCompany, windowOpenProxyCheck } = useCommonMixin()
  const router = useRouter()
  const route = useRoute()

  const accessToken = process.env.VUE_APP_SHOPIFY_STORE_ACCESS_TOKEN
  const clientDomain = process.env.VUE_APP_SHOPIFY_CLIENT_DOMAIN
  const skgCourses = [
    {
      shortTitle: '34i',
      id: '63d7d5bb-e8e9-429d-b360-cefdecd80448',
    },
    {
      shortTitle: '34i',
      id: '489dd660-9a78-4f94-8076-1e8f20a451e2',
    },
    {
      shortTitle: '34d',
      id: '8de44221-a055-4cdb-8ce5-d7ac148cb49e',
    },
    {
      shortTitle: '34d',
      id: 'ef7607dd-bb67-42f7-b0f8-a0f1a437fdd9',
    },
    {
      shortTitle: '34f',
      id: '84be496a-b904-4184-b456-98736fea9259',
    },
    {
      shortTitle: '34f',
      id: '284424aa-1a0e-4c60-a5e0-d8df6d9d1868',
    },
    {
      shortTitle: 'aevo',
      id: '4dc07be7-7aab-43a0-ae70-4b5f00b5a79e',
    },
    {
      shortTitle: 'aevo',
      id: 'd1643bae-db0b-4778-9662-7080f21ea5e4',
    },
  ]

  const flattenedSkgCourseIds = computed(() => skgCourses.map((c) => c.id))
  const course = computed(() => CourseModule.course)
  const courseBundle = computed(() => CourseModule.courseBundle)
  const inPackageDetails = computed(() => route.name === 'PackageDetails')

  // Initializing a client
  const shopifyClient = Client.buildClient({
    domain: clientDomain,
    storefrontAccessToken: accessToken,
  })

  const useStripeCheckout = computed(() => {
    if (inPackageDetails.value) return !CourseModule.courseBundle?.shopifyProductId
    return !course.value?.shopifyProductId
  })

  const createShopifyCheckout = async (email?: string, shopifyVariantId?: string, enforceShopifyCheckout = false) => {
    const userEmail = email ?? UserModule.email ?? null
    if (useStripeCheckout.value && !enforceShopifyCheckout) {
      await handleStripeCheckout(userEmail)
    } else if (
      isSachkundeCompany.value &&
      flattenedSkgCourseIds.value.includes(course.value?.id ?? '') &&
      !enforceShopifyCheckout
    ) {
      await handleSkgCheckout()
    } else {
      eventBus.$emit('close-modal')
      eventBus.$emit('turn-on-loader')

      const shopifyProductVariantId =
        shopifyVariantId ||
        (inPackageDetails.value ? courseBundle.value?.shopifyProductVariantId : course.value?.shopifyProductVariantId)
      const initialOptions: {
        email?: string
        lineItems: { variantId: string; quantity: number; customAttributes: { key: string; value: string }[] }[]
      } = {
        email: userEmail,
        lineItems: [
          {
            variantId: `gid://shopify/ProductVariant/${shopifyProductVariantId}`,
            quantity: 1,
            customAttributes: [{ key: '_company_id', value: UserModule.currentCompany!.id }],
          },
        ],
      }
      await handleShopifyOrStripeCheckout(initialOptions, userEmail)
    }
  }

  const handleSkgCheckout = async () => {
    const skgCourse = skgCourses.find((c) => c.id === course.value?.id)
    if (skgCourse) {
      await skgCoursePurchaseIntended(UserModule.email, skgCourse.id)
      const url = `https://www.sachkundegurus.de/produkte/${skgCourse.shortTitle}`
      windowOpenProxyCheck(url, 'course_purchase_intended')
    }
  }

  const handleShopifyOrStripeCheckout = async (
    initialOptions: {
      email?: string | undefined
      lineItems: { variantId: string; quantity: number }[]
    },
    userEmail: string,
  ) => {
    try {
      // Create checkout with email included
      const initialCheckout = await shopifyClient.checkout.create(initialOptions)

      setTimeout(() => {
        eventBus.$emit('turn-off-loader')
      }, 2500)

      windowOpenProxyCheck(initialCheckout.webUrl, 'course_purchase_intended')
    } catch (error: any) {
      const errorMessage = JSON.parse(error.message)
      if (
        Array.isArray(errorMessage) &&
        (errorMessage[0].code === 'BAD_DOMAIN' || errorMessage[0].code === 'INVALID')
      ) {
        // remove email from options
        const optionsWithoutEmail = { ...initialOptions }
        delete optionsWithoutEmail.email

        // Create checkout without email
        const checkoutWithoutEmail = await shopifyClient.checkout.create(optionsWithoutEmail)
        if (errorMessage[0].code === 'BAD_DOMAIN') {
          await CommonApi.postSlackMessage(`Invalid email used in shopify checkout. User Email: ${userEmail}`)
          await invalidEmailShopifyCheckout(userEmail)
          eventBus.$toasted.info(translateString('shopifyCheckout.useYourAccountEmail'), { duration: 10000 })
        }

        windowOpenProxyCheck(checkoutWithoutEmail.webUrl, 'course_purchase_intended')
      } else {
        eventBus.$emit('turn-off-loader')
        if (!UserModule.hasValidUser) {
          router.push(`/login?redirect=${route.fullPath}`).catch(() => {
            return
          })
        } else {
          await handleStripeCheckout(userEmail)
        }
        return error
      }
      setTimeout(() => {
        eventBus.$emit('turn-off-loader')
      }, 2500)
    }
  }

  const handleStripeCheckout = async (userEmail: string | null) => {
    const courseIdentifier = inPackageDetails.value
      ? `Course Bundle: ${CourseModule.courseBundle?.courseBundleId || 'N/A'}`
      : `Course: ${CourseModule.course?.id || 'N/A'}`
    const userEmailMessage = userEmail ? `User Email: ${userEmail}. ` : ''
    const message = `Stripe checkout used. ${userEmailMessage}${courseIdentifier}. Current Path: ${route.fullPath}`

    await CommonApi.postSlackMessage(message)
    if (userEmail) await stripeCheckoutUsed(userEmail)
    await navigateStripeCheckoutAction()
  }

  const navigateStripeCheckoutAction = async () => {
    if (route.name === 'PackageDetails') {
      await router.push({ name: 'PackageCheckout' })
    } else if (route.name === 'CourseCheckout' && courseBundle.value) {
      await router.push({ name: 'PackageCheckout', params: { packageId: courseBundle.value!.courseBundleId } })
    } else {
      await router.push({ name: 'CourseCheckout' })
    }
  }

  return {
    useStripeCheckout,
    createShopifyCheckout,
  }
}
