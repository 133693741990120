import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '../index'
import CourseApi from '@/services/api/CourseApi'
import {
  CourseItem,
  CourseModule as CourseModuleState,
  Lesson,
  CourseExamQuestion,
  CourseExamResult,
  CourseExamQuestionAnswered,
  CoursePackage,
  LessonQuestion,
  CourseSection,
  FollowUp,
  Tag,
  GroupCourseItem,
  ExamCompletionState,
} from '@/services/interfaces/Course'
import { Answer, Nullable, QuizCompletion } from '@/services/interfaces/QuizQuestion'
import { UserModule } from './user'
import Vue from 'vue'
import { Question } from '@/services/interfaces/QuizQuestion'

@Module({ name: 'course', store, dynamic: true, namespaced: true })
class Course extends VuexModule {
  public courses: Nullable<CourseItem[]> = null
  public memberCourses: Nullable<CourseItem[]> = null
  public groupCourses: Nullable<CourseItem[]> = null
  public course: Nullable<CourseItem> = null
  public currentLesson: Nullable<Lesson> = null
  public coursesModuleMap: { [key: string]: CourseModuleState[] | undefined } = {}
  public assignedGroupCoursesMap: { [key: string]: GroupCourseItem[] } = {}
  public currentLessonTitle = ''
  public currentCourseGroupId = ''
  public guidedQuizCompletions: { [key: string]: Nullable<QuizCompletion> } = {}
  public examCompletionState: ExamCompletionState = null
  public fetchingExamCompletionState = false
  public timeRemainingInSeconds: number | null = null
  public examResults: CourseExamResult[] = []
  public examResult: CourseExamResult | null = null
  public currentExamQuestion: CourseExamQuestion | null = null
  public courseExamQuestionsAnswered: CourseExamQuestionAnswered[] = []
  public courseBundle: Nullable<CoursePackage> = null
  public courseBundles: Nullable<CoursePackage[]> = null
  public currentLessonQuestions: Nullable<LessonQuestion[]> = null
  public currentCourseModule: Nullable<CourseModuleState> = null
  public courseSections: Nullable<CourseSection[]> = null
  public courseSectionsAssignedCourses: Nullable<CourseItem[]> = null
  public quizQuestions: Nullable<LessonQuestion[]> = null
  public companyFollowups: Nullable<FollowUp[]> = null
  public tags: Tag[] = []

  get courseModules() {
    return (courseId: string, courseGroupId?: string): CourseModuleState[] => {
      if (!this.coursesModuleMap[courseId]) return []
      const courseModules = this.coursesModuleMap[courseId]
      if (courseGroupId) {
        return courseModules!
          .filter((mod) => mod.courseGroupId === courseGroupId)
          .sort((a, b) => {
            return a.courseGroupOrderingNumber - b.courseGroupOrderingNumber
          })
      }
      return courseModules as CourseModuleState[]
    }
  }

  get completedModuleStates() {
    return (courseId: string, courseGroupId?: string): boolean[] => {
      if (!this.coursesModuleMap[courseId]) return []
      return this.courseModules(courseId, courseGroupId).map((courseModule) => {
        return courseModule.lessons.every((lesson) => lesson.completed)
      })
    }
  }

  get numberOfCourseModules() {
    return (courseId: string, courseGroupId?: string): number => {
      if (!this.coursesModuleMap[courseId]) return 0
      return this.courseModules(courseId, courseGroupId).length
    }
  }

  get currentModule() {
    return (courseId: string, moduleId: string): CourseModuleState | null => {
      const courseModules = this.coursesModuleMap[courseId]
      if (!courseModules) return null
      return courseModules.find((mod) => mod.id === moduleId) as CourseModuleState
    }
  }

  get courseGroupModules() {
    return (courseId: string, courseGroupId: string): CourseModuleState[] | null => {
      return this.courseModules(courseId, courseGroupId)
    }
  }

  get lessonModule() {
    return (courseId: string, lessonId: string): CourseModuleState | undefined => {
      return this.courseModules(courseId).find((mod) => mod.lessons.some((l) => l.id === lessonId))
    }
  }

  get totalCourseGroupModules() {
    return (courseId: string, courseGroupIds: string[]): number | null => {
      const courseModules = this.coursesModuleMap[courseId]
      if (!courseGroupIds.length || !courseModules) return courseModules === undefined ? null : courseModules.length
      return courseModules.filter((mod) => courseGroupIds.includes(mod.courseGroupId || '')).length
    }
  }

  get totalCourseGroupLessons() {
    return (courseId: string, courseGroupIds: string[]): number => {
      const courseModules = this.coursesModuleMap[courseId]
      if (!courseGroupIds.length || !courseModules) {
        return (
          courseModules?.reduce((acc, mod) => {
            acc += mod.lessons.length
            return acc
          }, 0) || 0
        )
      }
      return courseModules
        .filter((mod) => courseGroupIds.includes(mod.courseGroupId || ''))
        .reduce((acc, mod) => {
          acc += mod.lessons.length
          return acc
        }, 0)
    }
  }

  get purchasedCourses(): CourseItem[] {
    if (!this.memberCourses) return []
    return this.memberCourses.filter((course) => course.purchased)
  }

  get coursesOwnedbyCompany(): CourseItem[] {
    if (!this.memberCourses) return []
    return this.memberCourses.filter((course) => course.ownedByCompany && course.state === 'ready')
  }

  get externalCourses(): CourseItem[] {
    if (!this.memberCourses) return []
    return this.memberCourses.filter((course) => !course.ownedByCompany && course.state === 'ready')
  }

  get latestExamCompletionId() {
    return this.course?.exam?.latestExamCompletionId || null
  }

  get groupAssignedCourses() {
    return (groupId: string) => this.assignedGroupCoursesMap[groupId] || []
  }

  get examHasSubjects() {
    if (!this.course) return false
    if (!this.course.exam) return false
    return this.course.exam.parts.every((part) => {
      return part.subjects.some((subject) => subject.subjectName)
    })
  }

  get originalQuizQuestions() {
    if (!this.currentLesson?.quiz?.hasQuestions) return []
    return this.currentLesson?.quiz?.questions.map((q: Question) => ({
      id: q.questionId,
      questionType: q.questionType,
      title: q.title,
      comment: q.comment,
      imageUrl: q.imageUrl,
      answers: q.answers.map((a) => ({
        id: a.answerId,
        details: a.answer,
        correct: a.isCorrect,
      })),
    })) as LessonQuestion[]
  }

  get originalLessonAudioFiles() {
    if (!this.currentLesson?.audioFiles) return []
    return this.currentLesson?.audioFiles
  }

  @Mutation
  private SET_COURSES(courses: CourseItem[]) {
    this.courses = courses
  }

  @Mutation
  private SET_MEMBER_COURSES(courses: CourseItem[] | null) {
    this.memberCourses = courses
  }

  @Mutation
  private SET_GROUP_COURSES(courses: CourseItem[] | null) {
    this.groupCourses = courses
  }

  @Mutation
  private SET_COURSE(course: CourseItem | null) {
    this.course = course
  }

  @Mutation
  private SET_COURSE_MODULES(payload: { courseId: string; modules: CourseModuleState[] }) {
    Vue.set(this.coursesModuleMap, payload.courseId, payload.modules)
  }

  @Mutation
  private SET_ASSIGNED_GROUP_COURSES(payload: { groupId: string; courses: CourseItem[] }) {
    Vue.set(
      this.assignedGroupCoursesMap,
      payload.groupId,
      payload.courses.filter((c) => ['ready', 'in_progress'].includes(c.state)),
    )
  }

  @Mutation
  private UPDATE_ASSIGNED_GROUP_COURSES(payload: { groupIds: string[]; course: CourseItem; action: 'add' | 'remove' }) {
    if (payload.action == 'remove') {
      payload.groupIds.forEach((gId) => {
        const courses = this.assignedGroupCoursesMap[gId].filter((c) => c.id !== payload.course.id)
        Vue.set(this.assignedGroupCoursesMap, gId, courses)
      })
    } else if (payload.action === 'add') {
      payload.groupIds.forEach((gId) => {
        const courses = this.assignedGroupCoursesMap[gId]
        courses.push(payload.course)
        Vue.set(this.assignedGroupCoursesMap, gId, courses)
      })
    }
  }

  @Mutation
  private SET_CURRENT_LESSON(lesson: Lesson | null | any) {
    this.currentLesson = lesson
  }

  @Mutation
  private SET_CURRENT_MODULE(module: CourseModuleState | null) {
    this.currentCourseModule = module
  }

  @Mutation
  private SET_LATEST_GUIDED_QUIZ_COMPLETION_ID(payload: {
    courseId: string
    moduleId: string
    completionId: string | null
  }) {
    const courseModules = this.coursesModuleMap[payload.courseId]
    const courseModule = courseModules?.find((mod) => mod.id === payload.moduleId) as CourseModuleState
    if (courseModule) {
      courseModule.guidedQuiz!.latestGuidedQuizCompletionId = payload.completionId
    }
  }

  @Mutation
  private SET_CURRENT_LESSON_TITLE(lessonTitle: string) {
    // to handle the case the user hasn't paid for the course
    this.currentLessonTitle = lessonTitle
  }

  @Mutation
  private SET_CURRENT_COURSE_GROUP(courseGroupId: string) {
    this.currentCourseGroupId = courseGroupId
  }

  @Mutation
  private SET_GUIDED_QUIZ_COMPLETION(payload: { [key: string]: QuizCompletion | null }) {
    const moduleId = Object.keys(payload)[0]
    if (moduleId in this.guidedQuizCompletions) {
      this.guidedQuizCompletions[moduleId] = payload[moduleId]
    } else {
      Vue.set(this.guidedQuizCompletions, moduleId, payload[moduleId])
    }
  }

  @Mutation
  private SET_EXAM_COMPLETION_STATE(status: ExamCompletionState) {
    this.examCompletionState = status
  }

  @Mutation
  private SET_EXAM_TIME_REMAINING(time: number | null) {
    this.timeRemainingInSeconds = time
  }

  @Mutation
  private SET_EXAM_RESULTS(results: CourseExamResult[]) {
    this.examResults = results
  }

  @Mutation
  private SET_MORE_EXAM_RESULTS(results: CourseExamResult[]) {
    this.examResults = this.examResults.concat(results)
  }

  @Mutation
  private SET_EXAM_RESULT(result: CourseExamResult) {
    this.examResult = result
  }

  @Mutation
  private SET_COURSE_EXAM_QUESTION(question: CourseExamQuestion | null) {
    this.currentExamQuestion = question
  }

  @Mutation
  private SET_COURSE_EXAM_QUESTION_ANSWERS(answers: Answer[]) {
    if (!this.currentExamQuestion) return
    this.currentExamQuestion.answers = answers
  }

  @Mutation
  private SET_EXAM_LATEST_COMPLETION_ID(id: string | null) {
    if (!this.course?.exam) return
    this.course.exam.latestExamCompletionId = id
  }

  @Mutation
  private SET_FETCHING_EXAM_COMPLETION_STATE(value: boolean) {
    this.fetchingExamCompletionState = value
  }

  @Mutation
  private SET_COURSE_EXAM_QUESTIONS_ANSWERED(questions: CourseExamQuestionAnswered[]) {
    this.courseExamQuestionsAnswered = questions
  }

  @Mutation
  private SET_COURSE_BUNDLE(bundle: CoursePackage) {
    this.courseBundle = bundle
  }

  @Mutation
  private SET_COURSE_BUNDLES(bundles: CoursePackage[]) {
    this.courseBundles = bundles
  }

  @Mutation
  private SET_CURRENT_LESSON_QUESTIONS(questions: LessonQuestion[] | null) {
    this.currentLessonQuestions = questions
  }

  @Mutation
  private SET_QUIZ_QUESTIONS(questions: LessonQuestion[] | null) {
    this.quizQuestions = questions
  }

  @Mutation
  private SET_COURSE_SECTIONS(sections: CourseSection[] | null) {
    if (!sections) {
      this.courseSections = null
      return
    }
    this.courseSections = sections
      .map((s) => {
        s.description = s.description ?? ''
        s.bulletPoints = s.bulletPoints ?? []
        s.assignedCourses = s.assignedCourses
          ? s.assignedCourses.sort((a, b) => a.courseSectionOrderNumber - b.courseSectionOrderNumber)
          : []
        s.unassignedCourses = s.unassignedCourses ?? []
        return s
      })
      .sort((a, b) => (a.orderingNumber || 0) - (b.orderingNumber || 0))

    this.courseSectionsAssignedCourses = null
    this.courseSectionsAssignedCourses = sections.reduce((acc, section) => {
      section.assignedCourses.forEach((course) => {
        if (course.state !== 'in_progress' && course.visibleToCourseSection && course.visibleToGroup) {
          acc.push(course)
        }
      })
      return acc
    }, [] as CourseItem[])
  }

  @Mutation
  private HIDE_COURSE(payload: { sectionId: string; courseId: string }) {
    const section = this.courseSections!.find((s) => s.id === payload.sectionId)
    const course = section!.assignedCourses.find((c) => (c as CourseItem).id === payload.courseId) as CourseItem
    course.visibleToCourseSection = !course.visibleToCourseSection
  }

  @Mutation
  private PROMOTE_COURSE(payload: { sectionId: string; courseId: string }) {
    const section = this.courseSections!.find((s) => s.id === payload.sectionId)
    const course = section!.assignedCourses.find((c) => (c as CourseItem).id === payload.courseId) as CourseItem
    course.promoted = !course.promoted
  }

  @Mutation
  private SET_COMPANY_FOLLOWUPS(followups: FollowUp[]) {
    this.companyFollowups = followups
  }

  @Mutation
  private SET_TAGS(tags: Tag[]) {
    this.tags = tags
  }

  @Action({ commit: 'SET_COURSES' })
  getCourses() {
    return CourseApi.getCourses({
      userId: UserModule.hasValidUser ? UserModule.id : undefined,
      companyId: UserModule.currentCompany?.id,
    })
      .then((courses) => courses)
      .catch(() => [])
  }

  @Action({ commit: 'SET_MEMBER_COURSES' })
  getMemberCourses(payload: { auth0Id?: string; reset?: boolean; filterDevCourseBundles?: boolean }) {
    if (payload.reset) this.SET_MEMBER_COURSES(null)
    return CourseApi.getCourses({
      userId: UserModule.hasValidUser ? UserModule.id : undefined,
      companyId: UserModule.currentCompany?.id,
      auth0Id: payload.auth0Id,
    })
      .then((courses) => {
        if (!payload.filterDevCourseBundles) {
          return courses.filter((c) => c.courseBundle?.courseBundleId !== '31aa50e8-1c38-47d6-a487-d94507a9dbf2')
        }
        return courses
      })
      .catch(() => [])
  }

  @Action({ commit: 'SET_GROUP_COURSES' })
  getGroupCourses() {
    return CourseApi.getCourses({
      userId: UserModule.hasValidUser ? UserModule.id : undefined,
      companyId: UserModule.currentCompany?.id,
      companyCourses: true,
    })
      .then((courses) => courses)
      .catch(() => [])
  }

  @Action({ commit: 'SET_COURSE' })
  setCourse(course: CourseItem | null) {
    return course
  }

  @Action({ commit: 'SET_COURSE' })
  getCourse(data: { courseId: string }): Promise<CourseItem> {
    this.SET_COURSE(null)
    if (this.courses) {
      const course = this.courses.find((c) => c.id === data.courseId)
      if (course) return new Promise((resolve) => resolve(course))
    }
    const userId = UserModule.hasValidUser ? UserModule.id : undefined
    return CourseApi.getCourseById(data.courseId, userId)
      .then((course) => course)
      .catch(() => ({}) as any)
  }

  @Action({ commit: 'SET_COURSE_MODULES' })
  getCourseModules(courseId: string) {
    return CourseApi.getCourseModules(courseId)
      .then((modules) => ({ courseId, modules }))
      .catch(() => ({ courseId, modules: [] }))
  }

  @Action({ commit: 'SET_ASSIGNED_GROUP_COURSES' })
  getGroupAssignedCourses(groupId: string) {
    return CourseApi.getGroupAssignedCourses(UserModule.currentCompany!.id, groupId)
      .then((courses) => ({ groupId, courses }))
      .catch(() => ({ groupId, courses: [] }))
  }

  @Action({ commit: 'UPDATE_ASSIGNED_GROUP_COURSES' })
  updateGroupAssignedCourses(payload: { groupIds: string[]; course: CourseItem; action: 'add' | 'remove' }) {
    return payload
  }

  @Action
  getLesson(payload: { courseId: string; moduleId: string; lessonId: string }) {
    this.SET_CURRENT_LESSON(null)
    return CourseApi.getLesson(payload.courseId, payload.moduleId, payload.lessonId)
      .then((lesson) => {
        this.SET_CURRENT_LESSON(lesson)
        return lesson
      })
      .catch((err) => {
        this.SET_CURRENT_LESSON({})
        return err
      })
  }

  @Action({ commit: 'SET_GUIDED_QUIZ_COMPLETION' })
  getGuidedQuizCompletion(payload: { courseId: string; moduleId: string }) {
    return CourseApi.getGuidedQuizCompletion(payload.courseId, payload.moduleId)
      .then((data) => ({ [payload.moduleId]: data }))
      .catch(() => ({}))
  }

  @Action({ commit: 'SET_GUIDED_QUIZ_COMPLETION' })
  setGuidedQuizCompletion(payload: { [key: string]: QuizCompletion | null }) {
    return payload
  }

  @Action({ commit: 'SET_CURRENT_LESSON' })
  resetCurrentLesson() {
    return null
  }

  @Action({ commit: 'SET_CURRENT_LESSON' })
  setCurrentLesson(lesson: Lesson) {
    return lesson
  }

  @Action({ commit: 'SET_CURRENT_MODULE' })
  setCurrentModule(module: CourseModuleState | null) {
    return module
  }

  @Action({ commit: 'SET_LATEST_GUIDED_QUIZ_COMPLETION_ID' })
  setLatestGuidedQuizCompletionId(payload: { courseId: string; moduleId: string; completionId: string | null }) {
    return payload
  }

  @Action({ commit: 'SET_CURRENT_LESSON_TITLE' })
  setCurrentLessonTitle(lessonTitle: string) {
    return lessonTitle
  }

  @Action({ commit: 'SET_CURRENT_COURSE_GROUP' })
  setCurrentCourseGroup(id: string) {
    return id
  }

  @Action({ commit: 'SET_FETCHING_EXAM_COMPLETION_STATE' })
  setFetchingExamCompletionState(value: boolean) {
    return value
  }

  @Action({ commit: 'SET_EXAM_LATEST_COMPLETION_ID' })
  resetExamCompletionId() {
    return null
  }

  @Action({ commit: 'SET_QUIZ_QUESTIONS' })
  setQuizQuestions(questions: LessonQuestion[] | null) {
    if (questions) return JSON.parse(JSON.stringify(questions))
    return questions
  }

  @Action
  async getExamCompletionState(completionId: string) {
    this.setFetchingExamCompletionState(true)
    try {
      const data = await CourseApi.getExamCompletionState(this.course!.id, this.course!.exam!.id, completionId)
      if (data.state !== null && ['timedOut', 'completed', 'aborted'].includes(data.state)) {
        this.SET_EXAM_COMPLETION_STATE(null)
        this.SET_EXAM_TIME_REMAINING(null)
      } else {
        this.SET_EXAM_COMPLETION_STATE(data.state)
        this.SET_EXAM_TIME_REMAINING(data.timeRemainingInSeconds)
      }
      this.setFetchingExamCompletionState(false)
      return data
    } catch (err) {
      this.setFetchingExamCompletionState(false)
      return err
    }
  }

  @Action
  getExamResults() {
    this.SET_EXAM_RESULTS([])
    return CourseApi.getExamResults(this.course!.id, this.course!.exam!.id, 1)
      .then((data) => {
        this.SET_EXAM_RESULTS(data.results)
        return data
      })
      .catch((err) => err)
  }

  @Action
  getMoreExamResults(page: number) {
    return CourseApi.getExamResults(this.course!.id, this.course!.exam!.id, page)
      .then((data) => {
        if (data.results) {
          this.SET_MORE_EXAM_RESULTS(data.results)
        }
        return data
      })
      .catch((err) => err)
  }

  @Action
  getExamResult(completionId: string) {
    this.SET_EXAM_RESULT({} as CourseExamResult)
    return CourseApi.getExamResult(this.course!.id, this.course!.exam!.id, completionId)
      .then((data) => {
        this.SET_EXAM_RESULT(data.result)
        return data
      })
      .catch((err) => err)
  }

  @Action
  getNextQuestion(unpauseQuestion: boolean = false) {
    return CourseApi.getNextQuestion(this.course!.id, this.course!.exam!.id, unpauseQuestion)
      .then((data) => {
        if (unpauseQuestion) {
          this.SET_EXAM_COMPLETION_STATE(null)
        }
        this.SET_COURSE_EXAM_QUESTION(data.question)
        return data
      })
      .catch((err) => err)
  }

  @Action
  skipExamQuestion() {
    return CourseApi.skipExamQuestion(this.course!.id, this.course!.exam!.id, {
      examCompletionId: this.currentExamQuestion!.questionNumber === 1 ? null : this.latestExamCompletionId,
      questionId: this.currentExamQuestion!.questionId,
      questionNumber: this.currentExamQuestion!.questionNumber,
      questionNumberOnPart: this.currentExamQuestion!.questionNumberOnPart,
      selectedAnswerIds: [],
      numberAnswer: null,
      partId: this.currentExamQuestion!.partId,
      subjectId: this.currentExamQuestion!.subjectId,
      lessonPickId: this.currentExamQuestion!.lessonPickId,
      skipped: true,
    })
      .then((data) => {
        this.SET_COURSE_EXAM_QUESTION(data.question)
        this.SET_EXAM_LATEST_COMPLETION_ID(data.examCompletionId)
        return data
      })
      .catch((err) => err)
  }

  @Action
  getPreviousQuestion(currentQuestionId: string) {
    return CourseApi.getPreviousQuestion(this.course!.id, this.course!.exam!.id, currentQuestionId)
      .then((data) => {
        this.SET_COURSE_EXAM_QUESTION(data.question)
        return data
      })
      .catch((err) => err)
  }

  @Action
  getExamQuestion(questionId: string) {
    return CourseApi.getExamQuestion(this.course!.id, this.course!.exam!.id, questionId)
      .then((data) => {
        this.SET_COURSE_EXAM_QUESTION(data.question)
        return data
      })
      .catch((err) => err)
  }

  @Action({ commit: 'SET_COURSE_EXAM_QUESTION_ANSWERS' })
  setCurrentExamQuestionAnswers(answers: Answer[]) {
    return answers
  }

  @Action
  pauseExam(timeRemainingInSeconds: number) {
    return CourseApi.pauseExam(
      this.course!.id,
      this.course!.exam!.id,
      this.latestExamCompletionId,
      timeRemainingInSeconds,
    )
      .then(() => {
        this.SET_EXAM_COMPLETION_STATE('paused')
      })
      .catch((err) => err)
  }

  @Action
  async postExamAnswer(payload: {
    selectedAnswerIds: string[]
    state: ExamCompletionState
    numberAnswer: string | null
  }) {
    try {
      const data = await CourseApi.postExamQuestionAnswer(this.course!.id, this.course!.exam!.id, {
        examCompletionId: this.latestExamCompletionId,
        questionId: this.currentExamQuestion!.questionId,
        questionNumber: this.currentExamQuestion!.questionNumber,
        selectedAnswerIds: payload.selectedAnswerIds,
        numberAnswer: payload.numberAnswer || null,
        state: payload.state,
        partId: this.currentExamQuestion!.partId,
        subjectId: this.currentExamQuestion!.subjectId,
        questionNumberOnPart: this.currentExamQuestion!.questionNumberOnPart,
        lessonPickId: this.currentExamQuestion!.lessonPickId ?? null,
      })
      this.SET_EXAM_LATEST_COMPLETION_ID(data.examCompletionId)
      if (payload.state === 'timedOut') {
        this.SET_EXAM_COMPLETION_STATE('timedOut')
        this.SET_EXAM_TIME_REMAINING(null)
      }
      return data
    } catch (err) {
      return err
    }
  }

  @Action
  abortExam(payload: { selectedAnswerIds: string[] }) {
    return CourseApi.postExamQuestionAnswer(this.course!.id, this.course!.exam!.id, {
      selectedAnswerIds: payload.selectedAnswerIds,
      numberAnswer: null,
      state: 'aborted',
      examCompletionId: this.latestExamCompletionId,
      lessonPickId: '',
    })
      .then((data) => {
        this.SET_EXAM_LATEST_COMPLETION_ID(null)
        this.SET_EXAM_COMPLETION_STATE(null)
        this.SET_EXAM_TIME_REMAINING(null)
        return data
      })
      .catch((err) => err)
  }

  @Action({ commit: 'SET_COURSE_EXAM_QUESTION' })
  resetCurrentExamQuestion() {
    return null
  }

  @Action
  getCourseExamQuestionsAnswered(completionId: string) {
    return CourseApi.getCourseExamQuestionsAnswered(this.course!.id, this.course!.exam!.id, completionId)
      .then((data) => {
        this.SET_COURSE_EXAM_QUESTIONS_ANSWERED(data.questions)
      })
      .catch((err) => err)
  }

  @Action
  getCourseExamAnsweredQuestion(payload: { questionId: string; completionId: string }) {
    return CourseApi.getCourseExamAnsweredQuestion(
      this.course!.id,
      this.course!.exam!.id,
      payload.completionId,
      payload.questionId,
    )
      .then((data) => {
        this.SET_COURSE_EXAM_QUESTION(data.question)
      })
      .catch((err) => err)
  }

  @Action({ commit: 'SET_COURSE_BUNDLE' })
  setCourseBundle(bundle: CoursePackage | null) {
    return bundle
  }

  @Action({ commit: 'SET_COURSE_BUNDLE' })
  getPackageBundle(packageId: string) {
    return CourseApi.getPackageBundle(packageId)
      .then((courseBundle) => courseBundle)
      .catch(() => ({}))
  }

  @Action({ commit: 'SET_COURSE_BUNDLES' })
  getPackageBundles() {
    return CourseApi.getCourseBundles()
      .then((courseBundles) => courseBundles)
      .catch(() => [])
  }

  @Action({ commit: 'SET_CURRENT_LESSON_QUESTIONS' })
  getCurrentLessonQuestions(payload: { courseId: string; moduleId: string; lessonId: string }) {
    this.SET_CURRENT_LESSON_QUESTIONS(null)
    return CourseApi.getCurrentLessonQuestions(payload.courseId, payload.moduleId, payload.lessonId)
      .then((questions) => questions)
      .catch(() => [])
  }

  @Action({ commit: 'SET_CURRENT_LESSON_QUESTIONS' })
  setCurrentLessonQuestions(questions: LessonQuestion[]) {
    this.SET_CURRENT_LESSON_QUESTIONS(null)
    return questions
  }

  @Action({ commit: 'SET_COURSE_SECTIONS' })
  getCourseSections(companyId: string) {
    return CourseApi.getCourseSections(companyId)
      .then((data) => data.sections)
      .catch(() => [])
  }

  @Action
  hideSectionCourse(payload: { sectionId: string; courseId: string; visible: boolean }) {
    this.HIDE_COURSE(payload)
    return CourseApi.updateCompanyAssignedCourse(UserModule.currentCompany!.id, payload.sectionId, payload.courseId, {
      visible: payload.visible,
    }).catch(() => null)
  }

  @Action
  promoteSectionCourse(payload: { sectionId: string; courseId: string; promoted: boolean }) {
    this.PROMOTE_COURSE(payload)
    return CourseApi.updateCompanyAssignedCourse(UserModule.currentCompany!.id, payload.sectionId, payload.courseId, {
      promoted: payload.promoted,
    }).catch(() => null)
  }

  @Action({ commit: 'SET_COMPANY_FOLLOWUPS' })
  getFollowups() {
    return CourseApi.getFollowups()
      .then((followups) => followups)
      .catch(() => [])
  }

  @Action({ commit: 'SET_TAGS' })
  getTags() {
    return CourseApi.getTags()
      .then((resp) => resp.tags)
      .catch(() => [])
  }

  @Action({ commit: 'SET_PACKAGE_SHOPIFY_PRODUCT_AMOUNT' })
  setPackageShopifyProductAmount(productAmount: number) {
    return productAmount
  }
}

export const CourseModule = getModule(Course)
