<template lang="pug">
.progress-wrapper
    VPopover
        .progress-container
            .level-points
                h6 {{ currentLevelName }}
                h6 {{ totalPoints }}P
            UserLevelPointsProgressBar(
                :percentage='progressPercentage',
                :show-new-points-progress='showNewPointsEarnedIndicator'
            )
        template(#popover)
            .hover-description-content
                .title-rank
                    h6.title(v-html='tooltipHeaderContent')
                    .rank(v-if='showCompanyRank')
                        .placement-text(v-html='placementText')
                        h2.actual-rank {{ '#' + actualUserRank }}
                h6.description {{ $t('navigation.gamificationPoints.description') }}
    h6.points-earned-indicator(
        v-tooltip.bottom='{ content: $t("pointsEarned", { points: pointsEarned }), show: showNewPointsEarnedIndicator, trigger: "manual" }'
    )
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import UserLevelPointsProgressBar from '@/components/common/UserLevelPointsProgressBar.vue'
  import { UserModule } from '@/store/modules/user'
  import { VPopover } from 'v-tooltip'
  import useGamification from '@/composables/useGamification'
  import useI18n from '@/composables/useI18n'

  const { userStats, totalPoints, currentLevel } = useGamification()
  const { translateString } = useI18n()
  const showNewPointsEarnedIndicator = ref(false)

  const companyName = computed(() => UserModule.currentCompany?.name)

  const pointsEarned = computed(() => {
    return UserModule.userGamificationPointsEarned
  })

  const currentLevelName = computed(() => {
    return currentLevel.value?.name
  })

  const previousLevelMaxPoints = computed(() => userStats.value?.previousLevelMaxPoints ?? 0)

  const currentLevelMaxPoint = computed(() => {
    return userStats.value?.level.maximumPoints ?? 0
  })

  const progressPercentage = computed(() => {
    const levelMaxPointDifferent = currentLevelMaxPoint.value - previousLevelMaxPoints.value
    return ((totalPoints.value - previousLevelMaxPoints.value) / levelMaxPointDifferent) * 100
  })

  const tooltipHeaderContent = computed(() => {
    return translateString('navigation.gamificationPoints.title', {
      totalPoints: totalPoints.value,
      nextLevelPoints: currentLevelMaxPoint.value,
    })
  })

  const placementText = computed(
    () => `${translateString('navigation.gamificationPoints.yourPlacementIn')} </br> <b>${companyName.value}</b>`,
  )

  const actualUserRank = computed(() => {
    if (!UserModule.userGamificationStats?.rank) return 0
    return UserModule.userGamificationStats.rank
  })

  const showCompanyRank = computed(() => {
    if (!UserModule.currentCompany?.showGamificationRank) return false
    return UserModule.currentCompany?.showGamificationRank && actualUserRank.value
  })

  const startTimer = () => {
    showNewPointsEarnedIndicator.value = true
    window.setTimeout(() => {
      showNewPointsEarnedIndicator.value = false
    }, 5000)
  }

  watch(pointsEarned, (points) => {
    if (points > 0) {
      startTimer()
    }
  })
</script>

<style lang="postcss">
  .progress-wrapper {
    .progress-container {
      @apply ketch-relative ketch-flex ketch-flex-col ketch-space-y-c8;
      .level-points {
        @apply ketch-flex ketch-items-center ketch-justify-between;
        h6 {
          @apply ketch-text-header-foreground-color;
        }
      }
    }
  }
  .hover-description-content {
    @apply ketch-flex ketch-flex-col ketch-space-y-c5;
    .title-rank {
      @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-pb-[7px] ketch-border-b ketch-border-secondary-text-color;
      .rank {
        @apply ketch-bg-white ketch-py-c5 ketch-rounded-normal ketch-flex ketch-flex-col ketch-items-center;
        > * {
          @apply ketch-text-black;
        }
        .placement-text {
          @apply ketch-text-[10px] ketch-leading-[13px] ketch-text-center;
        }
        .actual-rank {
          @apply ketch-font-bold md:ketch-text-[22px];
        }
      }
    }
    .description {
      @apply ketch-text-secondary-text-color;
    }
  }
</style>
