<template lang="pug">
.lesson-slideshow
    .dropdown-pagination(v-if='!onePager')
        Dropdown(
            :items='dropdownItems',
            :value='currentPage - 1',
            @input='currentPage = $event + 1',
            default-dropdown-behavior,
            stroke-color-for-expand-icon='var(--primary-text-color)'
        )
        .slide-pagination
            Pagination(
                :current-page='currentPage',
                :total-pages='slides.length',
                @next='currentPage += 1',
                @page='currentPage = $event',
                @previous='currentPage -= 1'
            )
    transition(
        :name='onePager ? "" : "fade"',
        mode='out-in'
    )
        .slide-content-container(
            :class='{ "one-pager": onePager }',
            :key='content.key',
            v-if='hasSlides'
        )
            .content-area(
                :key='index',
                v-for='(currentSlide, index) in content.slides'
            )
                template(v-if='currentSlide')
                    HTMLRenderer.h3(
                        :format-content='formatContent',
                        :html='currentSlide.title',
                        v-if='onePager'
                    )
                    .slide-image(v-if='currentSlide.imageUrl')
                        PlaceholderShimmer(
                            :animate='true',
                            height='400px'
                        )
                            template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
                                img.ketch-shadow-content-image-shadow(
                                    :class='{ "shimmer-asset": isLoadingResource }',
                                    :src='currentSlide.imageUrl',
                                    @click='zoomIn(onePager ? index + 1 : currentPage)',
                                    @load='onResourceLoaded'
                                )
                    .slide-video(v-else-if='currentSlide.videoUrl')
                        VideoOrImageRenderer(
                            :thumbnail-timestamp='currentSlide.thumbnailTimestamp',
                            :video-url='currentSlide.videoUrl || ""',
                            file-type,
                            video-only
                        )
                        VideoTranscript(
                            :video-transcript='currentSlide.videoTranscript || ""',
                            v-if='currentSlide.videoTranscript'
                        )
                    HTMLRenderer.credits.h6(
                        :format-content='formatContent',
                        :html='currentSlide.credits || ""',
                        link-target='_blank'
                    )
                    AuthorAudioSection(
                        :audio-author='currentSlide.audioAuthor',
                        :audio-source='currentSlide.audioUrl',
                        v-if='currentSlide.audioUrl'
                    )
                    .slide-content
                        HTMLRenderer.slide-title(
                            :format-content='formatContent',
                            :html='currentSlide.title || ""',
                            v-if='!onePager'
                        )
                        HTMLRenderer(
                            :format-content='formatContent',
                            :html='currentSlide.description || ""',
                            link-target='_blank'
                        )
                    hr(v-if='onePager')
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import Pagination from '@/components/common/Pagination.vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import AuthorAudioSection from '@/components/course/AuthorAudioSection.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import VideoOrImageRenderer from '@/components/course/VideoOrImageRenderer.vue'
  import Dropdown from '@/components/common/Dropdown.vue'
  import VideoTranscript from '@/components/course/VideoTranscript.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import eventBus from '@/main'
  import type { PropType } from 'vue'
  import type { Slideshow } from '@/services/interfaces/Course'

  const props = defineProps({
    slides: {
      type: Array as PropType<Slideshow[]>,
      required: true,
    },
    onePager: {
      type: Boolean,
      default: false,
    },
    formatContent: {
      type: Boolean,
      default: false,
    },
  })

  const currentPage = ref(1)

  const dropdownItems = computed(() => {
    return props.slides.map((slide, index) => ({
      type: index,
      title: slide.title,
      selectable: true,
    }))
  })

  const currentSlide = computed(() => props.slides[currentPage.value - 1])

  const content = computed(() => {
    const data = {
      key: currentPage.value,
      slides: [currentSlide.value],
    }
    if (props.onePager) {
      data.slides = props.slides
    }
    return data
  })

  const hasSlides = computed(() => !!content.value.slides && content.value.slides.length > 0)

  const { current } = useBreakpoint()
  const zoomIn = (pageNumber: number) => {
    if (['xxl', 'xxxl'].includes(current.value)) {
      eventBus.$emit('show-modal', {
        modalContentComponent: 'LessonSlideshowModal',
        modalProps: {
          slides: props.slides,
          pageNumber,
        },
        cssClass: 'slideshow-modal',
        modalCloseCallback: (callback: () => void) => {
          if (typeof callback === 'function') callback()
        },
      })
    }
  }
</script>

<style lang="postcss">
  .lesson-slideshow {
    .dropdown-pagination {
      @apply ketch-flex ketch-flex-col sm:ketch-flex-row sm:ketch-items-center sm:ketch-justify-between;
      @apply ketch-pb-c25 ketch-border-b ketch-border-dashed ketch-border-border-color sm:ketch-space-x-c40;
      @apply ketch-space-y-c20 sm:ketch-space-y-0;
      .slide-pagination {
        @apply ketch-flex-auto;
      }
      .dropdown {
        @apply sm:ketch-w-[40%];
        @screen sm {
          flex: 0 0 40%;
        }
        .selected-item {
          @apply ketch-border-primary-text-color;
        }
        h5 {
          @apply ketch-text-ellipsis ketch-overflow-hidden;
        }
        .dropdown-items .item {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .content-area {
      hr {
        @apply ketch-border-border-color ketch-my-c30;
      }
      > h3 {
        @apply ketch-font-bold;
      }
      .slide-image,
      .slide-video {
        @apply ketch-py-c30;
      }
      .slide-video {
        @apply ketch-space-y-c30;
      }
      .slide-image {
        .placeholder-shimmer .shimmer-wrapper {
          @apply ketch-flex ketch-justify-center;
        }
        img {
          @apply ketch-object-cover xl:hover:ketch-cursor-[zoom-in];
        }
      }
      .credits {
        @apply ketch--mt-c5 ketch-mb-c30;
      }
      .author-audio-section {
        @apply ketch-mb-c55;
      }
      .slide-content {
        .slide-title {
          @apply ketch-mb-c20 ketch-font-bold;
        }
      }
      &:last-of-type {
        hr {
          @apply ketch-hidden;
        }
      }
    }
  }
</style>
