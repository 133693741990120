<template lang="pug">
.package-info
    LoadingSpinner(v-if='loadingPackage')
    template(v-else)
        .details
            .item-description
                h2 {{ courseBundle.title }}
                HTMLRenderer(
                    :html='courseBundle.description',
                    :increase-padding='true',
                    link-target='_blank'
                )
            .sidebar
                PackagePriceSection(:product-amount='productAmount')
        PackageCoursesSlider(:courses='courseBundle.courses')
</template>

<script setup lang="ts">
  import { computed, onMounted } from 'vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import useCourse from '@/composables/useCourse'
  import PackageCoursesSlider from '@/components/course/PackageCoursesSlider.vue'
  import { CourseModule } from '@/store/modules/course'
  import PackagePriceSection from '@/components/course/PackagePriceSection.vue'
  import useShopifyClient from '@/composables/useShopifyClient'

  const { packageId, courseBundle, fetchAllCourseModules } = useCourse()
  const { useStripeCheckout } = useShopifyClient()

  const loadingPackage = computed(() => courseBundle.value === null)

  const productAmount = computed(() =>
    useStripeCheckout.value ? (courseBundle.value?.product.price.amount ?? 0) : courseBundle.value?.shopifyProductPrice,
  )

  CourseModule.getPackageBundle(packageId.value).finally(() => {
    fetchAllCourseModules(courseBundle.value!.courses.map((course) => course.id))
  })

  onMounted(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  })
</script>

<style lang="postcss" scoped>
  .package-info {
    @apply ketch-mt-c20 ketch-space-y-c50 md:ketch-mt-0;
    .details {
      @apply ketch-flex ketch-flex-col-reverse ketch-space-y-c30 ketch-space-y-reverse;
      @apply md:ketch-flex-row md:ketch-space-y-0;
      .item-description {
        @apply ketch-flex-auto md:ketch-mr-c30 xl:ketch-mr-c50;
        > h2 {
          @apply ketch-font-bold ketch-mb-c30;
        }
      }
      .sidebar {
        @screen md {
          flex: 0 0 300px;
        }
        @screen xl {
          flex: 0 0 350px;
        }
      }
    }
  }
</style>
