<template lang="pug">
LoadingSpinner(v-if='fetchingData')
.checkout-success(
    data-cy='checkout-success-page',
    v-else
)
    .copy
        .icon
            SVGRenderer(
                :has-hover='false',
                :icon='diamondIcon',
                stroke-color='var(--primary-color)'
            )
        h1.title {{ $t('checkout.success.courseUnlocked') }}
        h5.description(v-html='$t("checkout.success.courseDescription")')
        template(v-if='isOneOffCheckout')
            img.teaser-image(
                :src='teaserImageUrl',
                @click='toCourseDetails'
            )
            KetchUpButton.tertiary(@click.native='toCourseDetails')
                h5 {{ $t('checkout.success.startCourseNow') }}
                img(:src='arrowRight')
    PackageCoursesSlider(
        :courses='courseBundle.courses',
        v-if='courseBundle && !isOneOffCheckout'
    )
</template>

<script setup lang="ts">
  import useIcons from '@/composables/useIcons'
  import { computed, onMounted, ref } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import { CourseModule } from '@/store/modules/course'
  import useSegment from '@/composables/useSegment'
  import useCourse from '@/composables/useCourse'
  import PackageCoursesSlider from '@/components/course/PackageCoursesSlider.vue'
  import useBreakpoint from '@/composables/useBreakpoint'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import { useRoute } from 'vue-router/composables'

  const route = useRoute()
  const { diamondIcon, arrowRight } = useIcons()
  const { trackPage, pageViewed } = useSegment()
  const { fetchAllCourseModules, packageId, currentCourseId, firstCourseModule } = useCourse()
  const { isMobileDevice } = useBreakpoint()
  const fetchingData = ref(false)

  const toCourseDetails = () => {
    const moduleId = firstCourseModule.value(currentCourseId.value)?.id || ''
    window.location.href = `/courses/${currentCourseId.value}/module/${moduleId}/start`
  }

  const isOneOffCheckout = computed(() => route.name === 'OneOffCheckoutSuccess')
  const isPackageCheckout = computed(() => route.name === 'PackageCheckoutSuccess')
  const courseBundle = computed(() => CourseModule.courseBundle)

  const fetchCoursesAndModules = async () => {
    if (isPackageCheckout.value) {
      if (CourseModule.courseBundle) {
        fetchAllCourseModules(CourseModule.courseBundle.courses.map((course) => course.id))
      } else {
        await CourseModule.getPackageBundle(packageId.value)
      }
    } else {
      if (!CourseModule.course) {
        await CourseModule.getCourse({ courseId: currentCourseId.value })
      }
      fetchAllCourseModules([CourseModule.course!.id])
    }
    fetchingData.value = false
  }

  fetchingData.value = true
  fetchCoursesAndModules()

  const teaserImageUrl = computed(() => {
    return isMobileDevice.value ? CourseModule.course?.cardTeaserImageUrl : CourseModule.course?.teaserImageUrl
  })

  onMounted(() => {
    trackPage('Success Checkout')
    pageViewed('Success Checkout')
  })
</script>

<style lang="postcss" scoped>
  .checkout-success {
    .copy {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-mt-c60;
      @apply md:ketch-mt-0 ketch-mb-c30 md:ketch-mb-[85px];
      .icon {
        @apply ketch-w-c30;
      }
      .title {
        @apply ketch-font-big-daily-short ketch-text-3xl ketch-leading-3xl ketch-mt-c15;
      }
      .description {
        @apply sm:ketch-w-c600 ketch-text-center ketch-mt-c30;
        span {
          @apply ketch-font-bold;
        }
      }
      .teaser-image {
        @apply ketch-mb-c40 ketch-max-w-[650px] ketch-cursor-pointer ketch-mt-c50;
      }
      button {
        @apply ketch-w-auto;
        h5 {
          @apply ketch-font-normal;
        }
      }
    }
  }
</style>
