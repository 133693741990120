<template lang="pug">
.group-management
    OverviewActionBar(
        :count='totalGroups',
        :text='$t("homepage.groupsInYourAcademy")'
    )
        .add-groups
            KetchUpButton.primary(
                :disabled='hasNewGroup',
                @click.native='addNewGroup'
            )
                h5 {{ $t('homepage.createGroup') }}
                SVGRenderer(
                    :has-hover='false',
                    :icon='plusVectorIcon',
                    :stroke-color='"var(--primary-foreground-color)"',
                    width='12'
                )
    .group-management-container(ref='container')
        .group-management-container__group(
            :class='{ new: group.id === "", "all-users": group.name === "All Users" }',
            :key='group.id',
            v-for='group in userGroups'
        )
            .group--wrapper
                .title-description(
                    :class='{ editing: [editingKey, ""].includes(group.id) }',
                    @click='editingKey = group.id'
                )
                    .title-section
                        .left-side
                            .color(
                                :style='{ "background-color": groupBgColor(group.colorId) }',
                                v-if='group.colorId'
                            )
                            TextRenderer.title(
                                :allow-edit='[editingKey, ""].includes(group.id) ? "yes" : "no"',
                                :editor-state='`group-name-${group.id}`',
                                :mutate-and-queue-func='mutateAndQueueFunc(group.id)',
                                :placeholder='$t("homepage.addGroup.placeholder")',
                                :show-text-input='true',
                                :source='group.name || ""',
                                editor-property='groupName'
                            )
                            template(v-if='editingKey !== group.id && specificGroupUsersCount(group.id)')
                                h5.total-users [{{ specificGroupUsersCount(group.id) }}]
                                h6.badge(v-if='group.name === "All Users"') SYSTEM
                        SVGRenderer(
                            :fill-color='"var(--primary-color)"',
                            :has-hover='false',
                            :icon='pencilIcon'
                        )
                    TextRenderer.description(
                        :allow-edit='[editingKey, ""].includes(group.id) ? "yes" : "no"',
                        :editor-state='`group-description-${group.id}`',
                        :mutate-and-queue-func='mutateAndQueueFunc(group.id)',
                        :placeholder='$t("homepage.newGroupDescPlaceholder")',
                        :source='group.description || ""',
                        editor-property='description',
                        show-extra-text-tools,
                        show-toolbar-text-block-only
                    )
                    .buttons(v-if='[editingKey, ""].includes(group.id)')
                        KetchUpButton.quaternary(@click.native.stop='onCancel(group.id)')
                            h5 {{ $t('cancel') }}
                        KetchUpButton.primary(
                            :disabled='isSubmitting || !(payload[group.id] && (payload[group.id].groupName || group.name))',
                            @click.native.stop='onSubmit(group)'
                        )
                            LoadingSpinner(v-if='isSubmitting')
                            SVGRenderer(
                                :fill-color='"var(--primary-color)"',
                                :has-hover='false',
                                :icon='checkmark',
                                v-else,
                                width='16'
                            )
                h6.course-access(
                    @click.stop='openAssignCoursesToGroupModal(group)',
                    v-if='![editingKey, ""].includes(group.id)'
                ) {{ $t('homepage.accessToCourses', { totalCourses: totalCourses(group) }) }}
            .user-table-wrapper-pagination-cta(v-if='groupUsers(group.id)')
                UserTable(
                    :no-users-text='$t("homepage.noUsersInGroup")',
                    :selected-user='null',
                    :users='groupUsers(group.id)'
                )
                LoadingSpinner(v-if='loadingMoreUsers')
                .load-more-or-all(v-else-if='!loadedAllGroupUsers(group.id) && xMoreUsersToLoad(group.id) > 0')
                    h5
                        span(@click='loadMoreGroupUsers(group.id)') {{ loadXMore(group.id) }}
                        span {{ $t('or') }}
                        span(@click='loadAllGroupUsers(group.id)') {{ $t('loadAll') }}
            .user-table(v-else)
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import OverviewActionBar from '@/components/user-management/OverviewActionBar.vue'
  import UserTable from '@/components/user-management/UserTable.vue'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import { CourseModule } from '@/store/modules/course'
  import UsersApi from '@/services/api/UsersApi'
  import * as Sentry from '@sentry/vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { MemberGroup } from '@/services/interfaces/Auth0'
  import type { EditorStatePayload } from '@/services/interfaces/Course'

  const { plusVectorIcon, pencilIcon, checkmark } = useIcons()
  const { groupBgColor, userMembersPerPageLimit } = useCommonMixin()
  const { translateString } = useI18n()
  const editingKey = ref('')
  const loadingMoreUsers = ref(false)

  const userGroups = computed(() => UserModule.userGroups || [])

  const hasNewGroup = computed(() => userGroups.value.some((g) => g.id === ''))

  const allUserGroupMembers = computed(() => UserModule.userMembers?.groups)

  const totalGroups = computed(() => UserModule.userGroups?.length ?? 0)

  const specificGroupMembersResponse = computed(() => (groupId: string) => {
    return allUserGroupMembers.value?.find((g) => g.groupId === groupId)
  })

  const specificGroupTotalUsersCount = computed(() => (groupId: string) => {
    if (!specificGroupMembersResponse.value(groupId)) return 0
    return specificGroupMembersResponse.value(groupId)?.totalUsers ?? 0
  })

  const specificGroupUsersCount = computed(() => (groupId: string) => {
    if (!specificGroupMembersResponse.value(groupId)) return 0
    return specificGroupMembersResponse.value(groupId)?.users.length ?? 0
  })

  const loadedAllGroupUsers = computed(() => (groupId: string) => {
    return specificGroupTotalUsersCount.value(groupId) === specificGroupUsersCount.value(groupId)
  })

  const xMoreUsersToLoad = computed(() => (groupId: string) => {
    return specificGroupTotalUsersCount.value(groupId) - specificGroupUsersCount.value(groupId) >
      userMembersPerPageLimit.value
      ? userMembersPerPageLimit.value
      : specificGroupTotalUsersCount.value(groupId) - specificGroupUsersCount.value(groupId)
  })

  const loadXMore = computed(
    () => (groupId: string) => translateString('loadXMore', { number: xMoreUsersToLoad.value(groupId) }),
  )

  const groupUsers = computed(() => (groupId: string) => specificGroupMembersResponse.value(groupId)?.users ?? [])

  const totalCourses = computed(() => (group: MemberGroup) => CourseModule.groupAssignedCourses(group.id).length)

  const onCancel = (key: string) => {
    if (key === '') {
      const index = UserModule.userGroups?.findIndex((g) => g.id === key)
      if (index && index > -1) {
        UserModule.userGroups?.splice(index, 1)
      }
      return
    } else {
      editingKey.value = ''
    }
  }

  const groupColor = computed(() => {
    return (index: number) => {
      const colors = UserModule.groupColors
      if (!colors) return { id: '' }
      return colors[index % colors.length]
    }
  })

  const isSubmitting = ref(false)
  const onSubmit = async (group: MemberGroup) => {
    if (group.name === 'All  Users') return
    isSubmitting.value = true

    const data = payload.value[group.id]
    try {
      if (group.id === '') {
        Object.assign(data, { id: undefined })
        await UsersApi.createMemberGroup(UserModule.currentCompany!.id, data)
      } else {
        if (!data.groupName) {
          data.groupName = (data as any).name
        }
        Object.assign(data, { name: undefined })
        await UsersApi.updateMemberGroup(UserModule.currentCompany!.id, data)
        editingKey.value = ''
      }
      eventBus.$delete(payload.value, group.id)
      await UserModule.getMembersGroups()
      await UserModule.getCompanyActivityLog()
    } catch (error) {
      Sentry.captureException(error)
    }
    isSubmitting.value = false
  }

  const payload = ref<{ [key: string]: { id: string; groupName: string; description?: string; colorId?: string } }>({})

  const mutateAndQueueFunc = (groupId: string) => {
    return async (data: EditorStatePayload) => {
      const currentData: any = payload.value[groupId] ?? userGroups.value.find((g) => g.id === groupId)

      eventBus.$set(payload.value, groupId, {
        ...currentData,
        id: groupId,
        [data.property as string]: data.value,
      })
    }
  }

  const container = ref<any>(null)
  const addNewGroup = () => {
    if (!UserModule.userGroups?.some((g) => g.id === '')) {
      UserModule.userGroups?.push({
        id: '',
        name: '',
        description: '',
        colorId: groupColor.value(UserModule.userGroups.length).id,
      })
      setTimeout(() => {
        const el = container.value?.querySelector('.group-management-container__group.new') as HTMLDivElement
        if (el) {
          window.scroll({
            top: el.offsetTop,
            behavior: 'smooth',
          })
          container.value?.querySelector('.group-management-container__group.new .title input')?.focus()
        }
      }, 1)
    }
  }

  const openAssignCoursesToGroupModal = (group: MemberGroup) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'AssignGroupCourseModal',
      modalProps: {
        group,
      },
      cssClass: 'assign-group-course-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const loadMoreGroupUsers = (groupId: string) => {
    if (specificGroupMembersResponse.value(groupId)) {
      loadingMoreUsers.value = true
      UserModule.loadMoreUsers({
        perPage: userMembersPerPageLimit.value,
        page: specificGroupMembersResponse.value(groupId)!.currentPage + 1,
        groups: [groupId],
        userCategory: 'groups',
      }).finally(() => (loadingMoreUsers.value = false))
    }
  }

  const loadAllGroupUsers = (groupId: string) => {
    if (specificGroupMembersResponse.value(groupId)) {
      loadingMoreUsers.value = true
      UserModule.loadMoreUsers({
        perPage: userMembersPerPageLimit.value,
        page: specificGroupMembersResponse.value(groupId)!.currentPage + 1,
        groups: [groupId],
        userCategory: 'groups',
        loadAll: true,
      }).finally(() => (loadingMoreUsers.value = false))
    }
  }
</script>

<style lang="postcss">
  .group-management {
    &-container {
      @apply ketch-flex ketch-flex-col ketch-space-y-c40 ketch-mt-c40;
      &__group {
        @apply ketch-flex ketch-justify-between ketch-pb-c40 ketch-border-b ketch-border-dashed;
        @apply ketch-border-border-color;
        &:last-of-type {
          @apply ketch-border-0 ketch-pb-0;
        }
        &.all-users .title-description {
          @apply ketch-pointer-events-none ketch-cursor-auto;
        }
        .group--wrapper {
          @apply ketch-flex-auto ketch-pr-c80;
          .title-description {
            &:not(.editing):hover {
              @apply ketch--mx-c20 ketch--my-c20 ketch-px-c20 ketch-py-c20 ketch-cursor-pointer;
              @apply ketch-h-[fit-content] ketch-bg-white ketch-rounded-normal;
              .title-section {
                svg {
                  @apply ketch-block;
                }
              }
            }
            &.editing {
              .left-side {
                @apply ketch-flex-1;
                .title {
                  @apply ketch-w-full;
                  input {
                    @apply ketch-rounded-normal;
                  }
                }
              }
              .ck-outside-header .right {
                @apply ketch-hidden;
              }
              .description .ck-editor .ck-content {
                @apply ketch-min-h-[100px];
              }
              .buttons {
                @apply ketch-flex ketch-justify-end ketch-items-center ketch-space-x-c15 ketch-mt-c15;
                button {
                  @apply ketch-w-auto;
                  svg {
                    @apply ketch-w-c16;
                  }
                  &.quaternary {
                    @apply ketch-p-0;
                  }
                }
              }
            }
            .title-section {
              @apply ketch-flex ketch-justify-between ketch-mb-c10;
              .left-side {
                @apply ketch-flex ketch-items-center ketch-space-x-c15;
              }
              .color {
                @apply ketch-w-[20px] ketch-h-[20px];
                flex: 0 0 20px;
              }
              .title h2 {
                @apply ketch-leading-sm ketch-text-sm ketch-font-bold;
              }

              .badge {
                @apply ketch-text-black ketch-text-opacity-[0.3] ketch-bg-black ketch-bg-opacity-[0.03];
                @apply ketch-border ketch-border-[#d9d9d9] ketch-rounded-[5px] ketch-text-[10px] ketch-leading-xs1;
                @apply ketch-px-c8 ketch-flex ketch-items-center;
              }
              svg {
                @apply ketch-hidden;
              }
            }
          }
          .course-access {
            @apply ketch-mt-c40 ketch-underline ketch-cursor-pointer ketch-inline-block;
          }
        }
      }
    }
  }
</style>
