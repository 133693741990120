<template lang="pug">
.company-header-section-wrapper.component-spacing
    .company-header-section
        .company-headline-text
            h1.headline-title {{ headerTitle }}
            h4.header-subtext {{ headerSubtitle }}
            .company-socials(v-if='companySocialProperties && isDesktopDevice')
                .social-icons(v-if='companySocialProperties.icons')
                    .social-icon(
                        :key='icon.sourceUrl',
                        v-for='icon in companySocialProperties.icons'
                    )
                        a.icon-link(
                            :href='icon.sourceUrl',
                            @click='trackCompanySocialClick(icon.name)',
                            target='_blank',
                            v-if='icon.sourceUrl'
                        )
                            img(:src='displayIcon(icon.name)')
                .socials-description(v-if='companySocialProperties.description')
                    SVGRenderer(
                        :fill-color='"var(--header-foreground-color)"',
                        :has-hover='false',
                        :icon='curvedArrowLeft',
                        v-if='!isSmallerMobileDevice'
                    )
                    h3.description {{ companySocialProperties.description }}
        .company-header-image(v-if='isDesktopDevice')
            img(:src='companyImageUrl')
    GoogleReviewWidget(
        :header='true',
        v-if='isDesktopDevice'
    )
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import useSegment from '@/composables/useSegment'
  import useBreakpoint from '@/composables/useBreakpoint'
  import GoogleReviewWidget from '@/components/GoogleReviewWidget.vue'
  import type { Socials } from '@/services/interfaces/Auth0'
  import CookieHelper from '@/helpers/CookieHelper'

  const { curvedArrowLeft } = useIcons()
  const { socialMediaClicked } = useSegment()
  const { isSmallerMobileDevice, isDesktopDevice } = useBreakpoint()

  const companyImageUrl = computed(() => {
    return CookieHelper.getCookieValue('headerImageUrl')
  })

  const headerTitle = computed(() => UserModule.currentCompany?.headerTitle)
  const headerSubtitle = computed(() => UserModule.currentCompany?.headerSubtitle)
  const companySocialProperties = computed(() => UserModule.currentCompany?.socialMedia)

  const displayIcon = computed(() => {
    return (name: Socials) => {
      if (name === 'Facebook') return require('@/assets/images/facebook.svg')
      if (name === 'Instagram') return require('@/assets/images/instagram.svg')
      if (name === 'LinkedIn') return require('@/assets/images/linkedin.svg')
      if (name === 'Youtube') return require('@/assets/images/youtube.svg')
      if (name === 'Xing') return require('@/assets/images/xing-icon.svg')
      if (name === 'TikTok') return require('@/assets/images/tik_tok.svg')
      return ''
    }
  })

  const trackCompanySocialClick = (social: Socials) => {
    socialMediaClicked(UserModule.currentCompany!.id, UserModule.currentCompany!.name, social)
  }
</script>

<style lang="postcss" scoped>
  .company-header-section-wrapper {
    @apply ketch-relative ketch-h-fit;
    .company-header-section {
      @apply ketch-flex ketch-flex-col ketch-bg-header-background-color;
      @apply md:ketch-relative md:ketch-flex-row md:ketch-items-end md:ketch-justify-between;
      .company-headline-text {
        @apply ketch-flex ketch-flex-col ketch-justify-center ketch-space-y-c30 ketch-py-c20 ketch-relative;
        @apply ketch-z-[2] xl:ketch-justify-end md:ketch-py-c40;

        > * {
          @apply ketch-text-header-foreground-color;
        }

        .headline-title,
        .header-subtext {
          @apply ketch-text-center md:ketch-text-left;
        }

        .headline-title {
          @apply ketch-font-big-daily-short md:ketch-text-4xl md:ketch-leading-[52px];
        }

        .header-subtext {
          @apply lg:ketch-max-w-[530px];
        }

        .company-socials {
          @apply ketch-flex ketch-items-center ketch-space-x-c8 ketch-justify-center ketch-flex-col ketch-space-y-c5;
          @apply sm:ketch-flex-row sm:ketch-space-y-0 md:ketch-justify-start;

          .social-icons {
            @apply ketch-flex ketch-items-center ketch-space-x-c8;

            .icon-link {
              img {
                filter: grayscale(1);
                @apply ketch-w-full ketch-h-full ketch-max-h-[32px] ketch-max-w-[32px] hover:ketch-grayscale-0;
                @apply ketch-transition ketch-ease-in-out;
              }
            }
          }

          .socials-description {
            @apply ketch-flex ketch-items-center ketch-space-x-c5;

            .description {
              @apply ketch-font-special md:ketch-text-[22px];
            }
          }
        }

        @screen md {
          flex: 0 0 700px;
        }
      }
      .company-header-image {
        @apply ketch-flex ketch-justify-center md:ketch-justify-end md:ketch-relative md:ketch-z-[1] md:ketch-mt-0;
        @apply ketch-flex-shrink ketch-flex-grow ketch-flex-auto md:ketch--ml-[40%] md:ketch-mt-c20;

        img {
          @apply ketch-max-h-[150px] md:ketch-max-h-[350px] ketch-max-w-[250px] md:ketch-max-w-[500px];
        }
      }
    }
    .google-review-widget-wrapper {
      @apply ketch-absolute ketch-bottom-[20px] ketch-z-[2];
      @apply ketch-right-[16px] sm:ketch-right-[20px] md:ketch-right-[30px] xl:ketch-right-[70px];
    }
  }
</style>
