<template lang="pug">
.maintenance-banner-wrapper(ref='bannerElement')
    .maintenance-banner.component-spacing
        .warning-icon
            SVGRenderer(
                :fill-color='"var(--primary-foreground-color)"',
                :icon='warning',
                height='30',
                width='33'
            )
        .maintenance-details
            h4 {{ maintenanceTitle }}
            h6.maintenance-description {{ maintenanceDescription }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, nextTick } from 'vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'

  const emit = defineEmits(['maintenance-banner-height'])
  const { warning } = useIcons()
  const bannerElement = ref(null as HTMLDivElement | null)

  const maintenanceTitle = computed(() => process.env.VUE_APP_MAINTENANCE_TITLE)
  const maintenanceDescription = computed(() => process.env.VUE_APP_MAINTENANCE_DESCRIPTION)

  onMounted(() => {
    nextTick(() => {
      if (bannerElement.value) {
        emit('maintenance-banner-height', bannerElement.value.getBoundingClientRect().height)
      }
    })
  })

  onBeforeUnmount(() => {
    emit('maintenance-banner-height', 0)
  })
</script>

<style lang="postcss" scoped>
  .maintenance-banner-wrapper {
    @apply ketch-w-full ketch-flex ketch-items-center ketch-justify-center ketch-bg-primary-color;
    .maintenance-banner {
      @apply ketch-py-c10 ketch-flex ketch-items-center ketch-space-x-c15;
      > * {
        @apply ketch-text-primary-foreground-color;
      }
      .warning-icon {
        @apply ketch-max-h-[30px];
      }
      .maintenance-details {
        @apply ketch-flex ketch-flex-col;
        .maintenance-description {
          @apply ketch-opacity-70;
        }
      }
    }
  }
</style>
